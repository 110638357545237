<div class="time-selector" (clickOutside)="d?.close()">
  <div class="time-selector-container">
    <i
      class="icon-chevron-left icon-medium icon-coral icon-anim icon-box icon-box-medium time-selector-arrow time-selector-arrow-previous"
      [ngClass]="{'icon-disabled': isPreviousDisabled}"
      (click)="previous()">
    </i>
    <div class="time-selector-data card no-pad no-shadow card-border" (click)="d?.toggle()">
      <div class="time-selector-data-title typo-body-2-noquery typo-bold" [innerText]="calendarDay | translate"></div>
      <div class="time-selector-data-subtitle typo-body-3-noquery typo-capitalize" [innerText]="selectedDate"></div>
    </div>
    <i
      class="icon-chevron-right icon-medium icon-coral icon-anim icon-box icon-box-medium time-selector-arrow time-selector-arrow-next"
      [ngClass]="{'icon-disabled': isNextDisabled}"
      (click)="next()">
    </i>
  </div>
  <input
    class="datepicker-input"
    placeholder="yyyy-mm-dd"
    name="dp"
    [(ngModel)]="datepickerDate"
    (dateSelect)="onDatepickerDateSelect($event)"
    ngbDatepicker
    #d="ngbDatepicker"
    [minDate]="datepickerMinDate"
    [maxDate]="datepickerMaxDate">
</div>