import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PreviewTable, PreviewTableList } from 'src/app/interfaces/table-preview';
import { UnitTable } from 'src/app/interfaces/table-report';
import { getApiUrl } from 'src/app/utils/urls';
import * as dayjs from 'dayjs';
import {
  calculateDelta,
  isBDEIncoming,
  isMIBIncoming,
  setUnitOrder,
  sortUnits,
  swapArrayItems
} from 'src/app/utils/functions';
import { AppService } from 'src/app/services/local/app/app.service';
import { BdeList } from 'src/app/interfaces/bde-list';
import { AuctionID } from 'src/app/interfaces/auction';
import { MIBList } from 'src/app/interfaces/mib-list';
import { ThresholdStatus } from 'src/app/enums/threshold-status.enum';
import { StorageKeys } from 'src/app/enums/storage-keys';
import { ALARMS_AUDIO_PATH } from 'src/app/utils/consts';
import {DifferenceStatus} from "../../../enums/difference-status.enum";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import { notificationElements } from './auctions.constants';
import { INotificationElement } from './auctions.interface';

@Injectable({
  providedIn: 'root'
})

export class AuctionService {

  constructor(
    private http: HttpClient,
    private app: AppService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) { }

  private fixMibValue(mibValue: boolean): boolean {
    mibValue = mibValue === null ?
      true :
      mibValue;
    return mibValue = !mibValue;
  }

  getAuctionsList(): Observable<PreviewTableList[]> {
    const storedList = this.app.getStoredAuctionsList();
    if(storedList) {
      return of(storedList);
    } else {
      return this.http.get(
        getApiUrl('getauctionslist')
      ).pipe(
        map((response: PreviewTableList[]) => {
          response = swapArrayItems(response, 3, 4);
          this.app.storeAuctionsList(response);
          return response;
        })
      );
    }
  }

  getAllAuctions(date: string): Observable<UnitTable[]> {
    const storedUnits = this.app.getAllStoredUnits(date);
    if(storedUnits) {
      return of(storedUnits);
    } else {
      return this.http.get(
        getApiUrl('getauctions', null, { 'date': date }),
        { observe: 'response' }
      ).pipe(
        map((response) => {
          let units: UnitTable[] = <UnitTable[]> response.body;
          units.forEach(unit => {
            unit = <UnitTable> setUnitOrder(unit);
            let date = dayjs(unit.unitDate).startOf('day');
            unit.isQuarterly = unit.values[0].length == 4 ? true : false;
            const minutesToIncrement = unit.isQuarterly ? 15 : 60;
            unit.values.forEach(hour => {
              hour.forEach(quarter => {
                quarter.mib = this.fixMibValue(quarter.mib); // BE fix
                quarter.startTime = date.valueOf();
                date = date.add(minutesToIncrement, 'minute');
                quarter.endTime = date.valueOf();
              });
            });
          });
          units.sort(sortUnits);
          this.app.storeAllUnits(date, units);
          return units;
        })
      );
    }
  }

  getAuction(auctionID: AuctionID, date: string): Observable<UnitTable[]> {
    const storedUnits = this.app.getStoredUnits(auctionID, date);
    if(storedUnits) {
      return of(storedUnits);
    } else {
      return this.http.get(
        getApiUrl('getauction', auctionID.toString(), { 'date': date }),
        { observe: 'response' }
      ).pipe(
        map((response) => {
          let units: UnitTable[] = <UnitTable[]> response.body;
          units.forEach(unit => {
            unit = <UnitTable> setUnitOrder(unit);
            let date = dayjs(unit.unitDate).startOf('day');
            unit.isQuarterly = unit.values[0].length == 4 ? true : false;
            const minutesToIncrement = unit.isQuarterly ? 15 : 60;
            unit.values.forEach(hour => {
              hour.forEach(quarter => {
                quarter.mib = this.fixMibValue(quarter.mib); // BE fix
                quarter.startTime = date.valueOf();
                date = date.add(minutesToIncrement, 'minute');
                quarter.endTime = date.valueOf();
              });
            });
          });
          units.sort(sortUnits);
          this.app.storeUnits(auctionID, date, units);
          return units;
        }),
        catchError((error: HttpErrorResponse) => {
          return of(undefined);
        })
      );
    }
  }

  getRecap(auctionID: AuctionID): Observable<PreviewTable[]> {
    const storedRecap = this.app.getStoredRecap(auctionID);
    if(storedRecap) {
      return of(storedRecap);
    } else {
      return this.http.get(
        getApiUrl('getrecap', null, { auctionID: auctionID.toString() })
      ).pipe(
        map((response: PreviewTable[]) => {
          response.forEach(previewTable => {
            previewTable.delta = calculateDelta(previewTable.power, previewTable.pvm, previewTable.pv);
            previewTable.mibs.isIncoming = isMIBIncoming(previewTable.mibs.next?.startDate); // BE fix
            previewTable = <PreviewTable> setUnitOrder(previewTable);
            previewTable.bde.isIncoming = isBDEIncoming(previewTable.bde.next?.startTime);
            previewTable.isQuarterly = previewTable.flagUPquartorario;
          });
          response.sort(sortUnits);
          this.app.storeRecap(auctionID, response);
          if(localStorage.getItem(StorageKeys.ALARMS_TOGGLE) === 'true') {
            this.notificationManager(response, auctionID);
          }
          return response;
        })
      );
    }
  }

  getAllRecaps(): Observable<PreviewTable[]> {
    const storedDashboard = this.app.getStoredDashboard();
    if(storedDashboard) {
      return of(storedDashboard);
    } else {
      return this.http.get(
        getApiUrl('getallrecaps')
      ).pipe(
        map((response: PreviewTable[]) => {
          response.forEach(previewTable => {
            previewTable.delta = calculateDelta(previewTable.power, previewTable.pvm, previewTable.pv);
            previewTable.mibs.isIncoming = isMIBIncoming(previewTable.mibs.next?.startDate); // BE fix
            previewTable = <PreviewTable> setUnitOrder(previewTable);
            previewTable.bde.isIncoming = isBDEIncoming(previewTable.bde.next?.startTime);
            previewTable.isQuarterly = previewTable.flagUPquartorario;
          });
          response.sort(sortUnits);
          this.app.storeDashboard(response);
          if(localStorage.getItem(StorageKeys.ALARMS_TOGGLE) === 'true') {
            this.notificationManager(response);
          }
          return response;
        })
      );
    }
  }

  notificationManager(response: PreviewTable[], auctionID?: string): void {
    notificationElements.forEach((notificationElement: INotificationElement) => {
      let oldList = !!localStorage.getItem(notificationElement.storageKey) ? JSON.parse(localStorage.getItem(notificationElement.storageKey)) : [];
      const newList = response.filter((responseElement:PreviewTable) => notificationElement.isAlreadyNotified(responseElement, oldList));
      
      if(notificationElement.dispatchToast(
        oldList.filter((el: PreviewTable) => auctionID ? el.auctionID === auctionID : true), 
        newList, 
        this.toastr, 
        this.translate, 
        response
      )){
        new Audio(ALARMS_AUDIO_PATH).play();
      };

      localStorage.setItem(notificationElement.storageKey, JSON.stringify([
        ...response.filter((responseElement:PreviewTable) => notificationElement.condition(responseElement)),
        ...(auctionID ? oldList.filter((el: PreviewTable) => el.auctionID !== auctionID) : [])
      ]));
    })
  }

  getDashboardBdeList(date: string): Observable<BdeList[]> {
    const storedDashboardBdeList = this.app.getStoredDashboardBdeList();
    if(storedDashboardBdeList) {
      return of(storedDashboardBdeList);
    } else {
      return this.http.get(
        getApiUrl('getbde', null, {'date': date})
      ).pipe(
        map((response: BdeList[]) => {
          response.forEach(responseItem => {
            responseItem = <BdeList> setUnitOrder(responseItem);
          });
          response.sort(sortUnits);
          this.app.storeDashboardBdeList(response);
          return response;
        })
      );
    }
  }

  getDashboardMIBList(date: string): Observable<MIBList[]> {
    const storedDashboardMIBList = this.app.getStoredDashboardMIBList();
    if(storedDashboardMIBList) {
      return of(storedDashboardMIBList);
    } else {
      return this.http.get(
        getApiUrl('getmib', null, {'date': date})
      ).pipe(
        map((response: MIBList[]) => {
          response.forEach(responseItem => {
            responseItem = <MIBList> setUnitOrder(responseItem);
          });
          response.sort(sortUnits);
          this.app.storeDashboardMIBList(response);
          return response;
        })
      );
    }
  }

  /* getBdeList(date: string, auctionID: AuctionID): Observable<BdeList[]> {
    const storedBdeList = this.app.getStoredBdeList(auctionID);
    if(storedBdeList) {
      return of(storedBdeList);
    } else {
      return this.http.get(
        getApiUrl('getbde', null, {'date': date, 'auctionID': auctionID})
      ).pipe(
        map((response: BdeList[]) => {
          response.forEach(responseItem => {
            responseItem = <BdeList> setUnitOrder(responseItem);
          });
          response.sort(sortUnits);
          this.app.storeBdeList(auctionID, response);
          return response;
        })
      );
    }
  } */
}
