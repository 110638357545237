export enum StorageKeys {
  LANGUAGE = 'agp.system.language',
  DASHBOARD_TIP_1_CHECKED = 'agp.page.dashboardTip1Checked',
  NOTEBAR_HELPER_VIEWED = 'agp.page.notebarHelperViewed',
  AUTHENTICATION_ACCESS_TOKEN = 'agp.auth.authenticationAccessToken',
  AUTHENTICATION_ID_TOKEN = 'agp.auth.authenticationIdToken',
  LAST_VISITED_PAGE = 'agp.navigation.lastVisitedPage',
  NEW_NOTE_MESSAGE_BACKUP = 'agp.backup.note.newNoteMessage',
  EDIT_NOTE_MESSAGE_BACKUP = 'agp.backup.note.newNoteMessage',
  IS_HIGH_CONTRAST = 'agp.theme.isHighContrast',
  IS_THEME_DARK = 'agp.theme.isThemeDark',
  ALARM_THRESHOLD = 'agp.alarm.threshold',
  ALARM_MIB = 'agp.alarm.mib',
  ALARM_BDE = 'agp.alarm.bde',
  ALARMS_TOGGLE = 'agp.alarm.status',
  ALARMS_LAST_AUCTION = 'agp.alarm.lastCheckedAuction',
  UP_CRITICAL_NOTIFICATION = 'agp.notification.critical',
  UP_MIB_NOTIFICATION = 'agp.notification.mib',
  UP_BDE_NOTIFICATION = 'agp.notification.bde',
};
