import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input-textarea',
  templateUrl: './input-textarea.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTextareaComponent),
      multi: true
    }
  ]
})
export class InputTextareaComponent implements OnInit {

  public value: string;

  @Input() id: string;
  @Input() styleClasses: string = '';
  @Input() placeholder: string = '';
  @Input() precompiledValue: string = '';
  
  @Output() onInput: EventEmitter<string> = new EventEmitter<string>();
    
  constructor() {}

  ngOnInit() {}

  update(event: Event) {
    this.value = (event.target as HTMLInputElement).value;
    this.onInput.emit(this.value);
  }
}