export enum UnitDataType {
  PV = 'pv',
  PV_ACTUAL = 'pvActual',
  PV_PREVIOUS = 'pvPrevious',
  PVM = 'pvm',
  PVM_PV = 'pvmpv',
  PVM_P = 'pvmp',
  POWER = 'power',
  POWER_MAX = 'powerMax',
  P_MAX = 'pmax',
  MIB = 'mib',
  CHART = 'chart'
}