import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
  ComponentRef,
  OnDestroy,
  AfterViewInit
} from '@angular/core';
import { PreviewTable, PreviewTableList } from 'src/app/interfaces/table-preview';
import { AuctionService } from 'src/app/services/http/auction/auction.service';
import { AlertComponent } from 'src/app/components/shared/alert/alert.component';
import {
  dashboardConnectionErrorAlertConfig,
  dashboardInfoAlertConfig,
  dashboardViewList,
  DATA_UPDATE_FREQUENCY,
  LOADER_CLASS_PREFIX
} from 'src/app/utils/consts';
import { StorageKeys } from 'src/app/enums/storage-keys';
import { generateAlertComponent } from 'src/app/utils/functions';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Tab } from 'src/app/interfaces/tab';
import { DashboardTab } from 'src/app/enums/dashboard-tab.enum';
import * as dayjs from 'dayjs';
import { BdeList } from 'src/app/interfaces/bde-list';
import { AlertConfiguration } from 'src/app/interfaces/alert-configuration';
import { MIBList } from 'src/app/interfaces/mib-list';
import { AppService, StoredLinksList } from 'src/app/services/local/app/app.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit, OnDestroy {

  private readonly onDestroy$ = new Subject<void>();
  private readonly DASHBOARD_LOADER_CLASS = LOADER_CLASS_PREFIX + '-dashboard';
  private readonly BDE_LOADER_CLASS = LOADER_CLASS_PREFIX + '-bde';
  private readonly MIB_LOADER_CLASS = LOADER_CLASS_PREFIX + '-mib';

  public readonly DASHBOARD_VIEW_LIST = dashboardViewList;
  public readonly DashboardTab = DashboardTab;

  private infoAlert: ComponentRef<AlertComponent>;
  private errorAlert: ComponentRef<AlertComponent>;
  private recapInterval: any;
  private bdeInterval: any;
  private mibInterval: any;

  public auctionList: PreviewTableList[] = [];
  public currentTab: Tab = this.DASHBOARD_VIEW_LIST[0];
  public isLoggedIn: boolean = false;
  public linksList: StoredLinksList = null;

  @ViewChild('alertBox', {read: ViewContainerRef, static: false}) alertBox: ViewContainerRef;

  constructor(
    private auctionService: AuctionService,
    private factoryResolver: ComponentFactoryResolver,
    private app: AppService,
  ) { }

  ngOnInit() {
    this.app.getLoginState().pipe(
      takeUntil(this.onDestroy$)
    ).subscribe( (isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
    });
    this.getAuctionsList();
    this.getExternalLinks();
  }

  ngAfterViewInit() {
    const dashboardTip1Checked = localStorage.getItem(StorageKeys.DASHBOARD_TIP_1_CHECKED);
    if( !dashboardTip1Checked ) {
      this.infoAlert = generateAlertComponent(this.factoryResolver, this.alertBox, dashboardInfoAlertConfig);
    }
  }

  ngOnDestroy() {
    clearInterval(this.recapInterval);
    clearInterval(this.bdeInterval);
    clearInterval(this.mibInterval);
    this.onDestroy$.next();
  }

  private getAllRecaps(): void {
    document.body.classList.add(this.DASHBOARD_LOADER_CLASS);
    this.auctionService.getAllRecaps().pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      (response: PreviewTable[]) => {
        document.body.classList.remove(this.DASHBOARD_LOADER_CLASS);
        this.resetErrorAlert();
        this.auctionList.forEach(auction => auction.data = []);
        response.forEach((responseItem) => {
          const auction = this.auctionList.find(auction => auction.auctionID === responseItem.auctionID);
          const auctionIndex = this.auctionList.indexOf(auction);
          this.auctionList[auctionIndex].data.push(responseItem);
        });
      },
      (error: HttpErrorResponse) => {
        document.body.classList.remove(this.DASHBOARD_LOADER_CLASS);
        this.setErrorAlert(dashboardConnectionErrorAlertConfig);
      }
    );
  }

  private getBDEs(): void {
    document.body.classList.add(this.BDE_LOADER_CLASS);
    this.auctionService.getDashboardBdeList(
      dayjs().format('YYYY-MM-DD')
    ).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      (response: BdeList[]) => {
        document.body.classList.remove(this.BDE_LOADER_CLASS);
        this.resetErrorAlert();
        this.auctionList.forEach(auction => auction.bde = []);
        response.forEach((responseItem) => {
          const auction = this.auctionList.find(auction => auction.auctionID === responseItem.auctionID);
          const auctionIndex = this.auctionList.indexOf(auction);
          this.auctionList[auctionIndex].bde.push(responseItem);
        });
      },
      (error: HttpErrorResponse) => {
        document.body.classList.remove(this.BDE_LOADER_CLASS);
        this.setErrorAlert(dashboardConnectionErrorAlertConfig);
      }
    )
  }

  private getMIBs(): void {
    document.body.classList.add(this.MIB_LOADER_CLASS);
    this.auctionService.getDashboardMIBList(
      dayjs().format('YYYY-MM-DD')
    ).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      (response: MIBList[]) => {
        document.body.classList.remove(this.MIB_LOADER_CLASS);
        this.resetErrorAlert();
        this.auctionList.forEach(auction => auction.mib = []);
        response.forEach((responseItem) => {
          const auction = this.auctionList.find(auction => auction.auctionID === responseItem.auctionID);
          const auctionIndex = this.auctionList.indexOf(auction);
          this.auctionList[auctionIndex].mib.push(responseItem);
        });
      },
      (error: HttpErrorResponse) => {
        document.body.classList.remove(this.MIB_LOADER_CLASS);
        this.setErrorAlert(dashboardConnectionErrorAlertConfig);
      }
    )
  }

  private getAuctionsList(): void {
    this.auctionService.getAuctionsList().pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      (response: PreviewTableList[]) => {
        this.resetErrorAlert();
        this.auctionList = response;
        switch(this.currentTab.id) {
          case DashboardTab.DASHBOARD:
            clearInterval(this.recapInterval);
            this.getAllRecaps();
            this.recapInterval = setInterval(() => this.getAllRecaps(), DATA_UPDATE_FREQUENCY * 1000);
          break;

          case DashboardTab.BDE:
            clearInterval(this.bdeInterval);
            this.getBDEs();
            this.bdeInterval = setInterval(() => this.getBDEs(), DATA_UPDATE_FREQUENCY * 1000);
          break;

          case DashboardTab.MIB:
            clearInterval(this.mibInterval);
            this.getMIBs();
            this.mibInterval = setInterval(() => this.getMIBs(), DATA_UPDATE_FREQUENCY * 1000);
          break;
        }
      },
      (error: HttpErrorResponse) => {
        this.setErrorAlert(dashboardConnectionErrorAlertConfig);
      }
    );
  }

  private resetErrorAlert(): void {
    if(this.errorAlert) {
      this.errorAlert.destroy();
      this.errorAlert = null;
    }
  }

  private setErrorAlert(alertConfig: AlertConfiguration): void {
    if(!this.errorAlert) {
      this.errorAlert = generateAlertComponent(this.factoryResolver, this.alertBox, alertConfig);
      this.errorAlert.onDestroy(() => { this.errorAlert = null; });
    }
  }

  private getExternalLinks(): void {
    this.app.getExternalLinks().pipe(
      takeUntil(this.onDestroy$),
      filter(linksList => Boolean(linksList))
    ).subscribe(linksList => {
      this.linksList = linksList || null;
    });
  }

  public switchTab(newTab: Tab): void {
    this.currentTab = newTab;
    this.getAuctionsList();
  }
}
