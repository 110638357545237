import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ModalConfiguration } from 'src/app/interfaces/modal-config';
import { removeValueFromArray } from 'src/app/utils/functions';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private currentModalConfig = new BehaviorSubject<ModalConfiguration>(null);
  private activeModalsList = new BehaviorSubject<ModalConfiguration[]>([]);
  public onCurrentModalConfigChange = this.currentModalConfig.asObservable();
  public onActiveModalsChange = this.activeModalsList.asObservable();

  constructor() {}

  openModal(modalConfig: ModalConfiguration): void {
    let newValue = this.activeModalsList.value;
    newValue.push(modalConfig);
    this.activeModalsList.next(newValue);
  }

  closeModal(modalID: string): void {
    const modalToRemove = this.activeModalsList.value.find(activeModal => activeModal.id === modalID);
    this.activeModalsList.next( removeValueFromArray(this.activeModalsList.value, modalToRemove) );
  }

  closeAllModals(): void {
    this.activeModalsList.value.forEach(modal => {
      this.closeModal(modal.id);
    });
  }
}
