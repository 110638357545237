import { UnitCode } from 'src/app/enums/unit-codes.enum';
import { AuctionID } from 'src/app/interfaces/auction';

export interface UnitTable {
  auctionID?: AuctionID;
  auctionName?: string;
  unitCode: UnitCode;
  unitDate: string;
  values: Array<UnitTableValues[]>;
  showOptions?: UnitTableOptions;
  bde: UnitTableBde[];

  order?: number;
  isQuarterly?: boolean;
  infoUrl?: string;
}

export interface UnitTableValues {
  pvPrevious: number;
  pvActual: number;
  pvm: number;
  power: number;
  powerMax: number;
  mib: boolean;
  startTime?: number;
  endTime?: number;
  withNote?: boolean;
}

export interface UnitTableOptions {
  showMib: boolean;
  showPowerMax: boolean;
  showPvm: boolean;
}

export interface UnitTableBde {
  startTime: string;
  endTime: string;
  ptIni: number;
  ptFin: number;
  pvPowerDelta_TINI: number;
  pvPowerDelta_TFIN: number;
  type: string;
}

export interface UnitTableMIB {
  startDate: string;
  endDate: string;
}

export interface UnitTableSelection {
  auctionID: string;
  unitCode: UnitCode;
  startTime: number;
}

export const NULL_UNIT_SELECTION: UnitTableSelection = { auctionID: null, unitCode: null, startTime: null };

export interface UnitTableQuarterDetail {
  delta: number | string;
  mw: number | string;
}

export const NULL_QUARTER_DETAIL = { delta: null, mw: null };