import { Component, Input, OnInit } from '@angular/core';

export enum AccordionStyle {
  BIG = 'accordion-big',
  SMALL = 'accordion-small'
};

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html'
})
export class AccordionComponent implements OnInit {

  public readonly AccordionStyle = AccordionStyle;

  public visibility: boolean;
  
  @Input() initialVisibility: boolean = true;
  @Input() style: AccordionStyle = AccordionStyle.BIG;
  @Input() headerOnBottom: boolean = false;
  @Input() groupID: string;

  constructor() { }

  ngOnInit() {
    this.visibility = this.initialVisibility;
  }

  toggleVisibility(event: Event): void {
    if( (event.target as HTMLElement).classList.value.includes('accordion') ) {
      this.visibility = !this.visibility;
    }
  }
}