import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs';
import { ModalConfiguration } from 'src/app/interfaces/modal-config';
import { ModalService } from 'src/app/services/local/modal/modal.service';

@Component({
  selector: 'app-modal-chart-zoom',
  templateUrl: './modal-chart-zoom.component.html'
})
export class ModalChartZoomComponent implements OnInit, AfterViewInit, OnDestroy {

  private readonly onDestroy$ = new Subject<void>();

  public readonly MODAL_ID = 'modal-chart-zoom';

  public isReadyToPrint: boolean = false;

  @Input() config: ModalConfiguration;
  
  @Output() onClose: EventEmitter<Function> = new EventEmitter<Function>();

  constructor(
    private modalService: ModalService
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    const modalBodyNode: HTMLElement = document.querySelector('#' + this.MODAL_ID + ' .modal-body');
    if(modalBodyNode) {
      this.config.content.body.chart.canvasWidth = modalBodyNode.offsetWidth;
    }
    this.isReadyToPrint = true;
  }

  ngOnDestroy(): void {
    this.modalService.closeModal(this.config.id);
    this.onDestroy$.next();
  }

  public modalClose(event) {
    if(event) {
      this.onClose.emit(event)
    } else {
      this.onClose.emit(null);
    }
  }
}