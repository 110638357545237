<div class="auction-config">
  <div class="typo-heading-4 auction-config-heading" [innerText]="'AUCTION-CONFIG:general' | translate"></div>

  <div class="auction-config-field-set">
    <div class="auction-config-field-label typo-body-1" [innerText]="'Link:' | translate"></div>
    <div class="auction-config-field-input typo-body-1">
      <app-input-text
        [id]="'auction-config-global'"
        [placeholder]="'https://...'"
        [initialValue]="currentConfig.global"
        [control]="auctionConfigForm.controls.global">
      </app-input-text>
    </div>
    <div class="auction-config-field-controls">
      <i
        class="icon-checkmark icon-medium icon-coral icon-anim icon-box icon-box-small icon-clickable"
        (click)="setLink(null)"
        [ngClass]="{'icon-disabled': !isFieldValid('global')}">
      </i>
      <i
        class="icon-delete icon-medium icon-coral icon-anim icon-box icon-box-small icon-clickable"
        (click)="clearField(null)"
        [ngClass]="{'icon-disabled': currentConfig.global === ''}">
      </i>
    </div>
  </div>

  <div class="typo-heading-4 auction-config-heading" [innerText]="'AUCTION-CONFIG:auctions' | translate"></div>
  <ng-container *ngIf="isAuctionListReady">
    <div class="auction-config-field-set" *ngFor="let auction of auctionsList">
      <div class="auction-config-field-label typo-body-1" [innerText]="auction.auctionName + ':'"></div>
      <div class="auction-config-field-input typo-body-1">
        <app-input-text
          [id]="'auction-config-' + auction.auctionID"
          [placeholder]="'https://...'"
          [initialValue]="currentConfig.auctions[auction.auctionID]"
          [control]="auctionConfigForm.controls[auction.auctionID]">
        </app-input-text>
      </div>
      <div class="auction-config-field-controls">
        <i
          class="icon-checkmark icon-medium icon-coral icon-anim icon-box icon-box-small icon-clickable"
          (click)="setLink(auction.auctionID)"
          [ngClass]="{'icon-disabled': !isFieldValid(auction.auctionID)}">
        </i>
        <i
          class="icon-delete icon-medium icon-coral icon-anim icon-box icon-box-small icon-clickable"
          (click)="clearField(auction.auctionID)"
          [ngClass]="{'icon-disabled': !(currentConfig.auctions[auction.auctionID])}">
        </i>
      </div>
    </div>
  </ng-container>

  <div class="auction-config-alert-box">
    <template #alertBox></template>
  </div>
</div>