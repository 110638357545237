<div class="notebar card no-pad" [ngClass]="{'open': isOpen}">
  <div class="notebar-header-top" (click)="toggleNotebar()">
    <div class="notebar-header-top-title typo-heading-2" [innerText]="'NOTEBAR:notes' | translate"></div>
    <div class="notebar-header-top-subtitle typo-body-3 typo-gun-metal" [innerText]="totalNotesCount || ''"></div>
    <div class="notebar-header-top-badge"></div>
    <i class="icon-chevron-up icon-medium icon-coral icon-box icon-box-medium icon-anim notebar-header-top-chevron"></i>
  </div>
  <div class="notebar-header-bottom">
    <div
      *ngIf="auction"
      class="notebar-header-bottom-title typo-body-1 typo-bold typo-capitalize"
      [innerText]="auction.name | translate">
    </div>
    <div class="notebar-header-bottom-subtitle typo-body-3 typo-gun-metal" [innerText]="currentNotesCount || ''"></div>
  </div>
  <div class="notebar-helper" [ngClass]="{'hidden': !isHelperVisible}">
    <i class="icon-note icon-sky icon-medium icon-box icon-box-large icon-box-no-shadow icon-box-no-border icon-box-sky notebar-helper-icon"></i>
    <div class="notebar-helper-content typo-body-2-noquery" [innerText]="'NOTEBAR:helper' | translate"></div>
    <i class="icon-cross icon-dark-gray icon-medium notebar-helper-close" (click)="closeHelper()"></i>
  </div>
  <div class="notebar-timestamp">
    <div class="notebar-timestamp-time typo-heading-1 typo-regular" [innerText]="displayedTime"></div>
    <div class="notebar-timestamp-date typo-body-2-noquery typo-capitalize">
      <span [innerText]="currentDay | translate"></span>
      <span [innerText]="currentDisplayedDate"></span>
    </div>
  </div>

  <div class="notebar-alert-box">
    <template #alertBox></template>
  </div>

  <div class="notebar-note-list">
    <ng-container *ngIf="filteredNoteList.length > 0; else noNotes">
      <ng-container *ngFor="let noteGroup of filteredNoteList">
        <div class="notebar-note-group">
          <div class="notebar-note-group-title typo-heading-4" [innerText]="noteGroup.unitCode | translate"></div>
          <div class="notebar-note-group-subtitle  typo-body-3 typo-gun-metal" [innerText]="'NOTEBAR:unit-notes' | translate: {number: noteGroup.notes.length}"></div>
        </div>
        <ng-container [ngTemplateOutlet]="notebarEditNoteInputBlock"></ng-container>
        <ng-container [ngTemplateOutlet]="notebarNewNoteInputBlock"></ng-container>
        <div class="notebar-note card" *ngFor="let note of noteGroup.notes" [attr.id]="NOTE_ID_PREFIX + note.id">
          <div class="notebar-note-content typo-body-2-noquery" [innerText]="note.message"></div>
          <div class="notebar-note-footer">
            <div class="notebar-note-owner typo-body-2-noquery typo-bold" [innerText]="note.author"></div>
            <div class="notebar-note-timestamp typo-body-3 typo-wolf-gray" [innerText]="getFormattedCreationDate(note.creationDate)"></div>
            <i
              class="icon-pencil icon-coral icon-medium icon-anim notebar-note-edit"
              [ngClass]="{'icon-disabled': isEditingNote || isAddingNote}"
              (click)="requestEditNote(note.id)">
            </i>
            <i
              class="icon-delete icon-coral icon-medium icon-anim notebar-note-delete"
              [ngClass]="{'icon-disabled': isEditingNote || isAddingNote}"
              (click)="requestDeleteNote(note.id, false)">
            </i>
          </div>
          <div class="notebar-note-confirm-delete card">
            <span class="notebar-note-confirm-delete-label typo-body-2" [innerText]="'NOTEBAR:confirm-delete' | translate"></span>
            <div class="notebar-note-confirm-delete-actions">
              <button
                class="btn btn-secondary notebar-note-confirm-delete-no typo-uppercase"
                [innerText]="'NOTEBAR:confirm-delete-no' | translate"
                (click)="requestDeleteNote(note.id, true)">
              </button>
              <button
                class="btn btn-primary notebar-note-confirm-delete-yes typo-uppercase"
                [innerText]="'NOTEBAR:confirm-delete-yes' | translate"
                (click)="deleteNote(note.id)">
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #noNotes>
      <div class="notebar-note-group">
        <div class="notebar-note-group-title typo-heading-4" [innerText]="selectedUnit | translate"></div>
        <div class="notebar-note-group-subtitle  typo-body-3 typo-gun-metal" [innerText]="'NOTEBAR:no-notes' | translate"></div>
      </div>
      <ng-container [ngTemplateOutlet]="notebarNewNoteInputBlock"></ng-container>
    </ng-template>

    <ng-template #notebarNewNoteInputBlock>
      <div class="notebar-input" [ngClass]="{'show': isNewNoteInputVisible}">
        <app-input-textarea
          [id]="'notebar-new-note-input-field'"
          (onInput)="getValue($event)"
          [placeholder]="'NOTEBAR:notes-placeholder'"
          [styleClasses]="'notebar-input-field typo-body-3'"
          [precompiledValue]="newNoteMessageBackup || ''">
        </app-input-textarea>
        <div class="notebar-input-buttons">
            <button class="btn btn-secondary" (click)="hideNewNoteInput()">
              <div class="btn-text" [innerText]="'NOTEBAR:abort' | translate"></div>
              <i class="icon-cross icon-medium icon-coral btn-icon"></i>
            </button>
          <button class="btn btn-primary" (click)="sendNote($event)" [ngClass]="{'disabled': isSendDisabled}">
            <div class="btn-text" [innerText]="'NOTEBAR:save' | translate"></div>
            <i class="icon-checkmark icon-medium icon-white btn-icon"></i>
          </button>
        </div>
      </div>
    </ng-template>

    <ng-template #notebarEditNoteInputBlock>
      <div class="notebar-input" [ngClass]="{'show': isEditNoteInputVisible}">
        <app-input-textarea
          [id]="'notebar-edit-note-input-field'"
          (onInput)="getEditValue($event)"
          [placeholder]="'NOTEBAR:notes-placeholder'"
          [styleClasses]="'notebar-input-field typo-body-3'"
          [precompiledValue]="textareaPrecompiledValue || editNoteMessageBackup || ''">
        </app-input-textarea>
        <div class="notebar-input-buttons">
            <button class="btn btn-secondary" (click)="hideEditNoteInput()">
              <div class="btn-text" [innerText]="'NOTEBAR:abort' | translate"></div>
              <i class="icon-cross icon-medium icon-coral btn-icon"></i>
            </button>
          <button class="btn btn-primary" (click)="editNote($event)">
            <div class="btn-text" [innerText]="'NOTEBAR:edit' | translate"></div>
            <i class="icon-checkmark icon-medium icon-white btn-icon"></i>
          </button>
        </div>
      </div>
    </ng-template>

  </div>
  <button
    class="btn btn-primary notebar-button-add"
    [ngClass]="{'disabled': !(this.selectedUnit && !isNewNoteInputVisible) || isEditingNote}"
    (click)="showNewNoteInput()">
    <div class="btn-text" [innerText]="'NOTEBAR:add' | translate"></div>
    <i class="icon-plus icon-medium btn-icon"></i>
  </button>
</div>

<i
  (click)="toggleNotebar()"
  class="icon-note icon-medium icon-white icon-box icon-box-medium icon-box-coral icon-anim notebar-tablet-open-button cursor-pointer">
</i>