import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse,
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { Auth } from 'aws-amplify';
import { catchError, switchMap } from 'rxjs/operators';
import AWSConfig from '../../../../aws-exports';
import { StorageKeys } from 'src/app/enums/storage-keys';
import { redirectToAuthentication } from 'src/app/utils/functions';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  private readonly AUTHENTICATION_URL_DOMAIN = 'amazoncognito.com';

  constructor(
    private http: HttpClient
  ) {}
  
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request instanceof HttpRequest) {
      let updatedRequest: HttpRequest<any> = request.clone({
        withCredentials: false
      });
      const storedAccessToken = sessionStorage.getItem(StorageKeys.AUTHENTICATION_ACCESS_TOKEN);
      if(request.url.includes(this.AUTHENTICATION_URL_DOMAIN)) {
        return this.doRequest(next, updatedRequest);
      }
      return from(Auth.currentSession()).pipe(
        switchMap((currentSession) => {
          const idToken = currentSession.getIdToken().getJwtToken();
          const accessToken = currentSession.getAccessToken().getJwtToken();
          if(!Boolean(storedAccessToken)) {
            sessionStorage.setItem(StorageKeys.AUTHENTICATION_ID_TOKEN, idToken);
            sessionStorage.setItem(StorageKeys.AUTHENTICATION_ACCESS_TOKEN, accessToken);
          }
          updatedRequest = this.addAuthenticationToken(updatedRequest, idToken, accessToken);
          return this.doRequest(next, updatedRequest);
        }),
        catchError((error) =>  {
          if(!(error instanceof HttpErrorResponse)) {
            return this.getTokenDetailsFromCognito(storedAccessToken).pipe(
              switchMap((refreshTokenResponse) => {
                const {id_token, access_token } = refreshTokenResponse;
                updatedRequest = this.addAuthenticationToken(updatedRequest, id_token, access_token);
                return this.doRequest(next, updatedRequest);
              })
            );
          }
          return throwError(error);
        })
      );
    }
  }

  private doRequest(next: HttpHandler, request: HttpRequest<any>): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(error => {
        if(request.url.includes(this.AUTHENTICATION_URL_DOMAIN)) {
          redirectToAuthentication();
        }
        return throwError(error);
      })
    );
  }

  private addAuthenticationToken(request: HttpRequest<any>, idToken: string, accessToken: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`,
        'X-id-token': idToken
      }
    });
  }

  private getRefreshTokenFromCognito(accessToken: string): Observable<any> {
    const details = {
      grant_type: 'authorization_code',
      code: accessToken,
      scope: environment.baseUrls.authenticationUrl.options.scope,
      redirect_uri: environment.baseUrls.authenticationUrl.options.redirect_uri,
    };
    const formBody = Object.keys(details).map(
      key => `${encodeURIComponent(key)}=${encodeURIComponent(details[key])}`
    ).join('&');

    return this.http.post<any>(
      environment.baseUrls.refreshTokenUrl.base,
      formBody, {
        responseType: 'json',
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization:  'Basic ' + btoa(`${AWSConfig.oauth.client_id}:`)
        })
      }
    );
  }

  private getTokenDetailsFromCognito(accessToken: string): Observable<any|null> {
    return this.getRefreshTokenFromCognito(accessToken).pipe(
      switchMap((response: any) => {
        if (response) {
          sessionStorage.setItem(StorageKeys.AUTHENTICATION_ID_TOKEN, response.id_token);
          sessionStorage.setItem(StorageKeys.AUTHENTICATION_ACCESS_TOKEN, response.access_token);
        }
        return response;
      }),
      catchError ((error) => {
        return error;
      })
    );
  }
  
}
