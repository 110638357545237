import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace'
})
export class StringReplacePipe implements PipeTransform {

  transform(value: string, stringTarget: string, newString: string): string {
    return value === stringTarget ? newString : value;
  }
}
