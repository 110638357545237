import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'floatTrim'
})
export class FloatTrimPipe implements PipeTransform {

  transform(value: number | string): string {
    if(value === null || value === undefined || value === NaN || value === '' || !parseFloat(<string>value)) {
      return <string> value;
    } else {
      const floatValue = parseFloat( <string> value );
      const intValue = parseInt( <string> value );

      switch(true) {
        case floatValue > 999:
          return '999e';

        case Math.round(floatValue) === 0:
          return '0';

        case intValue.toString().length >= 3:
          return floatValue.toFixed(0);

        case intValue.toString().length >= 2:
          return this.checkZero(floatValue.toFixed(1)) ?
            intValue.toString() :
            floatValue.toFixed(1);

        case intValue.toString().length >= 1:
          return this.checkZero(floatValue.toFixed(2)) ?
            intValue.toString() :
            floatValue.toFixed(2);
      }
    }
  }

  private checkZero(value: number|string): boolean {
    const floatValue = parseFloat(<string>value);
    const intValue = parseInt(<string>value);
    return floatValue - intValue === 0;
  }
}