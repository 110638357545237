<div class="table-report typo-body-3" *ngIf="!!dataset">
  <div class="table-report-body" *ngIf="mode === UnitDataType.PV">
    <div class="table-report-body-row" *ngFor="let hours of dataset.values">
      <div class="table-report-body-cell"
        *ngFor="let quarter of hours"
        [ngClass]="{
          'changed': quarter.pvActual != quarter.pvPrevious,
          'positive': quarter.pvActual > quarter.pvPrevious,
          'negative': quarter.pvActual < quarter.pvPrevious,
          'past': currentTime >= quarter.endTime,
          'offline': quarter.mib,
          'no-data': quarter.pvActual == null,
          'badge': quarter.withNote,
          'selected': selectedCell.startTime == quarter.startTime && selectedCell.unitCode === dataset.unitCode
        }"
        (click)="selectCell($event, dataset.unitCode, quarter.startTime)"
        [innerText]="quarter.pvActual | floatTrim | replace:'0':'-' | replace:null:'ND' | replace:'':'ND'">
      </div>
    </div>
  </div>

  <div class="table-report-body" *ngIf="mode === UnitDataType.PVM_PV">
    <div class="table-report-body-row" *ngFor="let hours of dataset.values; index as hourIndex">
      <div class="table-report-body-cell"
        *ngFor="let quarter of hours; index as quarterIndex"
        [ngClass]="{
          'changed': quarter.pvm != quarter.pvActual,
          'positive': quarter.pvm > quarter.pvActual,
          'negative': quarter.pvm < quarter.pvActual,
          'past': currentTime >= quarter.endTime,
          'offline': quarter.mib,
          'no-data': quarter.pvActual == null,
          'badge': quarter.withNote,
          'selected': selectedCell.startTime == quarter.startTime && selectedCell.unitCode === dataset.unitCode
        }"
        (click)="selectCell($event, dataset.unitCode, quarter.startTime)"
        [innerText]="quarter.pvm | floatTrim | replace:'0':'-' | replace:null:quarter.pvActual | replace:'':'ND'">
      </div>
    </div>
  </div>

  <div class="table-report-body" *ngIf="mode === UnitDataType.PVM_P">
    <div class="table-report-body-row" *ngFor="let hours of dataset.values; index as hourIndex">
      <div class="table-report-body-cell"
        *ngFor="let quarter of hours; index as quarterIndex"
        [ngClass]="{
          'changed': quarter.pvm != quarter.power,
          'positive': quarter.pvm > quarter.power,
          'negative': quarter.pvm < quarter.power,
          'past': currentTime >= quarter.endTime,
          'offline': quarter.mib,
          'no-data': quarter.pvm == null,
          'badge': quarter.withNote,
          'selected': selectedCell.startTime == quarter.startTime && selectedCell.unitCode === dataset.unitCode
        }"
        (click)="selectCell($event, dataset.unitCode, quarter.startTime)"
        [innerText]="quarter.pvm | floatTrim | replace:'0':'-'">
      </div>
    </div>
  </div>

  <div class="table-report-body" *ngIf="mode === UnitDataType.POWER">
    <div class="table-report-body-row" *ngFor="let hours of dataset.values; index as hourIndex">
      <div class="table-report-body-cell"
        *ngFor="let quarter of hours; index as quarterIndex"
        [ngClass]="{
          'past': currentTime >= quarter.endTime,
          'offline': quarter.mib,
          'badge': quarter.withNote,
          'selected': selectedCell.startTime == quarter.startTime && selectedCell.unitCode === dataset.unitCode
        }"
        (click)="selectCell($event, dataset.unitCode, quarter.startTime)"
        [innerText]="quarter.power | floatTrim | replace:'0':'-' | replace:null:'-' | replace:'':'-'">
      </div>
    </div>
  </div>

  <div class="table-report-body" *ngIf="mode === UnitDataType.P_MAX">
    <div class="table-report-body-row" *ngFor="let hours of dataset.values; index as hourIndex">
      <div class="table-report-body-cell"
        *ngFor="let quarter of hours; index as quarterIndex"
        [ngClass]="{
          'past': currentTime >= quarter.endTime,
          'offline': quarter.mib,
          'no-data': quarter.pvActual == null,
          'badge': quarter.withNote,
          'selected': selectedCell.startTime == quarter.startTime && selectedCell.unitCode === dataset.unitCode
        }"
        (click)="selectCell($event, dataset.unitCode, quarter.startTime)"
        [innerText]="quarter.powerMax | floatTrim | replace:'0':'-' | replace:null:'ND' | replace:'':'ND'">
      </div>
    </div>
  </div>
</div>
<div class="table-report-time">
  <div
    *ngFor="let timeLabel of tableReportTimeLabels"
    class="table-report-time-item typo-body-3-noquery"
    [ngClass]="{
      'past': dayjs(timeLabel).hour() < dayjs().hour() || dayjs(timeLabel).date() < dayjs().date(),
      'current': dayjs(timeLabel).hour() == dayjs().hour() && dayjs(timeLabel).date() == dayjs().date()
    }"
    [innerText]="dayjs(timeLabel).add(1, 'hour').format('kk')">
  </div>
</div>