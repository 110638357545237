import { Component, Input, OnInit } from '@angular/core';
import { BdeList } from 'src/app/interfaces/bde-list';
import * as dayjs from 'dayjs';
import { BDEType } from 'src/app/enums/bde-type.enum';
import { UNIT_ID_LINK_PREFIX } from 'src/app/utils/consts';
import { UnitTableBde } from 'src/app/interfaces/table-report';

@Component({
  selector: 'app-table-bde',
  templateUrl: './table-bde.component.html',
})
export class TableBDEComponent implements OnInit {

  public readonly BDEType = BDEType;
  public readonly UNIT_ID_LINK_PREFIX = UNIT_ID_LINK_PREFIX;

  public dayjs = dayjs;
  public displayedDataset: BdeList[];
  
  @Input() dataset: BdeList[];
  @Input() singleDataset: UnitTableBde[];
  @Input() isSingleUnit: boolean = false;
  @Input() routedLinks: boolean = true;
    
  constructor() {}

  ngOnInit() {}
}