import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, forwardRef, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { proportionalRange } from 'src/app/utils/functions';

@Component({
  selector: 'app-input-range',
  templateUrl: './input-range.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputRangeComponent),
      multi: true
    }
  ]
})
export class InputRangeComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() id: string;
  @Input() minValue: number = 0;
  @Input() maxValue: number = 100;
  @Input() step: number = 1;
  @Input() initialValue: number = this.minValue;
  @Input() isTrackInverted: boolean = false;
  @Input() isLabelVisible: boolean = true;
  @Input() labelUnit: string = '';
  @Input() control: UntypedFormControl;

  @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('inputNode') inputNode: ElementRef;
  @ViewChild('labelNode') labelNode: ElementRef;

  public value: number;

  private previousValue : number = this.initialValue;

  constructor(
    private changeDetectorReference: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.value = this.initialValue;
  }
  
  ngAfterViewInit(): void {
    this.updateTrackLength();
    this.inputNode.nativeElement.addEventListener('input', () => this.updateTrackLength());
    this.inputNode.nativeElement.addEventListener('click', () => this.emitValue());
    this.inputNode.nativeElement.addEventListener('touchend', () => this.emitValue());
    this.changeDetectorReference.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes?.initialValue?.previousValue && changes?.initialValue?.currentValue) {
      this.updateTrackLength();
    }
  }

  private updateTrackLength(): void {
    this.updateValue();
    const inputNode = (this.inputNode.nativeElement as HTMLInputElement);
    const labelNode = (this.labelNode.nativeElement as HTMLElement);
    const positionPercentage = (this.value - this.minValue) * 100 / (this.maxValue - this.minValue);
    inputNode.style.backgroundSize = `${positionPercentage}% 100%`;
    labelNode.style.left = `${proportionalRange(0, 100, 3, 97, positionPercentage)}%`;
  }

  private updateValue(): void {
    this.value = parseFloat((this.inputNode.nativeElement as HTMLInputElement).value);
  }

  private emitValue(): void {
    if(this.value !== this.previousValue) {
      this.previousValue = this.value;
      this.valueChange.emit(this.value);
    }
  }
}