import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html'
})
export class InputTextComponent implements OnInit, OnChanges {

  public value: string;

  @Input() id: string;
  @Input() placeholder: string = '';
  @Input() styleClasses: string = '';
  @Input() initialValue: string = '';
  @Input() control: UntypedFormControl;
  
  @Output() onInput: EventEmitter<string> = new EventEmitter<string>();
    
  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    const newValue = changes?.initialValue?.currentValue;
    if(newValue !== this.value) {
      this.value = newValue;
    }
  }

  update(event: Event) {
    this.value = (event.target as HTMLInputElement).value;
    this.onInput.emit(this.value);
  }
}