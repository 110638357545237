import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms'; 
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { HttpLoaderFactory } from 'src/app/utils/functions';
import { DEFAULT_LANGUAGE } from 'src/app/utils/consts';

import { AccordionComponent } from './accordion/accordion.component';
import { AlertComponent } from './alert/alert.component';
import { AuctionConfigComponent } from './auction-config/auction-config.component';
import { ChartDoughnutComponent } from './chart-doughnut/chart-doughnut.component';
import { ChartLineComponent } from './chart-line/chart-line.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { HeaderComponent } from './header/header.component';
import { InputCheckboxComponent } from './input-checkbox/input-checkbox.component';
import { InputRangeComponent } from './input-range/input-range.component';
import { InputSelectComponent } from './input-select/input-select.component';
import { InputTextareaComponent } from './input-textarea/input-textarea.component';
import { InputTextComponent } from './input-text/input-text.component';
import { ModalChartZoomComponent } from './modal-chart-zoom/modal-chart-zoom.component';
import { ModalComponent } from './modal/modal.component';
import { ModalConfigComponent } from './modal-config/modal-config.component';
import { NotebarComponent } from './notebar/notebar.component';
import { TabComponent } from './tab/tab.component';
import { TableBDEComponent } from './table-bde/table-bde.component';
import { TableMIBComponent } from './table-mib/table-mib.component';
import { TablePreviewComponent } from './table-preview/table-preview.component';
import { TableReportComponent } from './table-report/table-report.component';
import { TimeSelectorComponent } from './time-selector/time-selector.component';
import { UnitConfigComponent } from './unit-config/unit-config.component';

const components = [
  AccordionComponent,
  AlertComponent,
  AuctionConfigComponent,
  ChartDoughnutComponent,
  ChartLineComponent,
  HeaderComponent,
  InputCheckboxComponent,
  InputRangeComponent,
  InputSelectComponent,
  InputTextareaComponent,
  InputTextComponent,
  ModalChartZoomComponent,
  ModalComponent,
  ModalConfigComponent,
  NotebarComponent,
  TabComponent,
  TableBDEComponent,
  TableMIBComponent,
  TablePreviewComponent,
  TableReportComponent,
  TimeSelectorComponent,
  UnitConfigComponent,
];

@NgModule({
  declarations: [
    ...components,
    TableMIBComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    NgbModule,
    ClickOutsideModule
  ],
  exports: [
    ...components
  ],
})
export class SharedModule {
  constructor(
    private translate: TranslateService,
  ) {
    translate.setDefaultLang(DEFAULT_LANGUAGE);
  }
}
