<label
  [attr.for]="id"
  [attr.class]="'checkbox ' + styleClasses">
  <input
    type="checkbox"
    [attr.name]="id"
    [attr.id]="id"
    class="checkbox-input"
    (change)="update($event)"
    [formControl]="control"
    [(ngModel)]="value">
  <span
    class="checkbox-checkmark">
  </span>
  <span
    *ngIf="checkboxLabel"
    [attr.class]="'checkbox-label ' + labelStyleClasses"
    [innerText]="checkboxLabel | translate">
  </span>
  <ng-content select="[checkbox-content]"></ng-content>
</label>