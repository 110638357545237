<div class="basic-page">
  <app-header></app-header>
  <main class="page-spacing">
    <div class="basic-page-background">
      <i class="icon-alperia basic-page-background-logo"></i>
    </div>
    <router-outlet (activate)="routedComponent = $event"></router-outlet>
    <div class="modal-wrapper">
      <div class="modal-overlay"></div>
      <div class="modal-container container">
        <ng-template #modalContainer></ng-template>
      </div>
    </div>
  </main>
</div>