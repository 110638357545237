import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-input-checkbox',
  templateUrl: './input-checkbox.component.html',
})
export class InputCheckboxComponent implements OnInit {
  
  public value: boolean;

  @Input() styleClasses: string;
  @Input() labelStyleClasses: string;
  @Input() id: string;
  @Input() checkboxLabel: string;
  @Input() initialValue: boolean = false;
  @Input() control: UntypedFormControl;
  
  @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  constructor() {}

  ngOnInit() {
    this.value = this.initialValue;
  }

  update(event: Event): void {
    this.value = (event.target as HTMLInputElement).checked;
    this.valueChange.emit(this.value);
  }
}