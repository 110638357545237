import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AlertThemes } from 'src/app/enums/alert-themes.enum';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html'
})
export class AlertComponent implements OnInit {

  @Input() theme: AlertThemes;
  @Input() fadeTime: string = 'slow';
  @Input() iconID: string;
  @Input() iconSize: string = 'icon-medium';
  @Input() message: string;
  @Input() hasRememberCheck: boolean;
  @Input() rememberCheckDefaultValue: boolean = false;
  @Input() rememberCheckMessage: string;
  @Input() rememberCheckID: string;
  @Input() autocloseTimeout: number = 0;

  @Output() onDismiss: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  public isRememberChecked: boolean = this.rememberCheckDefaultValue;
  
  constructor() {}

  ngOnInit() {
    if(this.autocloseTimeout) {
      setTimeout(() => this.dismiss(), (this.autocloseTimeout * 1000));
    }
  }

  public dismiss(): void {
    this.onDismiss.emit(this.hasRememberCheck ? this.isRememberChecked : undefined);
  }
}