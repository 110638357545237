import { AppService } from 'src/app/services/local/app/app.service';
import { EventEmitter, OnDestroy, Output } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UnitDataType } from 'src/app/enums/unit-data-type.enum';
import { UnitTable, UnitTableSelection, NULL_UNIT_SELECTION } from 'src/app/interfaces/table-report';
import { ClockService } from 'src/app/services/local/clock/clock.service';
import { UnitCode } from 'src/app/enums/unit-codes.enum';
import * as dayjs from 'dayjs';
import * as advancedFormat from 'dayjs/plugin/advancedFormat';

@Component({
  selector: 'app-table-report',
  templateUrl: './table-report.component.html'
})
export class TableReportComponent implements OnInit, OnDestroy {

  private readonly onDestroy$ = new Subject<void>();

  public readonly UnitDataType = UnitDataType;
  public readonly parseInt = parseInt;
  public readonly dayjs = dayjs;

  public tableReportTimeLabels: number[];
  public currentTime: number;
  public currentHour: number;
  
  @Input() dataset: UnitTable;
  @Input() mode: UnitDataType = UnitDataType.PV;
  @Input() selectedCell: UnitTableSelection = NULL_UNIT_SELECTION;

  constructor(
    private clockService: ClockService,
    private app: AppService
  ) {
    this.clockService.time.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((now: Date) => {
      this.currentTime = now.valueOf();
      this.currentHour = now.getHours();
    });
 }

  ngOnInit() {
    dayjs.extend(advancedFormat);
    dayjs.locale('it');
    this.generateTimeLabels();
    this.getSelectedUnitCell();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  public selectCell(event: Event, unitCode: UnitCode, startTime: number): void {
    if(unitCode === this.selectedCell.unitCode && this.selectedCell.startTime === startTime) {
      this.app.setSelectedUnitCell(NULL_UNIT_SELECTION);
    } else {
      this.app.setSelectedUnitCell({ auctionID: this.dataset.auctionID,unitCode: unitCode, startTime: startTime });
    }
  }

  private generateTimeLabels(): void {
    this.tableReportTimeLabels = [];
    this.dataset.values.forEach(hours => {
      this.tableReportTimeLabels.push( dayjs(hours[0].startTime).valueOf() );
    });
  }

  private getSelectedUnitCell(): void {
    this.app.getSelectedUnitCell().pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((selected: UnitTableSelection) => {
      if(selected.unitCode === this.dataset.unitCode) {
        if(selected.startTime === this.selectedCell.startTime) {
          this.selectedCell = NULL_UNIT_SELECTION;
        } else {
          this.selectedCell = selected;
        }
      } else {
        this.selectedCell = NULL_UNIT_SELECTION;
      }
    });
  }
}
