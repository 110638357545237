import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { DifferenceStatus } from "src/app/enums/difference-status.enum";
import { StorageKeys } from "src/app/enums/storage-keys";
import { ThresholdStatus } from "src/app/enums/threshold-status.enum";
import { PreviewTable } from "src/app/interfaces/table-preview";
import { INotificationElement } from "./auctions.interface";

enum NotificationType {
    THRESHOLD_POSITIVE = 'TP',
    THRESHOLD_NEGATIVE = 'TN',
    BDE = 'BDE',
    MIB = 'MIB',
}

class NotificationElement {
    storageKey: StorageKeys;
    objKey: string;

    constructor(storageKey: StorageKeys, objKey?: 'bde' | 'mibs') {
      this.storageKey = storageKey;
      this.objKey = objKey;
    }

    condition(element: PreviewTable): boolean {
        return this.objKey 
        ? !!element[this.objKey].current 
        : element.unbalancedAlarmStatus === ThresholdStatus.CRITICAL;
    } 

    isAlreadyNotified(responseElement: PreviewTable ,oldEl: PreviewTable[]): boolean {
        return this.objKey 
        ? !!responseElement[this.objKey].current 
        : !oldEl.some((el: PreviewTable) => responseElement.unitCode === el.unitCode && responseElement.unbalancedAlarmStatus === el.unbalancedAlarmStatus && responseElement.differenceStatus === el.differenceStatus);
    }

    dispatchToast(oldList: PreviewTable[], newList: PreviewTable[], toastrService: ToastrService, translate: TranslateService, response: PreviewTable[]): boolean {
        let notificationDispatched = false;
        const lists = {
            in: newList.filter((el: PreviewTable) => !this.objKey ? this.condition(el) : true),
            out: oldList.filter((el: PreviewTable) => !this.objKey ? this.condition(el) : true)
        };

        Object.values(lists).forEach((list: PreviewTable[], i: number)=> 
            list
            .filter((newEl: PreviewTable) => Object.keys(lists)[i] === 'in'
                ? !oldList.some((oldEl: PreviewTable) => this.isElementUnchanged(oldEl, newEl))
                : !response.some((oldEl: PreviewTable) => this.isElementUnchanged(oldEl, newEl))
            )
            .forEach((newEl: PreviewTable) => {
                let title = '';
                let message = '';
                let type: NotificationType;

                if(this.objKey){
                    title = `${this.objKey === 'bde' ? 'BDE' : 'MIB'} ${Object.keys(lists)[i].toUpperCase()}`;
                    message = this.objKey === 'bde' 
                        ? `${translate.instant("NOTIFICATION_AUCTION")}: ${newEl.auctionName} | ${translate.instant("NOTIFICATION_UNIT")}: ${translate.instant(newEl.unitCode)}`
                        : `${translate.instant("NOTIFICATION_AUCTION")}: ${newEl.auctionName} | ${translate.instant("NOTIFICATION_UNIT")}: ${translate.instant(newEl.unitCode)}`;
                    type = this.objKey === 'bde' ? NotificationType.BDE : NotificationType.MIB;
                } else {
                    title = `${translate.instant(newEl.differenceStatus === DifferenceStatus.POSITIVE ? "NOTIFICATION_THRESHOLD_POSITIVE" : "NOTIFICATION_THRESHOLD_NEGATIVE")} ${Object.keys(lists)[i].toUpperCase()}`;
                    message = `${translate.instant("NOTIFICATION_AUCTION")}: ${newEl.auctionName} | ${translate.instant("NOTIFICATION_UNIT")}: ${translate.instant(newEl.unitCode)}`;
                    type = newEl.differenceStatus === DifferenceStatus.POSITIVE ? NotificationType.THRESHOLD_NEGATIVE : NotificationType.THRESHOLD_NEGATIVE;
                }

                this.notify(toastrService, title, message, type)
                notificationDispatched = true;
            })
        );
        return notificationDispatched;
    }

    notify(toastrService: ToastrService, title: string, message: string, type: NotificationType ): void {
        switch(type){
            case NotificationType.THRESHOLD_POSITIVE: {
                toastrService.success(message, title);
                break;
            }
            case NotificationType.THRESHOLD_NEGATIVE: {
                toastrService.error(message, title);
                break;
            }
            case NotificationType.BDE: {
                toastrService.warning(message, title);
                break;
            }
            case NotificationType.MIB: {
                toastrService.info(message, title);
                break;
            }
            default: {
                break;
            }
        }
    }

    isElementUnchanged(oldEl: PreviewTable, newEl: PreviewTable): boolean {
        if(!this.objKey){
            return oldEl.unitCode === newEl.unitCode && oldEl.differenceStatus === newEl.differenceStatus && oldEl.unbalancedAlarmStatus === newEl.unbalancedAlarmStatus;
        }  
        return oldEl.unitCode === newEl.unitCode && !!oldEl[this.objKey].current === !!newEl[this.objKey].current;
    }
}

export const notificationElements: INotificationElement[] = [
    new NotificationElement(StorageKeys.UP_CRITICAL_NOTIFICATION),
    new NotificationElement(StorageKeys.UP_MIB_NOTIFICATION, 'mibs'),
    new NotificationElement(StorageKeys.UP_BDE_NOTIFICATION, 'bde')
]
