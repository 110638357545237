import { environment } from 'src/environments/environment';

export function getApiUrl(
  name: string,
  urlParam: string = null,
  queryParams: Object = null
): string {
  const apiBaseUrl = environment.baseUrls.apiBaseUrl;
  const assembledQueryParams = buildQueryParams(queryParams);
  switch (name) {
    case 'getauctions':
      return apiBaseUrl + 'auctions_all' + assembledQueryParams;
    case 'getauction':
      return apiBaseUrl + 'auctions/' + urlParam + assembledQueryParams;
    case 'getauctionslist':
      return apiBaseUrl + 'auctions';
    case 'getrecap':
      return apiBaseUrl + 'auctions/synthesis' + assembledQueryParams;
    case 'getallrecaps':
      return apiBaseUrl + 'auctions/synthesis';

    case 'getnotes':
      return apiBaseUrl + 'notes' + assembledQueryParams;
    case 'sendnote':
      return apiBaseUrl + 'notes';
    case 'deletenote':
      return apiBaseUrl + 'notes/' + urlParam;
    case 'editnote':
      return apiBaseUrl + 'notes/' + urlParam;

    case 'getbde':
      return apiBaseUrl + 'bde' + assembledQueryParams;
    case 'getmib':
      return apiBaseUrl + 'mib' + assembledQueryParams;

    case 'getlink':
      return apiBaseUrl + 'links/get/' + assembledQueryParams;
    case 'getalllinks':
      return apiBaseUrl + 'links/get/all_links';
    case 'addlink':
      return apiBaseUrl + 'admin/links/post/' + assembledQueryParams;
    case 'addunitlink':
      return apiBaseUrl + 'admin/links/UP/' + urlParam;
    case 'editlink':
      return apiBaseUrl + 'admin/links/patch/' + assembledQueryParams;
    case 'editunitlink':
      return apiBaseUrl + 'admin/links/UP/' + urlParam;
    case 'deletelink':
      return apiBaseUrl + 'admin/links/delete/';
    case 'deleteunitlink':
      return apiBaseUrl + 'admin/links';

    case 'setunitbalanceable':
      return apiBaseUrl + 'admin/auctions/upOption/' + urlParam + '/balanceable';
    case 'setunitmibvisibility':
      return apiBaseUrl + 'admin/auctions/upOption/' + urlParam + '/show_mib';
    case 'setunitpowermaxvisibility':
      return apiBaseUrl + 'admin/auctions/upOption/' + urlParam + '/show_power_max';
    case 'setunitpowerminvisibility':
      return apiBaseUrl + 'admin/auctions/upOption/' + urlParam + '/show_power_min';
    case 'setunitpvmvisibility':
      return apiBaseUrl + 'admin/auctions/upOption/' + urlParam + '/show_pvm';
    case 'setunitthresholdduration':
      return apiBaseUrl + 'admin/auctions/upOption/' + urlParam + '/unbalanced/alarm_minutes';
    case 'setunitthresholdvalue':
      return apiBaseUrl + 'admin/auctions/upOption/' + urlParam + '/unbalanced/alarm_threshold';
    case 'getallunitsoptions':
      return apiBaseUrl + 'getUPOptions';
  }
}

function buildQueryParams(params: Object): string {
  if(params) {
    let queryParams = '?';
    const queryParamsKeys = Object.keys(params);
    queryParamsKeys.forEach(key => {
      if(params[key] !== undefined) {
        queryParams += key + '=' + params[key] + '&';
      }
    });
    return queryParams.slice(0, -1);
  } else return '';
}