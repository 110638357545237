<div [ngClass]="[
  'alert',
  theme,
  'alert-speed-'+fadeTime,
  autocloseTimeout ? 'alert-autoclose' : ''
]">
  <div
    class="alert-autoclose-animation"
    *ngIf="autocloseTimeout"
    [ngStyle]="{'animation-duration': autocloseTimeout ? autocloseTimeout + 's' : '0'}">
  </div>
  <i *ngIf="iconID" [ngClass]="['alert-icon icon-gun-metal', iconID, iconSize]"></i>
  <div class="alert-text" [innerHTML]="message | translate"></div>
  <div class="alert-controls">
    <label [attr.for]="rememberCheckID" class="alert-checkbox checkbox" *ngIf="hasRememberCheck">
      <input type="checkbox" class="checkbox-input" [attr.id]="rememberCheckID" [(ngModel)]="isRememberChecked">
      <span class="checkbox-checkmark"></span>
      <span class="checkbox-label" [innerText]="rememberCheckMessage | translate"></span>
    </label>
    <i class="alert-close icon-cross icon-medium icon-gun-metal" tabindex="0" (click)="dismiss()"></i>
  </div>
</div>