/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

import { environment } from 'src/environments/environment';
import { Env } from 'src/app/enums/environment.enum';

const awsmobile = environment.id === Env.PROD ? {
  // --- PROD ---
  "aws_project_region": "eu-central-1",
  "aws_cognito_region": "eu-central-1",
  "aws_user_pools_id": "eu-central-1_LNure0K3P",
  "aws_user_pools_web_client_id": "2qumdrhrbl5ahjbr8q9vto436q",
  "oauth": {
    domain: "auth-prod.pdp.alperia.digital",
    scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
    redirectSignIn: "https://app.prod.pdp.alperia.digital",
    redirectSignOut: "https://app.prod.pdp.alperia.digital",
    responseType: "token",
    client_id: "2qumdrhrbl5ahjbr8q9vto436q"
  },
} : environment.id === Env.TEST ? {
  // --- TEST ---
  "aws_project_region": "eu-central-1",
  "aws_cognito_region": "eu-central-1",
  "aws_user_pools_id": "eu-central-1_A9BiLdFr6",
  "aws_user_pools_web_client_id": "7qo8fk0hth06tupm3s4g9sg8a2",
  "oauth": {
    domain: "auth-noprod-test.auth.eu-central-1.amazoncognito.com",
    scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
    redirectSignIn: "https://app.noprod-test.pdp.alperia.digital",
    redirectSignOut: "https://app.noprod-test.pdp.alperia.digital",
    responseType: "token",
    client_id: "7qo8fk0hth06tupm3s4g9sg8a2"
  },
} : {
  // --- DEV ---
  "aws_project_region": "eu-central-1",
  "aws_cognito_region": "eu-central-1",
  "aws_user_pools_id": "eu-central-1_SNQQiXOUI",
  "aws_user_pools_web_client_id": "urivujbj369vo9f2bpqinrnne",
  "oauth": {
    domain: "auth-noprod-dev.auth.eu-central-1.amazoncognito.com",
    scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
    redirectSignIn: window.location.href, //https://app.noprod-dev.pdp.alperia.digital
    redirectSignOut: window.location.href, //https://app.noprod-dev.pdp.alperia.digital
    responseType: "token",
    client_id: "urivujbj369vo9f2bpqinrnne"
  },
}

export default awsmobile;
