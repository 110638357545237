import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { combineLatest, of, Subject } from 'rxjs';
import { catchError, filter, first, takeUntil } from 'rxjs/operators';
import { AuctionID } from 'src/app/interfaces/auction';
import { AuctionConfig } from 'src/app/interfaces/auction-config';
import { PreviewTableList } from 'src/app/interfaces/table-preview';
import { AuctionService } from 'src/app/services/http/auction/auction.service';
import { ConfigService} from 'src/app/services/http/config/config.service';
import { AppService, StoredLinksList } from 'src/app/services/local/app/app.service';
import {
  addAuctionLinkErrorAlertConfiguration,
  addAuctionLinkSuccessAlertConfiguration,
  deleteAuctionLinkErrorAlertConfiguration,
  deleteAuctionLinkSuccessAlertConfiguration,
  LINK_CONFIG_KEY,
  URL_WITH_PROTOCOL_PATTERN
} from 'src/app/utils/consts';
import { generateAlertComponent } from 'src/app/utils/functions';
import { AlertComponent } from '../alert/alert.component';

@Component({
  selector: 'app-auction-config',
  templateUrl: './auction-config.component.html',
})
export class AuctionConfigComponent implements OnInit, OnDestroy {

  public auctionsList: PreviewTableList[] = [];
  public currentConfig: AuctionConfig = {
    global: '',
    auctions: []
  };
  public auctionConfigForm: UntypedFormGroup;
  public isAuctionListReady: boolean = false;

  private readonly onDestroy$ = new Subject<void>();
  private readonly URL_VALIDATORS = [
    Validators.required,
    Validators.pattern(URL_WITH_PROTOCOL_PATTERN)
  ];

  private feedbackAlert: ComponentRef<AlertComponent>;

  @ViewChild('alertBox', {read: ViewContainerRef, static: false}) alertBox: ViewContainerRef;

  constructor(
    private auctionService: AuctionService,
    private configService: ConfigService,
    private app: AppService,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {}

  ngOnInit() {
    this.auctionConfigForm = new UntypedFormGroup({
      global: new UntypedFormControl('', this.URL_VALIDATORS)
    });

    const getAuctionList$ = this.auctionService.getAuctionsList().pipe(
      first(),
      catchError(_ => of([]))
    );
    const getStoredLinks$ = this.app.getExternalLinks().pipe(
      takeUntil(this.onDestroy$),
      filter(linksList => Boolean(linksList)),
      catchError(_ => of([]))
    );

    combineLatest([
      getAuctionList$,
      getStoredLinks$
    ]).pipe(
      takeUntil(this.onDestroy$),
      filter(linksList => Boolean(linksList))
    ).subscribe(
      ([auctionsList, linksList]) => {
        this.auctionsList = auctionsList;
        this.currentConfig.global = (linksList as StoredLinksList)?.global || '';
        this.auctionConfigForm.controls.global = new UntypedFormControl(this.currentConfig.global, this.URL_VALIDATORS);

        this.auctionsList?.forEach(auction => {
          const storedLink = linksList[auction.auctionID] || '';
          if(
            !Boolean(this.auctionConfigForm.controls[auction.auctionID]) &&
            this.auctionConfigForm.controls[auction.auctionID]?.value === this.currentConfig.auctions[auction.auctionID]
          ) {
            this.auctionConfigForm.controls[auction.auctionID] = new UntypedFormControl(storedLink, this.URL_VALIDATORS);
          }
          this.currentConfig.auctions[auction.auctionID] = storedLink;
        });
        this.isAuctionListReady = true;
      }
    );
  }

  ngOnDestroy() { this.onDestroy$.next(); }

  public setLink(auctionID: AuctionID): void {
    const linkUrl = Boolean(auctionID) ?
      this.auctionConfigForm.controls[auctionID].value :
      this.auctionConfigForm.controls.global.value;
    this.configService.actions.links.auction.set(
      Boolean(auctionID) ?
        auctionID :
        undefined,
      {
        label: LINK_CONFIG_KEY,
        url: linkUrl
      }
    ).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      (response) => {
        if(this.feedbackAlert) {
          this.feedbackAlert.destroy();
        }
        this.feedbackAlert = generateAlertComponent(this.componentFactoryResolver, this.alertBox, addAuctionLinkSuccessAlertConfiguration);
        this.app.storeAuctionExternalLink(
          Boolean(auctionID) ?
            auctionID :
            'global',
          linkUrl
        );
      },
      (error) => {
        if(this.feedbackAlert) {
          this.feedbackAlert.destroy();
        }
        this.feedbackAlert = generateAlertComponent(this.componentFactoryResolver, this.alertBox, addAuctionLinkErrorAlertConfiguration);
      }
    );
  }

  public clearField(auctionID: AuctionID): void {
    this.configService.actions.links.auction.delete(
      LINK_CONFIG_KEY,
      Boolean(auctionID) ? auctionID : undefined,
    ).pipe(
      first()
    ).subscribe(
      (response) => {
        if(this.feedbackAlert) {
          this.feedbackAlert.destroy();
        }
        this.feedbackAlert = generateAlertComponent(this.componentFactoryResolver, this.alertBox, deleteAuctionLinkSuccessAlertConfiguration);
        this.auctionConfigForm.controls[auctionID || 'global'].setValue('');
        this.auctionConfigForm.controls[auctionID || 'global'].updateValueAndValidity();
      },
      (error) => {
        if(this.feedbackAlert) {
          this.feedbackAlert.destroy();
        }
        this.feedbackAlert = generateAlertComponent(this.componentFactoryResolver, this.alertBox, deleteAuctionLinkErrorAlertConfiguration);
      }
    );
  }

  public isFieldValid(auctionID: AuctionID|'global'): boolean {
    return (
      Boolean(this.auctionConfigForm.controls[auctionID].value) &&
      this.auctionConfigForm.controls[auctionID].value !== (
        auctionID === 'global' ?
          this.currentConfig.global :
          this.currentConfig.auctions[auctionID]
      ) &&
      this.auctionConfigForm.controls[auctionID].valid
    );
  }
}