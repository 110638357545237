import { PreviewTable } from 'src/app/interfaces/table-preview';
import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { environment } from "src/environments/environment";
import { ComponentFactoryResolver, ComponentRef, ViewContainerRef } from "@angular/core";
import { AlertComponent } from "src/app/components/shared/alert/alert.component";
import { AlertConfiguration } from "src/app/interfaces/alert-configuration";
import { ModalConfiguration } from "src/app/interfaces/modal-config";
import { UnitTable } from 'src/app/interfaces/table-report';
import { BDE_WARN_TIME, MIB_WARN_TIME, UNIT_LIST } from './consts';
import * as dayjs from 'dayjs';
import { BdeList } from 'src/app/interfaces/bde-list';
import { MIBList } from '../interfaces/mib-list';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import AWSConfig from '../../aws-exports';
import { StorageKeys } from '../enums/storage-keys';
import { UserData } from '../interfaces/user-data';
import { UserType } from '../enums/user-type.enum';
import { first } from 'rxjs/operators';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.baseUrls.httpLoaderPrefix, environment.baseUrls.httpLoaderSuffix);
}

export function fixTimestampNumber(number: number): string {
  return number < 10 ?
    '0' + number.toString() :
    number.toString()
}

export function unixTimestampToDateTime(
  unixTimestamp: number,
  dateSeparator: string = '/',
  timeSeparator: string = ':',
  dateTimeSeparator: string = ', '
  ): string {
  const year = new Date(unixTimestamp).getFullYear().toString();
  const month = fixTimestampNumber( (new Date(unixTimestamp).getMonth() + 1) );
  const date = fixTimestampNumber( new Date(unixTimestamp).getDate() );
  const hour = fixTimestampNumber( new Date(unixTimestamp).getHours() );
  const minute = fixTimestampNumber( new Date(unixTimestamp).getMinutes() );
  return (
    date +
    dateSeparator +
    month +
    dateSeparator +
    year +
    dateTimeSeparator +
    hour +
    timeSeparator +
    minute
  );
}

export function floorMinutesToQuarter(minutes: number): number {
  switch(true) {
    case minutes >= 0 && minutes < 15:
      return 0;
    case minutes >= 15 && minutes < 30:
      return 15;
    case minutes >= 30 && minutes < 45:
      return 30;
    case minutes >= 45 && minutes < 60:
      return 45;
  }
}

export function generateAlertComponent(componentFactoryResolver: ComponentFactoryResolver, containerNodeReference: ViewContainerRef, alertConfiguration: AlertConfiguration): ComponentRef<AlertComponent> {
  const factory = componentFactoryResolver.resolveComponentFactory(AlertComponent);
  const component = containerNodeReference.createComponent(factory);

  Object.keys(alertConfiguration).forEach(configurationKey => {
    component.instance[configurationKey] = alertConfiguration[configurationKey];
  });
  const dismissSubscription = component.instance.onDismiss.pipe(
    first()
  ).subscribe(state => {
    if(alertConfiguration.hasRememberCheck && state) {
      localStorage.setItem(alertConfiguration.rememberCheckStorageKey, 'true');
    }
    dismissSubscription.unsubscribe();
    component.destroy();
  });

  component.changeDetectorRef.detectChanges();
  return component;
}
export function generateModalComponent(
  componentFactoryResolver: ComponentFactoryResolver,
  containerNodeReference: ViewContainerRef,
  modalConfiguration: ModalConfiguration
): ComponentRef<any> {
  let component: ComponentRef<any>;
  const factory = componentFactoryResolver.resolveComponentFactory(modalConfiguration.componentType);
  component = containerNodeReference.createComponent(factory);
  component.instance.config = modalConfiguration;
  const closeSubscription = component.instance.onClose.subscribe(_ => {
    closeSubscription.unsubscribe();
    component.destroy();
  });
  component.changeDetectorRef.detectChanges();
  return component;
}

export function removeValueFromArray(array: Array<any>, value: any): Array<any> {
  const indexOfValue = array.indexOf(value);
  if(indexOfValue >= 0) {
    array.splice(indexOfValue, 1);
  }
  return array;
}

export function calculateDelta(power: number, pvm: number, pv: number): number {
  return power != null && pvm != null ?
    power - pvm :
    power != null && pv != null ?
      power - pv :
      pv; 
}

export function setUnitOrder(unorderedUnit: UnitTable | PreviewTable | BdeList | MIBList): UnitTable | PreviewTable | BdeList | MIBList {
  const orderedUnit = UNIT_LIST.filter(orderedUnit => orderedUnit.unitCode === unorderedUnit.unitCode)[0];
  unorderedUnit.order = orderedUnit ?
    orderedUnit.order :
    null;
  return unorderedUnit;
}

export function sortUnits(a: UnitTable | PreviewTable | BdeList | MIBList, b: UnitTable | PreviewTable | BdeList | MIBList) {
  if(a.order > b.order) { return 1; }
  else if(a.order < b.order) { return -1; }
  else { return 0; }
}

export function deepCopy(inObject) {
  let outObject, value, key;
  if (typeof inObject !== "object" || inObject === null) {
    return inObject;
  }
  outObject = Array.isArray(inObject) ? [] : {};
  for (key in inObject) {
    value = inObject[key];
    outObject[key] = deepCopy(value);
  }
  return outObject;
}

export function isBDEIncoming(nextBDETimestamp: string): boolean {
  if(nextBDETimestamp) {
    return dayjs(nextBDETimestamp).subtract(BDE_WARN_TIME, 'minute').valueOf() < dayjs().valueOf();
  } else {
    return false;
  }
}

export function isMIBIncoming(nextMIBTimestamp: string): boolean {
  if(nextMIBTimestamp) {
    return dayjs(nextMIBTimestamp).subtract(MIB_WARN_TIME, 'minute').valueOf() < dayjs().valueOf();
  } else {
    return false;
  }
}

export function getNgbDateStructFromDate(date: Date): NgbDateStruct {
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate()
  };
}

export function getAuthenticationUrl(): string {
  const loginUrl = environment.baseUrls.authenticationUrl;
  const clientId = AWSConfig.oauth.client_id;
  return (
    loginUrl.base +
    Object.keys(loginUrl.options).map(
      key => `${key}=${loginUrl.options[key]}`
    ).join('&') + `&client_id=${clientId}`
  );
}

export function redirectToAuthentication(): void {
  const route = window.location.pathname;
  const params = window.location.search;
  localStorage.setItem(StorageKeys.LAST_VISITED_PAGE, route + params);
  window.location.assign(getAuthenticationUrl());
}

export function isNumberGreaterThan(input: number, threshold: number): boolean {
  return input > threshold;
}

export function proportionalRange(
  oldMin: number, oldMax: number,
  newMin: number, newMax: number,
  value: number
): number {
  return ((newMax - newMin) / (oldMax - oldMin)) * (value - oldMin) + newMin;
}

export function isUserAdmin(userData: UserData): boolean {
  return userData.userType.includes(UserType.ADMIN);
}

export function sanitizeUnitCodeForTemplateID(templateID: string): string {
  return templateID.split('.').join('');
}

export function isNodeDescendant(parent: HTMLElement, child: HTMLElement) {
  var node = child.parentNode;
  while (node != null) {
    if (node == parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
}

export function swapArrayItems(array: Array<any>, itemPosition1: number, itemPosition2: number): Array<any> {
  [array[itemPosition1], array[itemPosition2]] = [array[itemPosition2], array[itemPosition1]];
  return array;
}