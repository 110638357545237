import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {
  NULL_UNIT_SELECTION,
  NULL_QUARTER_DETAIL,
  UnitTable,
  UnitTableQuarterDetail,
  UnitTableSelection,
  UnitTableValues
} from 'src/app/interfaces/table-report';
import { AuctionDataTypeList } from 'src/app/interfaces/auction-data-type-list';
import {
  auctionDataTypeList,
  UNIT_ID_LINK_PREFIX,
  RELATIVE_LINK_SCROLL_OFFSET,
  auctionGuides,
  auctionConnectionErrorAlertConfig,
  DATA_UPDATE_FREQUENCY,
  LOADER_CLASS_PREFIX,
  ALL_AUCTIONS_ID
} from 'src/app/utils/consts';
import { UnitDataType } from 'src/app/enums/unit-data-type.enum';
import { PreviewTable } from 'src/app/interfaces/table-preview';
import { Auction, AuctionGuide, AuctionID } from 'src/app/interfaces/auction';
import { ActivatedRoute } from '@angular/router';
import { AuctionService } from 'src/app/services/http/auction/auction.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ModalService } from 'src/app/services/local/modal/modal.service';
import { ModalConfiguration } from 'src/app/interfaces/modal-config';
import {
  calculateDelta,
  generateAlertComponent,
} from 'src/app/utils/functions';
import * as dayjs from 'dayjs';
import { Note } from 'src/app/interfaces/notes';
import { UnitCode } from 'src/app/enums/unit-codes.enum';
import { AlertComponent } from 'src/app/components/shared/alert/alert.component';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpStatusCode } from 'src/app/enums/http-status-code.enum';
import { AppService } from 'src/app/services/local/app/app.service';
import { AlertConfiguration } from 'src/app/interfaces/alert-configuration';
import { AccordionStyle } from 'src/app/components/shared/accordion/accordion.component';
import { ClockService } from 'src/app/services/local/clock/clock.service';
import { ModalID } from 'src/app/enums/modal-id.enum';
import { ModalChartZoomComponent } from '../../shared/modal-chart-zoom/modal-chart-zoom.component';

@Component({
  selector: 'app-auction-page',
  templateUrl: './auction-page.component.html'
})
export class AuctionPageComponent implements OnInit, OnDestroy {

  private readonly onDestroy$ = new Subject<void>();
  private readonly HEADER_NODE_SELECTOR = 'header';
  private readonly AUCTION_STICKY_TARGET_NODE_SELECTOR = '.auction-page-filters';
  private readonly REPORT_LOADER_CLASS = LOADER_CLASS_PREFIX + '-report';
  private readonly RECAP_LOADER_CLASS = LOADER_CLASS_PREFIX + '-recap';
  private readonly auctionGuides = auctionGuides;

  public readonly AUCTION_DATA_TYPE_LIST: AuctionDataTypeList[] = auctionDataTypeList;
  public readonly UNIT_DATA_TYPE = UnitDataType;
  public readonly UNIT_ID_LINK_PREFIX = UNIT_ID_LINK_PREFIX;
  public readonly AccordionStyle = AccordionStyle;
  public readonly ALL_AUCTIONS_ID = ALL_AUCTIONS_ID;

  private currentModalConfig: ModalConfiguration;
  private reportInterval: any;
  private recapInterval: any;
  private previousAuctionID: '';
  private errorAlert: ComponentRef<AlertComponent>;
  private asyncScroll: boolean = false;
  private lastScrollID: string;

  public date: dayjs.Dayjs = dayjs();
  public currentAuctionID: string;
  public currentAuctionName: string;
  public currentAuction: Auction;
  public currentAuctionPreviewTableDataset: PreviewTable[];
  public currentAuctionReportTableDataset: UnitTable[];
  public currentTab: AuctionDataTypeList = auctionDataTypeList[0];
  public selectedReportCell: UnitTableSelection = NULL_UNIT_SELECTION;
  public isSidebarOpen: boolean;
  public areToolsSticky: boolean = false;
  public currentAuctionGuide: AuctionGuide[];
  public quarterDetail: UnitTableQuarterDetail = NULL_QUARTER_DETAIL;
  public notebarOpened: boolean = true;
  public dateSelectorMaxDate: Date = dayjs().startOf('day').add(1, 'day').toDate();
  public isViewAllPage: boolean = false;

  @ViewChild('alertBox', {read: ViewContainerRef, static: false}) alertBox: ViewContainerRef;

  constructor(
    private route: ActivatedRoute,
    private auctionService: AuctionService,
    private modalService: ModalService,
    private factoryResolver: ComponentFactoryResolver,
    private app: AppService,
    private clock: ClockService,
    private changeDetectorReference: ChangeDetectorRef,
  ) {
    this.clock.time.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((now: Date) => {
      this.dateSelectorMaxDate = dayjs().startOf('day').add(1, 'day').toDate();
    });
  }

  ngOnInit() {
    this.getAuctionIdFromUrl();
    const selectedGuide = this.auctionGuides.filter(guide => guide.tabID === this.currentTab.id)[0];
    this.currentAuctionGuide = selectedGuide ?
      selectedGuide.guides :
      [];
  }

  ngAfterViewInit() {
    this.getUnitIdFromUrl();
    this.stickyToolsEvent();
    this.app.getNotebarVisibility().pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(isNotebarOpen => {
      this.notebarOpened = isNotebarOpen;
      this.changeDetectorReference.detectChanges();
    });
  }

  ngOnDestroy() {
    clearInterval(this.recapInterval);
    clearInterval(this.reportInterval);
    this.onDestroy$.next();
  }

  public switchTab(selectedTab: AuctionDataTypeList): void {
    this.currentTab = selectedTab;
    const selectedGuide = this.auctionGuides.filter(guide => guide.tabID === selectedTab.id)[0];
    this.currentAuctionGuide = selectedGuide ?
      selectedGuide.guides :
      [];
  }

  public switchDate(newDate: Date): void {
    this.date = dayjs(newDate);
    if (!!this.currentAuctionID) {
      this.getAuctionReport(this.currentAuction.id, this.date.format('YYYY-MM-DD'));
    } else {
      this.getAllAuctionReports(this.date.format('YYYY-MM-DD'))
    }
  }

  public chartZoom(event: Event): void {
    const target = <HTMLElement> event.target;
    const targetUnitCode: UnitCode = <UnitCode> target.getAttribute('data-unit');
    const selectedDataset = this.currentAuctionReportTableDataset.filter(unit => unit.unitCode === targetUnitCode)[0];
    this.currentModalConfig = {
      id: ModalID.CHART_ZOOM,
      componentType: ModalChartZoomComponent,
      content: {
        head: {
          title: targetUnitCode,
          closeIconID: 'icon-zoom-out',
        },
        body: {
          chart: {
            id: 'chart-zoom',
            dataset: selectedDataset,
            canvasWidth: window.innerWidth - 80 - 200, // 80 = modal padding; 100*2 = modal margin
            canvasHeight: window.innerHeight - 70 - 200 - 100, // 70 = header height; 100*2 = modal margin; 100 = average modal head
          }
        }
      }
    }
    this.modalService.openModal(this.currentModalConfig);
  }

  public addNoteBadge(addedNote: Note): void {
    const noteUnit = this.currentAuctionReportTableDataset.find(unit => unit.unitCode === addedNote.unitCode);
    noteUnit.values.forEach(hour => {
      const noteQuarter = hour.find(quarter => dayjs(quarter.startTime).valueOf() === dayjs(addedNote.quarter).valueOf());
      if(noteQuarter) {
        noteQuarter.withNote = true;
      }
    });
    //this.app.storeUnits(this.currentAuctionID, this.date.format('YYYY-MM-DD'), this.currentAuctionReportTableDataset);
  }

  public removeNoteBadge(deletedNote: Note): void {
    const noteUnit = this.currentAuctionReportTableDataset.find(unit => unit.unitCode === deletedNote.unitCode);
    noteUnit.values.forEach(hour => {
      const noteQuarter = hour.find(quarter => dayjs(quarter.startTime).valueOf() === dayjs(deletedNote.quarter).valueOf());
      if(noteQuarter) {
        noteQuarter.withNote = false;
      }
    });
    //this.app.storeUnits(this.currentAuctionID, this.date.format('YYYY-MM-DD'), this.currentAuctionReportTableDataset);
  }

  public isUnitDataAvailable(unit: UnitTable, currentTab: AuctionDataTypeList): boolean {
    let dataKey: string;
    let valueList = [];
    let res: boolean = true;
    switch(currentTab.id) {
      case this.UNIT_DATA_TYPE.PV:
        dataKey = 'pvActual';
      break;

      case this.UNIT_DATA_TYPE.PVM_PV:
        dataKey = 'pvm';
      return true;

      case this.UNIT_DATA_TYPE.PVM_P:
        dataKey = 'pvm';
      break;

      case this.UNIT_DATA_TYPE.P_MAX:
        dataKey = 'powerMax';
      break;

      default: return true;
    }
    unit.values.forEach(hour => {
      valueList = [...valueList, ...hour.map(value => value[dataKey])];
    });
    res = valueList.some(value => value !== null);
    return res;
  }

  public isUnitNotEnabled(unit: UnitTable): boolean {
    return !unit.isQuarterly && (
      this.currentTab.id === this.UNIT_DATA_TYPE.PVM_PV ||
      this.currentTab.id === this.UNIT_DATA_TYPE.PVM_P ||
      this.currentTab.id === this.UNIT_DATA_TYPE.P_MAX
    );
  }

  private getAuctionIdFromUrl(): void {
    this.auctionService.getAuctionsList().pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((auctionList) => {
      this.route.queryParams.pipe(
        takeUntil(this.onDestroy$)
      ).subscribe(params => {
        if(params?.scrollTo) {
          this.lastScrollID = params.scrollTo;
        }
        if(params?.id != this.previousAuctionID) {
          this.app.setSelectedUnitCell(NULL_UNIT_SELECTION);
          this.selectedReportCell = NULL_UNIT_SELECTION;
          this.previousAuctionID = params.id;

          this.currentAuctionPreviewTableDataset = [];
          this.currentAuctionReportTableDataset = [];
          clearInterval(this.recapInterval);
          clearInterval(this.reportInterval);

          this.isViewAllPage = params.id === ALL_AUCTIONS_ID;
          if(!this.isViewAllPage) {
            const searchAuction = auctionList.find(auction => auction.auctionID === params.id);
            this.currentAuction = {
              id: searchAuction.auctionID,
              name: searchAuction.auctionName,
            };
            this.currentAuctionID = this.currentAuction.id;
            this.currentAuctionName = this.currentAuction.name;
            this.getAuctionPreview(this.currentAuction.id);
            this.getAuctionReport(this.currentAuction.id, this.date.format('YYYY-MM-DD'));
            this.recapInterval = setInterval(() => { this.getAuctionPreview(this.currentAuction.id) }, DATA_UPDATE_FREQUENCY * 1000);
            this.reportInterval = setInterval(() => { this.getAuctionReport(this.currentAuction.id, this.date.format('YYYY-MM-DD')) }, DATA_UPDATE_FREQUENCY * 1000);
          } else {
            this.currentAuction = {
              id: null,
              name: null,
            };
            this.currentAuctionID = this.currentAuction.id;
            this.currentAuctionName = this.currentAuction.name;
            this.getAuctionsPreview();
            this.getAllAuctionReports(this.date.format('YYYY-MM-DD'));
            this.recapInterval = setInterval(() => { this.getAuctionsPreview() }, DATA_UPDATE_FREQUENCY * 1000);
            this.reportInterval = setInterval(() => { this.getAllAuctionReports(this.date.format('YYYY-MM-DD')) }, DATA_UPDATE_FREQUENCY * 1000);
          }
        }
      });
    });
  }

  private getUnitIdFromUrl(): void {
    this.route.queryParams.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(params => {
      if( params && params.scrollTo ) {
        this.scrollToElement(params.scrollTo);
      }
    });
  }

  private scrollToElement(scrollID: string): void {
    if(scrollID) {
      const element = document.querySelector(`[data-scroll='${scrollID}']`);
      if(element) {
        const elementPosition = element.getBoundingClientRect().top;
        window.scrollTo({
          top: elementPosition - RELATIVE_LINK_SCROLL_OFFSET,
          behavior: 'smooth'
        });
        this.asyncScroll = false;
      } else {
        this.asyncScroll = true;
      }
    }
  }

  private setRelativeLinks(): void {
    if(this.currentAuctionPreviewTableDataset && this.currentAuctionReportTableDataset) {
      document.querySelectorAll('[data-scrollto]').forEach(link => {
        link.addEventListener('click', (event) => {
          event.preventDefault();
          const nodeID = link.getAttribute('data-scrollto');
          const node = document.querySelector(`[data-scroll='${nodeID}']`);
          const nodePosition = node.getBoundingClientRect().top;
          window.scrollTo({
            top: nodePosition - RELATIVE_LINK_SCROLL_OFFSET,
            behavior: 'smooth'
          });
        });
      });
    }
  }

  private getAuctionPreview(auctionID: AuctionID): void {
    document.body.classList.add(this.RECAP_LOADER_CLASS);
    this.auctionService.getRecap(auctionID).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      (response: PreviewTable[]) => {
        this.resetErrorAlert();
        this.currentAuctionPreviewTableDataset = response;
        this.setRelativeLinks();
        document.body.classList.remove(this.RECAP_LOADER_CLASS);
      },
      (error: HttpErrorResponse) => {
        document.body.classList.remove(this.RECAP_LOADER_CLASS);
        this.setErrorAlert(auctionConnectionErrorAlertConfig);
      }
    );
  }

  private getAuctionsPreview(): void {
    document.body.classList.add(this.RECAP_LOADER_CLASS);
    this.auctionService.getAllRecaps().pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      (response: PreviewTable[]) => {
        this.resetErrorAlert();
        this.currentAuctionPreviewTableDataset = response;
        this.setRelativeLinks();
        document.body.classList.remove(this.RECAP_LOADER_CLASS);
      },
      (error: HttpErrorResponse) => {
        document.body.classList.remove(this.RECAP_LOADER_CLASS);
        this.setErrorAlert(auctionConnectionErrorAlertConfig);
      }
    );
  }

  private getAuctionReport(auctionID: AuctionID, date: string): void {
    document.body.classList.add(this.REPORT_LOADER_CLASS);
    this.auctionService.getAuction(auctionID, date).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      (response: UnitTable[]) => {
        this.resetErrorAlert();
        this.onReportDatasetReady(response, date);
      },
      (error: HttpErrorResponse) => {
        document.body.classList.remove(this.REPORT_LOADER_CLASS);
        if(error.status === HttpStatusCode.NotFound) {
          this.currentAuctionReportTableDataset.forEach(unit => {
            unit.values.forEach(hour => {
              hour.forEach(quarter => {
                Object.keys(quarter).forEach(key => quarter[key] = null);
              });
            });
          });
        } else {
          this.setErrorAlert(auctionConnectionErrorAlertConfig);
        }
      }
    );
  }

  private getAllAuctionReports(date: string): void {
    document.body.classList.add(this.REPORT_LOADER_CLASS);
    this.auctionService.getAllAuctions(date).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      (response: UnitTable[]) => {
        this.resetErrorAlert();
        this.onReportDatasetReady(response, date);
      },
      (error: HttpErrorResponse) => {
        document.body.classList.remove(this.REPORT_LOADER_CLASS);
        if(error.status === HttpStatusCode.NotFound) {
           this.currentAuctionReportTableDataset.forEach(unit => {
            unit.values.forEach(hour => {
              hour.forEach(quarter => {
                Object.keys(quarter).forEach(key => quarter[key] = null);
              });
             });
          });
        } else {
          this.setErrorAlert(auctionConnectionErrorAlertConfig);
        }
      }
    );
  }

  private onReportDatasetReady(response: UnitTable[], date: string): void {
    document.body.classList.remove(this.REPORT_LOADER_CLASS);
    if(
      this.currentAuctionID === this.previousAuctionID &&
      this.currentAuctionReportTableDataset?.length > 0 &&
      this.currentAuctionReportTableDataset[0].unitDate === date
    ) {
      this.currentAuctionReportTableDataset.forEach(dataset => {
        const newDataset = response.find(unit => unit.unitCode === dataset.unitCode);
        dataset.unitDate = newDataset.unitDate;
        dataset.values = newDataset.values;
      });
    } else {
      this.currentAuctionReportTableDataset = response;
    }
    this.setRelativeLinks();
    this.getSelectedUnitCell();
    //setTimeout(() => { this.syncScroll('.accordion-body-inner.scroll'); }, 500);
    if(this.asyncScroll) {
      setTimeout(() => { this.scrollToElement(this.lastScrollID); }, 500);
    }
  }

  private stickyToolsEvent(): void {
    const headerHeight = document.querySelector(this.HEADER_NODE_SELECTOR).offsetHeight;
    const target: HTMLElement = document.querySelector(this.AUCTION_STICKY_TARGET_NODE_SELECTOR);
    let targetTopPosition = target.offsetTop + target.offsetHeight + headerHeight;
    window.addEventListener('scroll', (event) => {
      if(!this.areToolsSticky) {
        targetTopPosition = target.offsetTop + target.offsetHeight + headerHeight;
      }
      if(window.pageYOffset > targetTopPosition) {
        this.areToolsSticky = true;
      } else {
        this.areToolsSticky = false;
      }
    });
  }

  private getSelectedUnitCell(): void {
    this.app.getSelectedUnitCell().pipe(
      takeUntil(this.onDestroy$)
    ).subscribe( (selectedUnitCell: UnitTableSelection) => {
      this.selectedReportCell = selectedUnitCell;
      this.generateUnitDetail();
    });
  }

  private generateUnitDetail(): void {
    if(this.selectedReportCell.unitCode) {
      const selectedUnit = this.currentAuctionReportTableDataset.find(unit => unit.unitCode === this.selectedReportCell.unitCode);
      let selectedQuarter: UnitTableValues;
      selectedUnit.values.forEach(hour => {
        const search = hour.find(quarter => quarter.startTime === this.selectedReportCell.startTime);
        if(search) {
          selectedQuarter = search;
        }
      });
      if(selectedQuarter) {
        this.quarterDetail.delta = calculateDelta(selectedQuarter.power, selectedQuarter.pvm, selectedQuarter.pvActual) || '-';
        //this.quarterDetail.mw = '-'; //TODO verify value
      }
    } else {
      this.quarterDetail.delta = null;
      this.quarterDetail.mw = null;
    }
  }

  private resetErrorAlert(): void {
    if(this.errorAlert) {
      this.errorAlert.destroy();
      this.errorAlert = null;
    }
  }

  public totalPv(tableReport: UnitTable) {
    let totalPv: number = 0;
    if(tableReport.values && tableReport.values.length) {
      tableReport.values.forEach(hour => {
        if(hour && hour.length === 1) {
          totalPv = totalPv + hour[0].pvActual;
        } else if(hour && hour.length === 4) {
          let hourlySum: number = 0;
          hour.forEach(quarter => {
            hourlySum = hourlySum + quarter.pvActual;
          });
          totalPv = totalPv + hourlySum / 4;
        }
      });
    }
    return totalPv;
  }

  private setErrorAlert(alertConfig: AlertConfiguration): void {
    if(!this.errorAlert) {
      this.errorAlert = generateAlertComponent(this.factoryResolver, this.alertBox, alertConfig);
      this.errorAlert.onDestroy(() => { this.errorAlert = null; });
    }
  }

  private syncScroll(nodesSelector: string) {
    const targetNodes = document.querySelectorAll(nodesSelector) as NodeListOf<HTMLElement>;
    targetNodes.forEach(targetNode => {
      targetNode.addEventListener('scroll', (event) => {
        targetNodes.forEach(otherNode => {
          otherNode.scrollLeft = (event.target as HTMLElement).scrollLeft;
        });
      });
    });
  }
}
