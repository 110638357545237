import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { Language } from 'src/app/enums/language.enum';
import { Note, NoteEdit, NoteGroup } from 'src/app/interfaces/notes';
import { ClockService } from 'src/app/services/local/clock/clock.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { floorMinutesToQuarter, generateAlertComponent } from 'src/app/utils/functions';
import { NotesService } from 'src/app/services/http/notes/notes.service';
import { UnitCode } from 'src/app/enums/unit-codes.enum';
import {
  newNoteSuccessAlertConfiguration,
  newNoteErrorAlertConfiguration,
  deleteNoteSuccessAlertConfiguration,
  deleteNoteErrorAlertConfiguration,
  editNoteSuccessAlertConfiguration,
  editNoteErrorAlertConfiguration,
  NOTE_DEFAULT_USER_NAME,
  LOADER_CLASS_PREFIX,
  BREAKPOINT_TABLET_MAX_WIDTH
} from 'src/app/utils/consts';
import { filter, first, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Auction } from 'src/app/interfaces/auction';

import * as dayjs from 'dayjs';
import * as calendar from 'dayjs/plugin/calendar';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import * as utc from 'dayjs/plugin/utc';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import { TimeSelectorTitles } from 'src/app/enums/time-selector-titles.enum';
import { StorageKeys } from 'src/app/enums/storage-keys';
import { AlertComponent } from 'src/app/components/shared/alert/alert.component';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpStatusCode } from 'src/app/enums/http-status-code.enum';
import { AppService } from 'src/app/services/local/app/app.service';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-notebar',
  templateUrl: './notebar.component.html'
})
export class NotebarComponent implements OnInit, OnChanges, OnDestroy {

  private readonly onDestroy$ = new Subject<void>();
  private readonly NOTES_LOADER_CLASS = LOADER_CLASS_PREFIX + '-notes';
  private readonly NOTEBAR_VISIBLE_CLASS = 'notebar-visible';

  private currentLanguage: Language;
  private date: dayjs.Dayjs = dayjs();
  private previousClockTime: number = 0;
  private clockTime: string;
  private currentUser: string = NOTE_DEFAULT_USER_NAME;
  private noteAlert: ComponentRef<AlertComponent>;

  public readonly NOTE_ID_PREFIX = 'note-';
  public readonly NOTE_CONFIRM_DELETE_CLASS = 'show-confirm-delete';

  public isOpen: boolean = true;
  public noteList: Note[] = [];
  public sortedNoteList: NoteGroup[] = [];
  public filteredNoteList: NoteGroup[] = [];
  public newNoteValue: string = '';
  public noteToEdit: NoteEdit = { id: '', message: ''};
  public isHelperVisible: boolean = !Boolean( localStorage.getItem(StorageKeys.NOTEBAR_HELPER_VIEWED) );
  public isNewNoteInputVisible: boolean = false;
  public isEditNoteInputVisible: boolean = false;
  public isSendDisabled: boolean = true;
  public currentDay: string;
  public currentDisplayedDate: string;
  public displayedTime: string;
  public totalNotesCount: number;
  public currentNotesCount: number;
  public textareaPrecompiledValue: string = '';
  public isEditingNote: boolean = false;
  public isAddingNote: boolean = false;
  public newNoteMessageBackup: string = '';
  public editNoteMessageBackup: string = '';

  @Input() auction: Auction;
  @Input() selectedDate: Date;
  @Input() selectedCellTime: string;
  @Input() selectedUnit: UnitCode;
  @Input() selectedAuctionID: string;

  @Output() onAdd: EventEmitter<Note> = new EventEmitter<Note>();
  @Output() onDelete: EventEmitter<Note> = new EventEmitter<Note>();
  
  @ViewChild('alertBox', {read: ViewContainerRef, static: false}) alertBox: ViewContainerRef;

  constructor(
    private translateService: TranslateService,
    private clock: ClockService,
    private notesService: NotesService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private app: AppService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.translateService.onLangChange.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((event: LangChangeEvent) => {
      if(event && event.lang) {
        this.currentLanguage = <Language>event.lang;
        dayjs.locale(<string> this.currentLanguage);
        this.currentDisplayedDate = dayjs(this.selectedDate).format(', DD MMM YYYY');
      }
    });
    this.clock.time.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((now: Date) => {
      const flooredMinutes = floorMinutesToQuarter( dayjs(now).minute() );
      if(flooredMinutes.valueOf() != this.previousClockTime) {
        this.previousClockTime = flooredMinutes.valueOf();
        this.clockTime = dayjs(now).minute(flooredMinutes).format('HH:mm');
        if(!this.selectedCellTime) {
          this.displayedTime = this.clockTime + '-24:00';
        }
      }
    });
  }

  ngOnInit() {
    this.app.getNotebarVisibility().pipe(
      first()
    ).subscribe((isNotebarVisible) => {
      const isTablet = this.breakpointObserver.isMatched(BREAKPOINT_TABLET_MAX_WIDTH);
      this.isOpen = (
        isTablet ? false :
        isNotebarVisible !== null ? isNotebarVisible :
        true
      );
      this.app.updateNotebarVisibility(this.isOpen);
      this.isOpen ?
        document.body.classList.add(this.NOTEBAR_VISIBLE_CLASS) :
        document.body.classList.remove(this.NOTEBAR_VISIBLE_CLASS);
    });
    this.currentLanguage = <Language> this.translateService.currentLang || Language.IT;
    dayjs.locale(<string> this.currentLanguage || Language.IT);
    dayjs.extend(calendar);
    dayjs.extend(localizedFormat);
    dayjs.extend(utc);
    dayjs.extend(relativeTime);
    this.clockTime = dayjs().minute( floorMinutesToQuarter( dayjs().minute() ) ).format('HH:mm');
    this.displayedTime = this.clockTime + '-24:00';
    this.date = dayjs().startOf('day');
    this.currentDay = this.getRelativeDay(this.selectedDate);
    this.currentDisplayedDate = dayjs(this.selectedDate).format(', DD MMM YYYY');
    this.app.getUserData().pipe(
      takeUntil(this.onDestroy$),
      filter((userData) => Boolean(userData?.firstName) && Boolean(userData?.lastName))
    ).subscribe(userData => {
      this.currentUser = `${userData.firstName} ${userData.lastName}`;
    });
    this.newNoteMessageBackup = sessionStorage.getItem(StorageKeys.NEW_NOTE_MESSAGE_BACKUP);
    this.editNoteMessageBackup = sessionStorage.getItem(StorageKeys.EDIT_NOTE_MESSAGE_BACKUP);
    sessionStorage.getItem(StorageKeys.NEW_NOTE_MESSAGE_BACKUP);
    sessionStorage.getItem(StorageKeys.EDIT_NOTE_MESSAGE_BACKUP);
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes) {
      if(changes.selectedUnit || changes.selectedCellTime) {
        const currentUnitCode: UnitCode = changes.selectedUnit && changes.selectedUnit.currentValue ?
          changes.selectedUnit.currentValue :
          this.selectedUnit;
        if(currentUnitCode) {
          this.filteredNoteList = this.sortedNoteList.filter(noteGroup => noteGroup.unitCode == currentUnitCode);
          this.displayedTime = dayjs(this.selectedCellTime).format('HH:mm');
        } else {
          this.filteredNoteList = this.sortedNoteList;
          this.displayedTime = this.clockTime + '-24:00';
          this.isNewNoteInputVisible = false;
          this.isAddingNote = false;
        }
      }

      if(changes.auction && changes.auction.currentValue) {
        this.auction = changes.auction.currentValue;
        this.filteredNoteList = [];
        this.getNotes(this.date.startOf('day').valueOf());
      }

      if(changes.selectedDate && changes.selectedDate.currentValue && dayjs(changes.selectedDate.previousValue).valueOf() != dayjs(changes.selectedDate.currentValue).valueOf()) {
        this.date = dayjs(changes.selectedDate.currentValue);
        this.currentDay = this.getRelativeDay(this.date);
        this.currentDisplayedDate = this.date.format(', DD MMM YYYY');
        this.getNotes(this.date.startOf('day').valueOf());
      }
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  public getValue(value: string): void {
    this.newNoteValue = value;
    this.isSendDisabled = this.newNoteValue.length === 0;
  }

  public getEditValue(value: string): void {
    this.noteToEdit.message = value;
  }

  public sendNote(event: Event): void {
    sessionStorage.setItem(StorageKeys.NEW_NOTE_MESSAGE_BACKUP, this.newNoteValue);
    const newNote: Note = {
      auctionID: this.selectedAuctionID,
      unitCode: this.selectedUnit,
      quarter: dayjs(this.selectedCellTime).utc().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
      author: this.currentUser,
      message: this.newNoteValue,
    }
    this.notesService.sendNote(newNote).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(response => {
      if(response) {
        const isFirstNoteInQuarter = this.noteList.filter(note => (
          note.unitCode === this.selectedUnit &&
          parseInt(<string> note.quarter) === dayjs(this.selectedCellTime).valueOf()
        )).length == 0;
        this.noteList.push({
          ...newNote,
          creationDate: dayjs().valueOf(),
          author: this.currentUser,
          id: <string> response,
        });
        this.updateNoteLists();
        this.hideNewNoteInput();
        this.newNoteValue = '';
        this.isSendDisabled = true;
        if(this.noteAlert) {
          this.noteAlert.destroy();
        }
        this.noteAlert = generateAlertComponent(this.componentFactoryResolver, this.alertBox, newNoteSuccessAlertConfiguration);
        if(isFirstNoteInQuarter) {
          this.onAdd.emit(newNote);
        }
        sessionStorage.removeItem(StorageKeys.NEW_NOTE_MESSAGE_BACKUP);
      } else {
        if(this.noteAlert) {
          this.noteAlert.destroy();
        }
        this.noteAlert = generateAlertComponent(this.componentFactoryResolver, this.alertBox, newNoteErrorAlertConfiguration);
      }
    });
  }

  public deleteNote(noteID: string): void {
    this.notesService.deleteNote(noteID).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(response => {
      if(response) {
        const noteToDelete = this.noteList.find(note => note.id === noteID);
        const noteListIndex = this.noteList.indexOf(noteToDelete);
        this.noteList.splice(noteListIndex, 1);
        this.updateNoteLists();
        const isLastNoteInQuarter = !Boolean( this.noteList.find( note => dayjs(note.quarter).valueOf() === dayjs(noteToDelete.quarter).valueOf() ) );
        if(isLastNoteInQuarter) {
          this.onDelete.emit(noteToDelete);
        }
        if(this.noteAlert) {
          this.noteAlert.destroy();
        }
        this.noteAlert = generateAlertComponent(this.componentFactoryResolver, this.alertBox, deleteNoteSuccessAlertConfiguration);
      } else {
        if(this.noteAlert) {
          this.noteAlert.destroy();
        }
        this.noteAlert = generateAlertComponent(this.componentFactoryResolver, this.alertBox, deleteNoteErrorAlertConfiguration);
        this.requestDeleteNote(noteID, true);
      }
    });
  }

  public requestEditNote(noteID: string): void {
    this.isEditingNote = true;
    const currentNote = <Note> this.noteList.find(note => note.id === noteID);
    this.noteToEdit.id = currentNote.id;
    this.noteToEdit.message = currentNote.message;
    this.showEditNoteInput(currentNote.message);
  }

  public editNote(event: Event): void {
    sessionStorage.setItem(StorageKeys.EDIT_NOTE_MESSAGE_BACKUP, this.noteToEdit.message);
    this.notesService.editNote(this.noteToEdit.id, this.noteToEdit.message).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((response) => {
      if(response) {
        sessionStorage.removeItem(StorageKeys.EDIT_NOTE_MESSAGE_BACKUP);
        const currentNote = this.noteList.find(note => note.id === this.noteToEdit.id);
        const currentNoteIndex = this.noteList.indexOf(currentNote);
        this.noteList[currentNoteIndex].message = this.noteToEdit.message;
        this.updateNoteLists();
        this.hideEditNoteInput();
        if(this.noteAlert) {
          this.noteAlert.destroy();
        }
        this.noteAlert = generateAlertComponent(this.componentFactoryResolver, this.alertBox, editNoteSuccessAlertConfiguration);
      } else {
        if(this.noteAlert) {
          this.noteAlert.destroy();
        }
        this.noteAlert = generateAlertComponent(this.componentFactoryResolver, this.alertBox, editNoteErrorAlertConfiguration);
      }
    })
  }

  public showNewNoteInput(): void {
    this.isAddingNote = true;
    this.isNewNoteInputVisible = true;
  }

  public hideNewNoteInput(): void {
    this.isAddingNote = false;
    this.isNewNoteInputVisible = false;
  }

  public showEditNoteInput(precompiledValue: string = ''): void {
    this.textareaPrecompiledValue = precompiledValue;
    this.isEditNoteInputVisible = true;
  }

  public hideEditNoteInput(): void {
    this.isEditingNote = false;
    this.isEditNoteInputVisible = false;
    this.textareaPrecompiledValue = '';
    this.noteToEdit = { id: '', message: ''};
  }

  public requestDeleteNote(noteID: string, abortDelete: boolean) {
    const noteNode = document.querySelector('#' + this.NOTE_ID_PREFIX + noteID);
    if(abortDelete) {
      noteNode.classList.remove(this.NOTE_CONFIRM_DELETE_CLASS);
    } else {
      noteNode.classList.add(this.NOTE_CONFIRM_DELETE_CLASS);
    }
  }

  public toggleNotebar(): void {
    this.isOpen = !this.isOpen;
    this.app.updateNotebarVisibility(this.isOpen);
    this.isOpen ?
      document.body.classList.add(this.NOTEBAR_VISIBLE_CLASS) :
      document.body.classList.remove(this.NOTEBAR_VISIBLE_CLASS);
  }

  public closeHelper(): void {
    this.isHelperVisible = false;
    localStorage.setItem(StorageKeys.NOTEBAR_HELPER_VIEWED, 'true');
  }

  public getFormattedCreationDate(creationDate: Date): string {
    return dayjs(creationDate).format('lll');
  }

  private updateNoteLists(): void {
    this.sortedNoteList = this.sortNotes(this.noteList);
    if(this.selectedUnit && this.selectedCellTime) {
      this.filteredNoteList = this.filteredNoteList = this.sortedNoteList.filter(noteGroup => noteGroup.unitCode == this.selectedUnit);
    } else {
      this.filteredNoteList = this.sortedNoteList;
    }
  }

  private sortNotes(noteList: Note[]): NoteGroup[] {
    let sortedNoteList: NoteGroup[] = [];
    const notelistUnits = new Set( noteList.map(note => note.unitCode) );
    notelistUnits.forEach(unit => {
      sortedNoteList.push({
        unitCode: unit,
        notes: noteList.filter(note => note.unitCode === unit)
      });
    });
    return sortedNoteList;
  }

  private getNotes(date: number): void {
    if(this.auction) {
      document.body.classList.add(this.NOTES_LOADER_CLASS);
      this.notesService.getNotes(
        dayjs(date).startOf('day').format('YYYY-MM-DD HH:mm'),
        dayjs(date).endOf('day').format('YYYY-MM-DD HH:mm'),
        this.auction.id
      ).pipe(
        takeUntil(this.onDestroy$)
      ).subscribe(
        (response: Note[]) => {
          document.body.classList.remove(this.NOTES_LOADER_CLASS);
          this.noteList = response;
          this.sortedNoteList = this.sortNotes(this.noteList);
          this.filteredNoteList = this.sortedNoteList;
          this.totalNotesCount = this.noteList.length;
        },
        (error: HttpErrorResponse) => {
          document.body.classList.remove(this.NOTES_LOADER_CLASS);
          if(error.status === HttpStatusCode.NotFound) {
          } else {
            this.noteList = [];
            this.sortedNoteList = [];
            this.filteredNoteList = [];
            this.totalNotesCount = this.noteList.length;
          }
        }
      );
    }
  }

  private getRelativeDay(date: Date | dayjs.Dayjs): string {
    return dayjs(date).calendar(null, {
      sameDay: '[' + TimeSelectorTitles.TODAY + ']',
      lastDay: '[' + TimeSelectorTitles.YESTERDAY + ']',
      nextDay: '[' + TimeSelectorTitles.TOMORROW + ']',
      lastWeek: 'dddd',
      sameElse: '[' + this.date.fromNow() + ']'
    });
  }
}