import { Component, OnDestroy, OnInit } from '@angular/core';
import 'dayjs/locale/it';
import 'dayjs/locale/de';
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Auth } from 'aws-amplify';
import { from, of, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { AppService } from './services/local/app/app.service';
import { redirectToAuthentication } from './utils/functions';
import { Router } from '@angular/router';
import { StorageKeys } from './enums/storage-keys';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private readonly onDestroy$ = new Subject<void>();

  constructor (
    private app: AppService,
    private router: Router,
  ) {}

  ngOnInit() {
    Chart.register(annotationPlugin);
    this.checkAuthentication();
  }

  ngOnDestroy() { this.onDestroy$.next(); }

  private checkAuthentication(): void {
    from(Auth.currentAuthenticatedUser()).pipe(
      takeUntil(this.onDestroy$)
    ).pipe(
      catchError((error) => {
        redirectToAuthentication();
        return of();
      })
    ).subscribe((user) => {
      this.app.updateUserData({
        firstName: user.signInUserSession.idToken.payload.name,
        lastName: user.signInUserSession.idToken.payload.family_name,
        userType: user.signInUserSession.idToken.payload['custom:ad_group']
      });
      const lastVisitedPage = localStorage.getItem(StorageKeys.LAST_VISITED_PAGE);
      if(Boolean(lastVisitedPage)) {
        localStorage.removeItem(StorageKeys.LAST_VISITED_PAGE);
        this.router.navigateByUrl(lastVisitedPage);
      }
      this.app.setLoginState(true);
    });
  }
}
