<app-modal
  [id]="MODAL_ID"
  [attr.id]="MODAL_ID"
  [closeIconID]="config.content.head.closeIconID"
  (onClose)="modalClose($event)"
  [styleClasses]="'modal-config'">
  <ng-container modal-head>
    <div class="modal-head-inner">
      <div
        class="modal-head-title typo-heading-3"
        [innerText]="config.content.head.title | translate">
      </div>
      <div class="modal-config-head-select" *ngIf="isViewReady">
        <app-input-select
          [list]="selectList"
          [initialValue]="selectListInitialValue"
          [maxHeight]="230"
          (onSelect)="selectConfig($event)">
        </app-input-select>
      </div>
    </div>
  </ng-container>
  <ng-container modal-body>
    <div class="modal-body-inner modal-config-body-inner" *ngIf="isViewReady">
      <app-auction-config *ngIf="selectedConfig === SELECT_LIST_GENERAL_CONFIGS_ITEM"></app-auction-config>
      <app-unit-config *ngIf="selectedConfig !== SELECT_LIST_GENERAL_CONFIGS_ITEM" [unitCode]="selectedConfig.value" [auctionID]="config?.content?.head?.auctionID"></app-unit-config>
    </div>
    <div class="modal-config-loader" *ngIf="!isViewReady"></div>
  </ng-container>
</app-modal>