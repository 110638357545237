import { Component, Input, OnInit } from '@angular/core';
import * as dayjs from 'dayjs';
import { UnitTableBde, UnitTableMIB } from 'src/app/interfaces/table-report';
import { MIBList } from 'src/app/interfaces/mib-list';
import { UNIT_ID_LINK_PREFIX } from 'src/app/utils/consts';

@Component({
  selector: 'app-table-mib',
  templateUrl: './table-mib.component.html',
})
export class TableMIBComponent implements OnInit {
  public readonly UNIT_ID_LINK_PREFIX = UNIT_ID_LINK_PREFIX;
  public dayjs = dayjs;
  public displayedDataset: MIBList[];
  
  @Input() dataset: MIBList[];
  @Input() singleDataset: UnitTableMIB[];
  @Input() isSingleUnit: boolean = false;
  @Input() routedLinks: boolean = true;
    
  constructor() {}

  ngOnInit() {}
}