<div class="accordion" [ngClass]="[visibility ? 'open' : '', style, groupID ? ('accordion-group accordion-group-' + groupID) : '']">
  <div class="accordion-head" [ngClass]="[style===AccordionStyle.BIG ? 'accordion-big-padding' : '']" (click)="toggleVisibility($event)">
    <div class="accordion-head-content">
      <ng-content select="[accordion-head]"></ng-content>
    </div>
    <i *ngIf="style===AccordionStyle.BIG" class="icon-chevron-down icon-coral icon-medium icon-box icon-box-small icon-anim accordion-chevron"></i>
    <i *ngIf="style===AccordionStyle.SMALL" class="icon-chevron-down icon-coral icon-medium icon-anim accordion-chevron"></i>
  </div>

  <div class="accordion-body">
    <ng-content select="[accordion-body]"></ng-content>
  </div>
</div>