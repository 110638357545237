<div class="chart-doughnut">
  <div
    class="chart-doughnut-canvas-wrapper"
    [style]="'width:'+canvasSize+'px'">
    <div class="chart-doughnut-track"></div>
    <canvas
      [attr.aria-label]="'doughnut-chart'"
      role="img"
      [attr.width]="canvasSize"
      [attr.height]="canvasSize"
      #chartCanvasNode>
    </canvas>
    <div class="chart-doughnut-info">
      <div class="chart-doughnut-info-percentage">
        <span class="chart-doughnut-info-percentage-sign typo-body-1">&#177;</span>
        <span class="chart-doughnut-info-percentage-value typo-heading-1" [innerText]="percentageValue"></span>
        <span class="chart-doughnut-info-percentage-symbol typo-body-1 typo-sky">&#37;</span>
      </div>
      <div class="chart-doughnut-info-duration typo-body-2">
        <div class="chart-doughnut-info-duration-since" [innerText]="'CHART-DOUGHNUT:duration-since' | translate"></div>
        <div class="chart-doughnut-info-duration-value" [innerText]="minutesValue"></div>
        <div
          class="chart-doughnut-info-duration-minutes"
          [innerText]="(minutesValue === 1 ? 'CHART-DOUGHNUT:duration-minute' : 'CHART-DOUGHNUT:duration-minutes')  | translate">
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="chart-doughnut-legend">
    <div class="chart-doughnut-legend-item">
      <div class="chart-doughnut-legend-item-sample nominal"></div>
      <div class="chart-doughnut-legend-item-label" [innerText]="'CHART-DOUGHNUT:legend-nominal' | translate"></div>
    </div>
    <div class="chart-doughnut-legend-item">
      <div class="chart-doughnut-legend-item-sample no-data"></div>
      <div class="chart-doughnut-legend-item-label" [innerText]="'CHART-DOUGHNUT:legend-no_data' | translate"></div>
    </div>
    <div class="chart-doughnut-legend-item">
      <div class="chart-doughnut-legend-item-sample warning"></div>
      <div class="chart-doughnut-legend-item-label" [innerText]="'CHART-DOUGHNUT:legend-warning' | translate"></div>
    </div>
    <div class="chart-doughnut-legend-item">
      <div class="chart-doughnut-legend-item-sample critical"></div>
      <div class="chart-doughnut-legend-item-label" [innerText]="'CHART-DOUGHNUT:legend-critical' | translate"></div>
    </div>
  </div> -->
</div>