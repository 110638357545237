import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Chart, ChartConfiguration } from 'chart.js';
import { proportionalRange } from 'src/app/utils/functions';

@Component({
  selector: 'app-chart-doughnut',
  templateUrl: './chart-doughnut.component.html'
})
export class ChartDoughnutComponent implements OnInit, AfterViewInit, OnChanges {
  
  private readonly TOTAL_VALUE: number = 100;
  private readonly BAR_THICKNESS: number = 40;
  private readonly TRACK_COLOR: string = '#eceeff';
  private readonly BAR_COLOR_NOMINAL: string = '#00bbde';
  private readonly BAR_COLOR_NODATA: string = '#838383';
  private readonly BAR_COLOR_WARNING: string = '#ff5a10';
  private readonly BAR_COLOR_CRITICAL: string = '#e30039';
  
  private chart: Chart<'doughnut'>;
  private barColor: string = '';
  
  public chartConfig: ChartConfiguration<'doughnut'> = {
    type: 'doughnut',
    data: { datasets: [] }
  };

  @Input() canvasSize: number = 200;
  @Input() percentageValue: number = 0;
  @Input() minutesValue: number = 0;

  @ViewChild('chartCanvasNode') chartCanvasNode: ElementRef;

  constructor() {}

  ngOnInit(): void {
    this.barColor = this.BAR_COLOR_NOMINAL;
  }

  ngAfterViewInit(): void {
    this.generateChartData();
    this.generateChartOptions();
    this.generateChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.chart && (changes?.percentageValue || changes?.minutesValue)) {
      this.generateChartData();
      this.chart.update('none');
    }
  }

  private generateChartData(): void {
    this.chartConfig.data.datasets = [
      {
        data: [this.percentageValue, (this.percentageValue - this.TOTAL_VALUE)],
        backgroundColor: [this.barColor, 'transparent'],
        borderWidth: 1,
        hoverBackgroundColor: [this.barColor, 'transparent'],
      }
    ];
  }

  private generateChartOptions(): void {
    this.chartConfig.options = {
      //@ts-ignore
      borderRadius: 999,
      borderWidth: 0,
      borderColor: 'transparent',
      cutout: proportionalRange(0, this.canvasSize, 0, 100, (this.canvasSize - this.BAR_THICKNESS)) + '%',
      plugins: {
        tooltip: {
          enabled: false
        }
      },
      layout: {
        padding: -20,
      },
      responsive: false
    };
  }

  private generateChart(): void {
    this.chart = new Chart(
      (this.chartCanvasNode.nativeElement as HTMLCanvasElement),
      this.chartConfig
    );
  }
}
