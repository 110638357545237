<div class="table table-mib" role="table" [ngClass]="[isSingleUnit ? 'column-spread' : '']">
  <div class="table-head table-mib-head" role="rowgroup">
    <div class="table-head-row table-mib-head-row" role="row">
      <div role="cell" class="table-head-cell table-mib-head-cell cell-name typo-body-2 typo-bold"
        [innerText]="'TABLE-MIB-HEAD:unit' | translate" *ngIf="!isSingleUnit">
      </div>
      <div class="table-head-cell table-mib-head-cell cell-data">
        <span role="cell" class="table-mib-head-subcell subcell-start-timestamp typo-body-2 typo-bold"
          [innerText]="'TABLE-MIB-HEAD:start-timestamp' | translate">
        </span>
        <span role="cell" class="table-mib-head-subcell subcell-end-timestamp typo-body-2 typo-bold"
          [innerText]="'TABLE-MIB-HEAD:end-timestamp' | translate">
        </span>
      </div>
    </div>
  </div>

  <div class="table-body table-mib-body" role="rowgroup">

    <ng-container *ngIf="!isSingleUnit; then multipleUnits else singleUnit"></ng-container>

    <ng-template #multipleUnits>
      <ng-container *ngIf="!!dataset; else noData">
        <div class="table-body-row table-mib-body-row" *ngFor="let unit of dataset; index as unitIndex" role="row">
          <div class="table-body-cell table-mib-body-cell cell-name tooltip-top tooltip-large"
            [attr.data-tooltip]="unit.unitCode" role="cell">
            <a *ngIf="routedLinks" [routerLink]="['/auction']"
              [queryParams]="{id: unit.auctionID, scrollTo: UNIT_ID_LINK_PREFIX + unitIndex}"
              [innerText]="unit.unitCode | translate"
              class="typo-textlink typo-body-2 typo-bold typo-uppercase text-trim-1">
            </a>
            <a *ngIf="!routedLinks" [routerLink]="" [queryParams]="{scrollTo: UNIT_ID_LINK_PREFIX + unitIndex}"
              [queryParamsHandling]="'merge'" [innerText]="unit.unitCode | translate"
              [attr.data-scrollto]="UNIT_ID_LINK_PREFIX + unitIndex"
              class="typo-textlink typo-body-2 typo-bold typo-uppercase text-trim-1">
            </a>
          </div>
          <div class="table-body-cell table-mib-body-cell cell-data">
            <div *ngFor="let mib of unit.mib" class="table-mib-body-cell-group">
              <div role="cell" class="table-mib-body-subcell subcell-start-timestamp table-mib-body-cell">
                <span class="typo-body-2 tooltip-top tooltip-auto"
                  [attr.data-tooltip]="dayjs(mib.startDate).format('DD/MM/YYYY HH:mm:ss')"
                  [innerText]="dayjs(mib.startDate).format('DD/MM/YYYY HH:mm')">
                </span>
              </div>
              <div role="cell" class="table-mib-body-subcell subcell-end-timestamp table-mib-body-cell">
                <span class="typo-body-2 tooltip-top tooltip-auto"
                  [attr.data-tooltip]="dayjs(mib.endDate).format('DD/MM/YYYY HH:mm:ss')"
                  [innerText]="dayjs(mib.endDate).format('DD/MM/YYYY HH:mm')">
                </span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-template>

    <ng-template #singleUnit>
      <ng-container *ngIf="!!singleDataset; else noData">
        <div class="table-body-cell table-mib-body-cell cell-data">
          <div *ngFor="let singleDatasetMIB of singleDataset" class="table-mib-body-cell-group">
            <div role="cell" class="table-mib-body-subcell subcell-start-timestamp table-mib-body-cell">
              <span class="typo-body-2 tooltip-left tooltip-auto"
                [attr.data-tooltip]="dayjs(singleDatasetMIB.startDate).format('DD/MM/YYYY HH:mm:ss')"
                [innerText]="dayjs(singleDatasetMIB.startDate).format('HH:mm')">
              </span>
            </div>
            <div role="cell" class="table-mib-body-subcell subcell-end-timestamp table-mib-body-cell">
              <span class="typo-body-2 tooltip-left tooltip-auto"
                [attr.data-tooltip]="dayjs(singleDatasetMIB.endDate).format('DD/MM/YYYY HH:mm:ss')"
                [innerText]="dayjs(singleDatasetMIB.endDate).format('HH:mm')">
              </span>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </div>
</div>

<ng-template #noData>
  <div class="table-body-row table-mib-body-row" role="row">
    <span class="table-body-cell table-mib-body-cell typo-body-2" [innerText]="'TABLE-MIB:no-data' | translate"
      role="cell">
    </span>
  </div>
</ng-template>