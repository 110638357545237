import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalID } from 'src/app/enums/modal-id.enum';
import { InputSelectItem } from 'src/app/interfaces/input-select-item';
import { ModalConfiguration } from 'src/app/interfaces/modal-config';
import { AuctionService } from 'src/app/services/http/auction/auction.service';
import { ModalService } from 'src/app/services/local/modal/modal.service';

@Component({
  selector: 'app-modal-config',
  templateUrl: './modal-config.component.html'
})
export class ModalConfigComponent implements OnInit, AfterViewInit, OnDestroy {

  private readonly onDestroy$ = new Subject<void>();

  public readonly MODAL_ID = ModalID.CONFIG;
  public readonly SELECT_LIST_GENERAL_CONFIGS_ITEM_VALUE: string = 'general';
  public readonly SELECT_LIST_GENERAL_CONFIGS_ITEM: InputSelectItem = {
    label: 'MODAL-CONFIG:select-general',
    value: this.SELECT_LIST_GENERAL_CONFIGS_ITEM_VALUE
  }

  public selectList: InputSelectItem[] = [];
  public selectListInitialValue: InputSelectItem;
  public selectedConfig: InputSelectItem;
  public isViewReady: boolean = false;

  @Input() config: ModalConfiguration;

  @Output() onClose: EventEmitter<Function> = new EventEmitter<Function>();

  constructor(
    private modalService: ModalService,
    private auctionService: AuctionService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.generateSelectList();
  }

  ngAfterViewInit() {}

  ngOnDestroy(): void {
    this.modalService.closeModal(this.config.id);
    this.onDestroy$.next();
  }

  public modalClose(event) {
    if(event) {
      this.onClose.emit(event)
    } else {
      this.onClose.emit(null);
    }
  }

  public selectConfig(selectedItem: InputSelectItem): void {
    if(this.selectedConfig !== selectedItem) {
      this.selectedConfig = selectedItem;
    }
  }

  private generateSelectList(): void {
    this.auctionService.getAllRecaps().pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(unitList => {
      this.selectList = [this.SELECT_LIST_GENERAL_CONFIGS_ITEM];
      unitList.forEach(unit => {
        this.selectList = [
          ...this.selectList,
          {
            label: `${unit.auctionName} > ${this.translateService.instant(unit.unitCode)}`,
            value: unit.unitCode
          }
        ];
      });

      const preselectedConfigView = this.config?.content?.head?.adminConfigSelectInitialValue;
      this.selectListInitialValue =
        this.selectList.find(listItem => listItem.value === preselectedConfigView) ||
        this.SELECT_LIST_GENERAL_CONFIGS_ITEM;
      this.selectedConfig = this.selectListInitialValue;

      this.isViewReady = true;
    });
  }
}
