import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Language } from 'src/app/enums/language.enum';
import { StorageKeys } from 'src/app/enums/storage-keys';
import { AuctionService } from 'src/app/services/http/auction/auction.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { RoutesList } from 'src/app/enums/routes-list.enum';
import { from, Subject } from 'rxjs';
import { Auction } from 'src/app/interfaces/auction';
import * as dayjs from 'dayjs';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import { ClockService } from 'src/app/services/local/clock/clock.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ALL_AUCTIONS_ID, BREAKPOINT_TABLET_MAX_WIDTH, MODAL_CONFIG_HEADER } from 'src/app/utils/consts';
import { AppService } from 'src/app/services/local/app/app.service';
import { Auth } from 'aws-amplify';
import { Theme } from 'src/app/enums/theme.enum';
import { ModalService } from 'src/app/services/local/modal/modal.service';
import { ModalConfiguration } from 'src/app/interfaces/modal-config';
import { ModalID } from 'src/app/enums/modal-id.enum';
import { ModalConfigComponent } from '../modal-config/modal-config.component';
import { isUserAdmin } from 'src/app/utils/functions';
import { ConfigService } from 'src/app/services/http/config/config.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {

  private readonly onDestroy$ = new Subject<void>();

  private currentModalConfig: ModalConfiguration;

  public readonly Language = Language;
  public readonly RoutesList = RoutesList;
  public readonly ALL_AUCTIONS_ID = ALL_AUCTIONS_ID;
  
  public clock: string;
  public auctionList: Auction[];
  public currentLanguage: string;
  public username: string;
  public isUserAdmin: boolean = false;
  public activeMenu: string;
  public isUserMenuOpen: boolean;
  public isMobileMenuVisible: boolean = false;
  public isTablet: boolean = false;
  public isLoggedIn: boolean = false;
  public globalExternalLink: string = '';
  public isThemeHighContrast: boolean = false;
  public isThemeDark: boolean = false;
  public isAudioEnabled: boolean = false;

  constructor(
    private translate: TranslateService,
    private auctionService: AuctionService,
    private router: Router,
    private route: ActivatedRoute,
    private clockService: ClockService,
    private breakpointObserver: BreakpointObserver,
    private app: AppService,
    private modalService: ModalService,
    private configService: ConfigService
  ) {
    this.clockService.time.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((now) => {
      this.clock = dayjs(now).format('LLLL');
    });
  }

  ngOnInit() {
    this.onResize();
    this.app.getLoginState().pipe(
      takeUntil(this.onDestroy$)
    ).subscribe( (isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
    });
    const storedLanguage = localStorage.getItem(StorageKeys.LANGUAGE);
    this.switchLanguage(storedLanguage ? storedLanguage : this.translate.defaultLang);
    dayjs.locale(this.currentLanguage);
    dayjs.extend(localizedFormat);
    this.setActiveMenuFromURL(this.router.url);
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this.onDestroy$)
    ).subscribe( (event: NavigationEnd) => {
      this.setActiveMenuFromURL(event.url);
    });
    this.getAuctionsList();
    this.app.getUserData().pipe(
      takeUntil(this.onDestroy$),
      filter((userData) => Boolean(userData?.firstName) && Boolean(userData?.lastName))
    ).subscribe(userData => {
      this.username = `${userData.firstName} ${userData.lastName}`;
      this.isUserAdmin = isUserAdmin(userData);
    });
    this.getGlobalExternalLink();

    this.isThemeHighContrast = document.body.classList.contains(Theme.HIGH_CONTRAST_CLASS);
    this.isThemeDark = document.body.classList.contains(Theme.DARK_CLASS);
    this.isAudioEnabled = (localStorage.getItem(StorageKeys.ALARMS_TOGGLE) === 'true');
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  public switchLanguage(languageID: string): void {
    this.currentLanguage = languageID;
    this.translate.use(languageID);
    dayjs.locale(this.currentLanguage);
    document.querySelector('html').setAttribute('lang', languageID);
    localStorage.setItem(StorageKeys.LANGUAGE, languageID);
    this.toggleBurger(false);
  }

  public getUserInitials(): string {
    return this.username ?
      this.username.split(' ')[0][0] + this.username.split(' ')[1][0] :
      ''; 
  }

  public toggleBurger(forceState: boolean = undefined): void {
    if(this.breakpointObserver.isMatched(BREAKPOINT_TABLET_MAX_WIDTH)) {
      this.isMobileMenuVisible = typeof forceState !== 'undefined' ?
        forceState : !this.isMobileMenuVisible;
    }
  }

  public toggleUserMenu(forceState: boolean = undefined) {
    this.isUserMenuOpen = typeof forceState !== 'undefined' ?
      forceState : !this.isUserMenuOpen;
  }

  public logout() {
    from(Auth.signOut()).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((data) => {});
  }

  public toggleHighContrast(): void {
    document.body.classList.toggle(Theme.HIGH_CONTRAST_CLASS);
    localStorage.setItem(
      StorageKeys.IS_HIGH_CONTRAST,
      document.body.classList.contains(Theme.HIGH_CONTRAST_CLASS).toString()
    );
    this.isThemeHighContrast = !this.isThemeHighContrast;
  }

  public toggleDarkTheme(): void {
    document.body.classList.toggle(Theme.DARK_CLASS);
    localStorage.setItem(
      StorageKeys.IS_THEME_DARK,
      document.body.classList.contains(Theme.DARK_CLASS).toString()
    );
    this.isThemeDark = !this.isThemeDark;
  }

  public toggleAudio(): void {
    this.isAudioEnabled = !this.isAudioEnabled;
    localStorage.setItem(StorageKeys.ALARMS_TOGGLE, this.isAudioEnabled.toString());
  }

  public openConfigModal(): void {
    this.currentModalConfig = {
      id: ModalID.CONFIG,
      componentType: ModalConfigComponent,
      content: {
        head: MODAL_CONFIG_HEADER
      }
    }
    this.modalService.openModal(this.currentModalConfig);
  }

  private getAuctionsList(): void {
    this.auctionService.getAuctionsList().pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(response => {
      this.auctionList = [];
      response.forEach(auction => {
        this.auctionList.push({
          id: auction.auctionID,
          name: auction.auctionName,
        });
      });
    });
  }

  private setActiveMenuFromURL(url: string): void {
    switch(true) {
      case url.includes(RoutesList.DASHBOARD):
        this.activeMenu = RoutesList.DASHBOARD;
        break;
      case url.includes(RoutesList.AUCTION):
        this.route.queryParams.pipe(
          takeUntil(this.onDestroy$)
        ).subscribe(params => {
          if( params && params.id ) {
            this.activeMenu = RoutesList.AUCTION + params.id.toString();
          } else {
            this.activeMenu = RoutesList.AUCTION
          }
        });
        break;
    }
  }

  private onResize(): void {
    this.isTablet = this.breakpointObserver.isMatched(BREAKPOINT_TABLET_MAX_WIDTH);
    window.addEventListener('resize', () => {
      this.isTablet = this.breakpointObserver.isMatched(BREAKPOINT_TABLET_MAX_WIDTH);
    });
  }

  private getGlobalExternalLink(): void {
    this.app.getExternalLinks().pipe(
      takeUntil(this.onDestroy$),
      filter(linksList => Boolean(linksList))
    ).subscribe(linksList => {
      this.globalExternalLink = linksList?.global || '';
    });
  }

  private __panicRefreshCounter: number = 0;
  private __panicRefreshTimeout: any;
  public __panicRefresh(): void {
    this.__panicRefreshCounter++;
    if(this.__panicRefreshCounter>=5) {
      clearTimeout(this.__panicRefreshTimeout);
      this.__panicRefreshCounter=0;
      Object.keys({...localStorage}).forEach(storageKey=>{
        if(!storageKey.startsWith('agp')){localStorage.removeItem(storageKey);}
      });
      Object.keys({...sessionStorage}).forEach(storageKey=>{
        if(!storageKey.startsWith('agp')){sessionStorage.removeItem(storageKey);}
      });
      location.reload();
    } else {
      clearTimeout(this.__panicRefreshTimeout);
      this.__panicRefreshTimeout=setTimeout(()=>{this.__panicRefreshCounter=0;},2000);
    }
  }
}
