import { Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router, ActivatedRoute, RouterEvent, NavigationEnd } from '@angular/router';
import { combineLatest, of, Subject } from 'rxjs';
import { catchError, debounceTime, filter, first, takeUntil } from 'rxjs/operators';
import { ModalConfiguration } from 'src/app/interfaces/modal-config';
import { AppService, StoredLinksList, StoredUnitsOptions } from 'src/app/services/local/app/app.service';
import { ModalService } from 'src/app/services/local/modal/modal.service';
import { generateModalComponent } from 'src/app/utils/functions';
import { NULL_UNIT_SELECTION } from 'src/app/interfaces/table-report';
import { StorageKeys } from 'src/app/enums/storage-keys';
import { Theme } from 'src/app/enums/theme.enum';
import { ConfigService } from 'src/app/services/http/config/config.service';
import { LinksResponse, UnitsOptionsResponse } from 'src/app/interfaces/config-service-response';
import { LINK_CONFIG_KEY } from 'src/app/utils/consts';

@Component({
  selector: 'app-basic-page',
  templateUrl: './basic-page.component.html'
})
export class BasicPageComponent implements OnInit, OnDestroy {

  private readonly onDestroy$ = new Subject<void>();
  private readonly MODALS_CONTAINER_NODE_SELECTOR = '.modal-wrapper';
  private readonly MODAL_CONTAINER_VISIBLE_CLASS: string = 'modal-open';
  private readonly TABLE_REPORT_NODE_SELECTOR = '.table-report';
  private readonly NOTEBAR_NODE_SELECTOR = '.notebar';
  private readonly NOTEBAR_BUTTON_NODE_SELECTOR = '.notebar-tablet-open-button';
  private readonly DATA_SELECTOR_NODE_SELECTOR = '.data-selector';
  private readonly HEADER_NODE_SELECTOR = '.header';
  private readonly TABLE_REPORT_DESELECT_BLACKLIST = [
    this.TABLE_REPORT_NODE_SELECTOR,
    this.NOTEBAR_NODE_SELECTOR,
    this.DATA_SELECTOR_NODE_SELECTOR,
    this.HEADER_NODE_SELECTOR,
    this.NOTEBAR_BUTTON_NODE_SELECTOR
  ];
  private readonly ROUTE_CLASS_PREFIX = 'route-';

  private activeModals = [];

  public routedComponent: any;
  public currentModalConfig: ModalConfiguration;
  
  @ViewChild('modalContainer', {read: ViewContainerRef, static: false}) modalContainer: ViewContainerRef;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private app: AppService,
    private configService: ConfigService
  ) { }

  ngOnInit(): void {
    this.routerEvents();
    this.addRouteClass();
    this.modalManager();
    this.unitCellSelection();
    this.setWebsiteTheme();
    this.retrieveLinksAndUnitFlags();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  private routerEvents(): void {
    this.router.events.pipe(
      debounceTime(0),
      filter((event) => {
        return (event as RouterEvent) instanceof NavigationEnd;
      }),
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.addRouteClass();
    });
  }

  private modalManager(): void {
    this.modalService.onActiveModalsChange.pipe(
      takeUntil(this.onDestroy$),
      filter(activeModalsConfig => activeModalsConfig != null)
    ).subscribe(activeModalsList => {
      const newActiveModals = activeModalsList.map(modalConfig => modalConfig.id);
      const modalsContainerNode = document.querySelector(this.MODALS_CONTAINER_NODE_SELECTOR);
      this.activeModals = activeModalsList.map(modalConfig => modalConfig.id);
      
      if(this.activeModals.length > 0) {
        generateModalComponent(this.componentFactoryResolver, this.modalContainer, activeModalsList[activeModalsList.length-1]);
        modalsContainerNode.classList.add(this.MODAL_CONTAINER_VISIBLE_CLASS);
        document.body.classList.add(this.MODAL_CONTAINER_VISIBLE_CLASS);
      } else {
        modalsContainerNode.classList.remove(this.MODAL_CONTAINER_VISIBLE_CLASS);
        document.body.classList.remove(this.MODAL_CONTAINER_VISIBLE_CLASS);
      }
    });
  }

  private unitCellSelection(): void {
    document.addEventListener('click', (event: Event) => {
      const eventTarget = event.target as HTMLElement;
      let isClickInsideTable: boolean = false;
      this.TABLE_REPORT_DESELECT_BLACKLIST.forEach(blacklistedElement => {
        const elements = document.querySelectorAll(blacklistedElement) as NodeListOf<HTMLElement>;
        elements.forEach(element => {
          if(element.contains(eventTarget) && isClickInsideTable === false) {
            isClickInsideTable = true;
          }
        });
      });
      if(!isClickInsideTable) {
        this.app.setSelectedUnitCell(NULL_UNIT_SELECTION);
      }
    });
    document.addEventListener('keydown', (event: KeyboardEvent) => {
      if(event.code == 'Escape') {
        this.app.setSelectedUnitCell(NULL_UNIT_SELECTION);
      }
    });
  }

  private addRouteClass(): void {
    const bodyClassList = document.querySelector('body').classList;
    const currentRoute = this.route.snapshot.children[0].routeConfig.path;
    let classList = [];
    bodyClassList.forEach(cssClass => classList.push(cssClass));
    const previousRouteClass: string = classList.find((cssClass: string) => cssClass.includes(this.ROUTE_CLASS_PREFIX));
    if(previousRouteClass) {
      bodyClassList.replace(previousRouteClass, this.ROUTE_CLASS_PREFIX + currentRoute);
    } else {
      bodyClassList.add(this.ROUTE_CLASS_PREFIX + currentRoute);
    }
  }

  private setWebsiteTheme(): void {
    const isHighContrast = localStorage.getItem(StorageKeys.IS_HIGH_CONTRAST) === 'true';
    const isThemeDark = localStorage.getItem(StorageKeys.IS_THEME_DARK) === 'true';
    if(isHighContrast) {
      document.body.classList.add(Theme.HIGH_CONTRAST_CLASS);
    } else if(isThemeDark) {
      document.body.classList.add(Theme.DARK_CLASS);
    }
  }

  private retrieveLinksAndUnitFlags(): void {
    const getAllLinks$ = this.configService.actions.links.getAll().pipe(
      first(),
      catchError(_ => of([]))
    );
    const getUnitOptions$ = this.configService.actions.visibilityFlags.getAll().pipe(
      first(),
      catchError(_ => of([]))
    );

    combineLatest([
      getAllLinks$,
      getUnitOptions$
    ]).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(([linksList, unitsOptions]) => {
      this.saveLinks(linksList);
      this.saveUnitsOptions(unitsOptions);
    });
  }

  private saveLinks(linksList: LinksResponse[]): void {
    const sortedLinks: StoredLinksList = {}
    linksList.forEach((listItem: LinksResponse) => {
      switch(true) {
        case listItem.auctionID === null && listItem.unitCode === null: //global link
          sortedLinks.global = listItem.links.find(link => link.key === LINK_CONFIG_KEY)?.value;
        break;

        case Boolean(listItem.auctionID) && listItem.unitCode === null: //auction link
          sortedLinks[listItem.auctionID] = listItem.links.find(link => link.key === LINK_CONFIG_KEY)?.value;
        break;

        case Boolean(listItem.unitCode): //unit links
          sortedLinks[listItem.unitCode] = listItem.links.map(fromResponse => {
            return {
              label: fromResponse.key,
              url: fromResponse.value
            };
          });
        break;
      }
    });
    this.app.storeExternalLinks(sortedLinks);
  }

  private saveUnitsOptions(unitsOptions: UnitsOptionsResponse[]): void {
    const storedUnitOptions: StoredUnitsOptions = {}
    unitsOptions.forEach(unit => {
      storedUnitOptions[unit.unitCode] = {
        balanceable: unit.balanceable,
        showMib: unit.showOptions.showMib,
        showPowerMax: unit.showOptions.showPowerMax,
        showPowerMin: unit.showOptions.showPowerMin,
        showPVM: unit.showOptions.showPvm,
        thresholdValue: (unit.unbalanced.unbalancedAlarmThreshold * 100),
        thresholdDuration: unit.unbalanced.unbalancedAlarmMinutes
      }
    });
    this.app.storeUnitsOptions(storedUnitOptions);
  }
}
