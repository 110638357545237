<div
  class="input-select"
  [ngClass]="{'open': showList}">
  <div class="input-select-current" (click)="toggleListVisibility()">
    <div class="input-select-current-value" [innerText]="current.label | translate"></div>
    <!-- <div class="input-select-placeholder" *ngIf="!current.label" [innerText]="placeholder | translate"></div> -->
    <i class="icon-chevron-down icon-dark-gray icon-medium input-select-chevron"></i>
  </div>
  <div class="input-select-list" [ngStyle]="{'max-height': maxHeight ? maxHeight+'px' : 'initial'}">
    <div class="input-select-list-item typo-body-2"
      *ngFor="let item of list"
      (click)="selectValue(item.value)"
      [innerText]="item.label | translate">
    </div>
  </div>
</div>