import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  SimpleChanges,
  ChangeDetectorRef,
  AfterViewInit,
  ComponentRef,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';
import { UnitCode } from 'src/app/enums/unit-codes.enum';
import { Link } from 'src/app/interfaces/link';
import { ConfigService } from 'src/app/services/http/config/config.service';
import { AppService, UnitOptions } from 'src/app/services/local/app/app.service';
import { AlertComponent } from '../alert/alert.component';
import {
  editUnitFlagSuccessAlertConfiguration,
  editUnitFlagErrorAlertConfiguration,
  addUnitLinkSuccessAlertConfiguration,
  addUnitLinkErrorAlertConfiguration,
  editUnitLinkSuccessAlertConfiguration,
  editUnitLinkErrorAlertConfiguration,
  deleteUnitLinkSuccessAlertConfiguration,
  deleteUnitLinkErrorAlertConfiguration,
  editUnitThresholdSuccessAlertConfiguration,
  editUnitThresholdErrorAlertConfiguration,
  URL_WITH_PROTOCOL_PATTERN
} from 'src/app/utils/consts';
import { generateAlertComponent } from 'src/app/utils/functions';

@Component({
  selector: 'app-unit-config',
  templateUrl: './unit-config.component.html'
})
export class UnitConfigComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() auctionName: string;
  @Input() unitCode: UnitCode;
  @Input() auctionID: number;

  public linksList: Link[] = [];
  public newLinkForm: UntypedFormGroup = new UntypedFormGroup({});
  public editLinkForm: UntypedFormGroup = new UntypedFormGroup({});
  public unitOptionsForm: UntypedFormGroup = new UntypedFormGroup({});

  private readonly onDestroy$ = new Subject<void>();
  private readonly URL_VALIDATORS = [
    Validators.required,
    Validators.pattern(URL_WITH_PROTOCOL_PATTERN)
  ];

  private editingList: string[] = [];
  private currentUnitOptions: UnitOptions;
  private feedbackAlert: ComponentRef<AlertComponent>;

  @ViewChild('alertBox', {read: ViewContainerRef, static: false}) alertBox: ViewContainerRef;
  
  constructor(
    private configService: ConfigService,
    private app: AppService,
    private changeDetectorReference: ChangeDetectorRef,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {}

  ngOnInit() {
    this.getUnitsOptions();
    this.getExternalLinks();

    this.initNewLinkForm();
    this.initEditLinkForm();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes?.unitCode?.previousValue && changes?.unitCode?.currentValue) {
      this.getExternalLinks();
      this.getUnitsOptions();
    }
  }

  ngAfterViewInit() {
    this.changeDetectorReference.detectChanges();
  }

  public switchVisibilityOption(optionController: keyof UnitOptions): void {
    this.configService.actions.visibilityFlags[optionController].set(this.unitCode).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      (response) => {
        this.feedbackAlert ? this.feedbackAlert.destroy() : null;
        this.feedbackAlert = generateAlertComponent(this.componentFactoryResolver, this.alertBox, editUnitFlagSuccessAlertConfiguration);
      },
      (error) => {
        this.unitOptionsForm.controls[optionController].setValue(
          !(this.unitOptionsForm.controls[optionController].value)
        );
        this.unitOptionsForm.controls[optionController].updateValueAndValidity();

        this.feedbackAlert ? this.feedbackAlert.destroy() : null;
        this.feedbackAlert = generateAlertComponent(this.componentFactoryResolver, this.alertBox, editUnitFlagErrorAlertConfiguration);
      }
    );
  }

  public updateTresholdPercentage(): void {
    const thresholdValue = this.unitOptionsForm.controls.thresholdValue?.value;
    this.configService.actions.threshold.value.set(this.unitCode, thresholdValue).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      (response) => {
        this.feedbackAlert ? this.feedbackAlert.destroy() : null;
        this.feedbackAlert = generateAlertComponent(this.componentFactoryResolver, this.alertBox, editUnitThresholdSuccessAlertConfiguration);
      },
      (error) => {
        this.unitOptionsForm.controls.thresholdValue.setValue(this.currentUnitOptions.thresholdValue);
        this.unitOptionsForm.controls.thresholdValue.updateValueAndValidity();
        
        this.feedbackAlert ? this.feedbackAlert.destroy() : null;
        this.feedbackAlert = generateAlertComponent(this.componentFactoryResolver, this.alertBox, editUnitThresholdErrorAlertConfiguration);
      }
    );
  }

  public updateTresholdDuration(): void {
    const thresholdDuration = this.unitOptionsForm.controls.thresholdDuration?.value;
    this.configService.actions.threshold.duration.set(this.unitCode, thresholdDuration).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      (response) => {
        this.feedbackAlert ? this.feedbackAlert.destroy() : null;
        this.feedbackAlert = generateAlertComponent(this.componentFactoryResolver, this.alertBox, editUnitThresholdSuccessAlertConfiguration);
      },
      (error) => {
        this.unitOptionsForm.controls.thresholdDuration.setValue(this.currentUnitOptions.thresholdDuration);
        this.unitOptionsForm.controls.thresholdDuration.updateValueAndValidity();
        
        this.feedbackAlert ? this.feedbackAlert.destroy() : null;
        this.feedbackAlert = generateAlertComponent(this.componentFactoryResolver, this.alertBox, editUnitThresholdErrorAlertConfiguration);
      }
    );
  }

  public isLinkAlreadyExisting(linkKey: string): boolean {
    return Boolean(this.linksList?.find(link => link.label === linkKey));
  }

  public requestNewLink(): void {
    const newLink = {
      label: this.newLinkForm.controls.label.value,
      url: this.newLinkForm.controls.url.value
    };
    this.configService.actions.links.unit.set(
      this.unitCode, 
      newLink
    ).pipe(
      first()
    ).subscribe(
      (response) => {
        this.newLinkForm.controls.label.setValue('');
        this.newLinkForm.controls.url.setValue('');
        this.newLinkForm.updateValueAndValidity();

        this.feedbackAlert ? this.feedbackAlert.destroy() : null;
        this.feedbackAlert = generateAlertComponent(this.componentFactoryResolver, this.alertBox, addUnitLinkSuccessAlertConfiguration);
      },
      (error) => {
        this.feedbackAlert ? this.feedbackAlert.destroy() : null;
        this.feedbackAlert = generateAlertComponent(this.componentFactoryResolver, this.alertBox, addUnitLinkErrorAlertConfiguration);
      }
    );
  }

  public showEditLink(link: Link): void {
    this.editingList.push(link.label);
    this.editLinkForm.controls[link.label] = new UntypedFormControl(link.url, this.URL_VALIDATORS);
  }

  private hideEditLink(requestedLink: Link): void {
    this.editingList.splice(
      this.editingList.indexOf(
        this.editingList.find(link => link === requestedLink.label)
      ),
      1
    );
  }
  
  public confirmEditLink(requestedLink: Link): void {
    const editedValue = this.editLinkForm.controls[requestedLink.label].value;
    const currentValue = this.linksList.find(link => link.label === requestedLink.label)?.url;
    if(editedValue === currentValue) {
      this.hideEditLink(requestedLink);
    } else {
      this.configService.actions.links.unit.edit(
        this.unitCode,
        {
          label: requestedLink.label,
          url: editedValue
        }
      ).pipe(
        first()
      ).subscribe(
        (response) => {
          this.hideEditLink(requestedLink);

          this.feedbackAlert ? this.feedbackAlert.destroy() : null;
          this.feedbackAlert = generateAlertComponent(this.componentFactoryResolver, this.alertBox, editUnitLinkSuccessAlertConfiguration);
        },
        (error) => {
          this.feedbackAlert ? this.feedbackAlert.destroy() : null;
          this.feedbackAlert = generateAlertComponent(this.componentFactoryResolver, this.alertBox, editUnitLinkErrorAlertConfiguration);
        }
      );
    }

  }

  public deleteLink(linkLabel: string): void {
    this.configService.actions.links.unit.delete(
      this.unitCode,
      linkLabel,
      this.auctionID
    ).pipe(
      first()
    ).subscribe(
      (response) => {
        this.feedbackAlert ? this.feedbackAlert.destroy() : null;
        this.feedbackAlert = generateAlertComponent(this.componentFactoryResolver, this.alertBox, deleteUnitLinkSuccessAlertConfiguration);
      },
      (error) => {
        this.feedbackAlert ? this.feedbackAlert.destroy() : null;
        this.feedbackAlert = generateAlertComponent(this.componentFactoryResolver, this.alertBox, deleteUnitLinkErrorAlertConfiguration);
      }
    )
  }

  public isEditing(linkLabel: string): boolean {
    return Boolean(this.editingList.find(key => key === linkLabel));
  }

  public isUrlValid(requestedLink: Link): boolean {
    const editedValue = this.editLinkForm.controls[requestedLink.label].value;
    const currentValue = this.linksList.find(link => link.label === requestedLink.label)?.url;
    return (
      new RegExp(URL_WITH_PROTOCOL_PATTERN).test(editedValue) ||
      editedValue === currentValue
    );
  }

  private initNewLinkForm(): void {
    this.newLinkForm = new UntypedFormGroup({
      label: new UntypedFormControl('', [
        Validators.required
      ]),
      url: new UntypedFormControl('', this.URL_VALIDATORS)
    });
  }

  private initEditLinkForm(): void {
    this.editLinkForm = new UntypedFormGroup({});
  }

  private initUnitOptionsForm(): void {
    this.unitOptionsForm = new UntypedFormGroup({});
    Object.keys(this.currentUnitOptions).forEach(key => {
      this.unitOptionsForm.controls[key] = new UntypedFormControl(
        this.currentUnitOptions[key] || 0,
        []
      );
    });
  }

  private getExternalLinks(): void {
    this.app.getExternalLinks().pipe(
      takeUntil(this.onDestroy$),
      filter(linksList => Boolean(linksList))
    ).subscribe(linksList => {
      this.linksList = (linksList[this.unitCode] as Link[]);
    });
  }

  private getUnitsOptions(): void {
    this.app.getUnitsOptions().pipe(
      takeUntil(this.onDestroy$),
      filter(unitsOptions => Boolean(unitsOptions))
    ).subscribe(
      (unitsOptions) => {
        this.currentUnitOptions = unitsOptions[this.unitCode];
        this.initUnitOptionsForm();
      }
    );
  }
}