import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { getApiUrl } from 'src/app/utils/urls';
import { Note } from 'src/app/interfaces/notes';
import { HttpStatusCode } from 'src/app/enums/http-status-code.enum';
import { AuctionID } from 'src/app/interfaces/auction';
import { AppService } from 'src/app/services/local/app/app.service';


@Injectable({
  providedIn: 'root'
})
export class NotesService {
  constructor(
    private http: HttpClient,
    private app: AppService,
  ) { }

  getNotes(dateFrom: string, dateTo: string, auctionID: AuctionID): Observable<Note[]> {
    /* const storedNotes = this.app.getStoredNotes(auctionID, dayjs(dateFrom).startOf('day').format('YYYY-MM-DD HH:mm'));
    if(storedNotes) {
      return of(storedNotes);
    } else { */
      return this.http.get(
        Boolean(auctionID) ?
        getApiUrl('getnotes', null, {'dateFrom': dateFrom, 'dateTo': dateTo, 'auctionID': auctionID}) :
        getApiUrl('getnotes', null, {'dateFrom': dateFrom, 'dateTo': dateTo}),
        { observe: 'response' }
      ).pipe(
        map(response => {
          const responseBody: Note[] = <Note[]> response.body;
          responseBody.forEach(note => {
            note.quarter = <number> note.quarter * 1000; // epoch in seconds to epoch in milliseconds
          });
          //this.app.storeNotes(auctionID, dayjs(dateFrom).startOf('day').format('YYYY-MM-DD HH:mm'), responseBody);
          return <Note[]>responseBody;
        })
      );
    //}
  }

  sendNote(newNote: Note): Observable<string | Boolean> {
    return this.http.post(
      getApiUrl('sendnote'),
      {
        auctionID: newNote.auctionID,
        unitCode: newNote.unitCode,
        message: newNote.message,
        author: newNote.author,
        quarter: newNote.quarter
      },
      { observe: 'response' }
    ).pipe(
      map(response => {
        if(response.status === HttpStatusCode.Ok || response.status === HttpStatusCode.Created) {
          const body: any = response.body;
          /* const auctionID = newNote.auctionID;
          const timestamp = dayjs(newNote.quarter).startOf('day').format('YYYY-MM-DD HH:mm');
          let storedNotes = this.app.getStoredNotes(auctionID, timestamp);
          storedNotes.push({
            ...newNote,
            creationDate: dayjs().valueOf(),
            id: <string> body.id,
          });
          this.app.storeNotes(auctionID, timestamp, storedNotes); */
          return <string> body.id;
        }
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  deleteNote(noteID: string): Observable<Boolean> {
    return this.http.delete(
      getApiUrl('deletenote', noteID),
      { observe: 'response' }
    ).pipe(
      map(response => {
        return response.status === HttpStatusCode.Ok;
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  editNote(noteID: string, noteMessage: string): Observable<Boolean> {
    return this.http.patch(
      getApiUrl('editnote', noteID),
      { message: noteMessage },
      { observe: 'response' }
    ).pipe(
      map(response => {
        return response.status === HttpStatusCode.Ok;
      }),
      catchError(() => {
        return of(false);
      })
    );
  }
}