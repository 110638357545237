import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  ViewChild,
  ViewContainerRef,
  OnDestroy
} from '@angular/core';
import Chart from 'chart.js/auto';
import { ChartConfiguration } from 'chart.js';
import { UnitTable } from 'src/app/interfaces/table-report';
import {
  chartQuarterlyLabels,
  chartHourlyLabels,
  chartQuarterlyFullLabels,
  chartHourlyFullLabels
} from 'src/app/utils/consts';
import { UnitDataType } from 'src/app/enums/unit-data-type.enum'
import { ClockService } from 'src/app/services/local/clock/clock.service';
import { Subject } from 'rxjs';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Language } from 'src/app/enums/language.enum';
import { floorMinutesToQuarter, isNumberGreaterThan } from 'src/app/utils/functions';
import * as dayjs from 'dayjs';
import { ChartTooltipData } from 'src/app/interfaces/chart';
import { ElementRef } from '@angular/core';
import { AppService } from 'src/app/services/local/app/app.service';
import { StorageKeys } from 'src/app/enums/storage-keys';

@Component({
  selector: 'app-chart-line',
  templateUrl: './chart-line.component.html'
})
export class ChartLineComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() tableDataset: UnitTable;
  @Input() id: number;
  @Input() canvasWidth: number = 880;
  @Input() canvasHeight: number = 140;
  @Input() isModalView: boolean = false;

  @ViewChild('chartTooltip', {read: ViewContainerRef, static: false}) chartTooltip: ViewContainerRef;
  @ViewChild('canvasWrapper') canvasWrapper: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;

  private readonly CHART_COLORS = {
    pvActual: '#36b9da', //sky
    pvPrevious: '#9d60fb', //purple graph
    pvm: '#2487a0', //dark sky
    power: '#ff5000', //coral
    powerMax: '#013d5a', //dark blue
    mib: '#77899840', //wolf gray alpha-25
    mibDark: '#e3e9ee40', //pale gray alpha-25
    todayAnnotation: '#b4c6d4', //concrete
    todayAnnotationDark: '#e3e9ee', //pale gray
    todayAnnotationBackground: '#f1fafd', //sky alpha-7
    todayAnnotationBackgroundDark: '#778998', //wolf gray
    todayAnnotationText: '#333e48', //dark gray
    todayAnnotationTextDark: '#e3e9ee', //pale gray
    axisLabels: '#333e48', //dark gray
    axisLabelsDark: '#e3e9ee', //pale gray
    grid: '#e4e7ea', //untitled light-gray color
    gridDark: '#778998', //wolf gray
  };
  private readonly CHART_ID_PREFIX = 'chartjs-';
  private readonly CHART_BASE_LINE_TENSION = 0.3;
  private readonly LEGEND_ITEM_INACTIVE_CLASS = 'inactive';
  private readonly TOOLTIP_WIDTH = 124;
  private readonly onDestroy$ = new Subject<void>();

  public readonly CHART_TOOLTIP_ID_SUFFIX = '-tooltip';
  public readonly CHART_DATA_LABELS = {
    PV: 'AUCTION--CHART:legend-pv',
    PV_PREVIOUS: 'AUCTION--CHART:legend-pv-previous',
    PVM: 'AUCTION--CHART:legend-pvm',
    POWER: 'AUCTION--CHART:legend-power',
    POWERMAX: 'AUCTION--CHART:legend-powermax',
    MIB: 'AUCTION--CHART:legend-mib',
  };
  public readonly isNumberGreaterThan = isNumberGreaterThan;

  private chart: Chart;
  private tooltipNode: HTMLElement;
  private currentVisibleTimeLabels: string[];
  private currentFullTimeLabels: string[];
  private isDatasetQuarterly: boolean;
  private currentLanguage: Language;
  private isThemeDark: boolean = false;

  public chartConfig: ChartConfiguration;
  public chartNode: HTMLCanvasElement;
  public chartNodeID: string;
  public chartMaxValue: number;
  public chartMinValue: number;
  public tooltipDataset: ChartTooltipData[] = [];
  public currentHoveredTooltipTime: string;
  public currentClockTime: string;
  public previousClockTime: string;

  constructor(
    private clock: ClockService,
    private translateService: TranslateService,
    private app: AppService,
  ) {
    this.translateService.onLangChange.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((event: LangChangeEvent) => {
      if(event && event.lang) {
        this.currentLanguage = <Language>event.lang;
      }
    });

    this.clock.time.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe((now: Date) => {
      if(this.chart) {
        this.currentClockTime = new Date(now).toLocaleString(this.currentLanguage, { hour: '2-digit', minute: '2-digit' });
        // "Property 'today' does not exist" error possibly caused by library
        //@ts-ignore
        this.chart.config.options.plugins.annotation.annotations.today.label.content = this.currentClockTime;
        const flooredMinutes = this.isDatasetQuarterly ?
          floorMinutesToQuarter( new Date(now).getMinutes() ) :
          0;
        const flooredTime = new Date(
          new Date().setMinutes(flooredMinutes)
        ).toLocaleString(this.currentLanguage, { hour: '2-digit', minute: '2-digit' });
        if( this.currentFullTimeLabels ) {
          const todayAnnotationPosition = this.currentFullTimeLabels.indexOf(flooredTime);
          // "Property 'today' does not exist" error possibly caused by library
          //@ts-ignore
          this.chart.config.options.plugins.annotation.annotations.today.xMax = todayAnnotationPosition;
          //@ts-ignore
          this.chart.config.options.plugins.annotation.annotations.today.xMin = todayAnnotationPosition;
        }
        if(this.currentClockTime != this.previousClockTime) {
          this.previousClockTime = this.currentClockTime;
          this.chart.update();
        }
      }
    });
  }

  ngOnInit() {
    this.isThemeDark = localStorage.getItem(StorageKeys.IS_THEME_DARK) === 'true';
    this.isDatasetQuarterly = this.tableDataset.values[0].length == 4;
    this.currentVisibleTimeLabels = this.isDatasetQuarterly ? chartQuarterlyLabels : chartHourlyLabels;
    this.currentFullTimeLabels = this.isDatasetQuarterly ? chartQuarterlyFullLabels : chartHourlyFullLabels;
    this.chartNodeID = this.CHART_ID_PREFIX + this.id;
    this.chartConfig = this.generateChartConfig();
  }

  ngAfterViewInit() {
    this.generateChart();
    this.app.getNotebarVisibility().pipe(
      takeUntil(this.onDestroy$),
      distinctUntilChanged()
    ).subscribe((isNotebarOpen)=>{
      this.resizeChart();
    });
    this.updateMibValues();
    this.chartMaxValue = this.chart.scales.y.max;
    this.chartMinValue = this.chart.scales.y.min;
    this.tooltipNode = this.chartTooltip.element.nativeElement;
    this.chart.update();
  }

  ngOnDestroy(): void {
    this.chart.destroy();
    this.onDestroy$.next();
  }

  private generateChartConfig(): ChartConfiguration {
    Chart.defaults.datasets.line.tension = this.CHART_BASE_LINE_TENSION;
    Chart.defaults.datasets.line.cubicInterpolationMode = 'monotone';
    Chart.defaults.datasets.line.borderWidth = 2;
    Chart.defaults.datasets.line.pointBorderWidth = 0;
    const chartDataset = this.tableDataset.isQuarterly ?
      [
        {
          label: this.CHART_DATA_LABELS.POWER,
          data: this.extractData(this.tableDataset, UnitDataType.POWER),
          backgroundColor: this.CHART_COLORS.power,
          borderColor: this.CHART_COLORS.power,
          borderWidth: 5,
          tension: this.CHART_BASE_LINE_TENSION,
          cubicInterpolationMode: 'monotone',
        },
        {
          label: this.CHART_DATA_LABELS.PV,
          data: this.extractData(this.tableDataset, UnitDataType.PV_ACTUAL),
          backgroundColor: this.CHART_COLORS.pvActual,
          borderColor: this.CHART_COLORS.pvActual,
          tension: this.CHART_BASE_LINE_TENSION,
          cubicInterpolationMode: 'monotone',
        },
        {
          label: this.CHART_DATA_LABELS.PV_PREVIOUS,
          data: this.extractData(this.tableDataset, UnitDataType.PV_PREVIOUS),
          backgroundColor: this.CHART_COLORS.pvPrevious,
          borderColor: this.CHART_COLORS.pvPrevious,
          tension: this.CHART_BASE_LINE_TENSION,
          cubicInterpolationMode: 'monotone',
        },
        {
          label: this.CHART_DATA_LABELS.PVM,
          data: this.extractData(this.tableDataset, UnitDataType.PVM),
          backgroundColor: this.CHART_COLORS.pvm,
          borderColor: this.CHART_COLORS.pvm,
          stepped: 'before',
          tension: 0,
        },
        {
          label: this.CHART_DATA_LABELS.POWERMAX,
          data: this.extractData(this.tableDataset, UnitDataType.POWER_MAX),
          backgroundColor: this.CHART_COLORS.powerMax,
          borderColor: this.CHART_COLORS.powerMax,
          tension: this.CHART_BASE_LINE_TENSION,
          cubicInterpolationMode: 'monotone',
        },
        {
          label: this.CHART_DATA_LABELS.MIB,
          data: [],
          backgroundColor: this.isThemeDark ? this.CHART_COLORS.mibDark : this.CHART_COLORS.mib,
          borderColor: 'transparent',
          pointBackgroundColor: 'transparent',
          fill: 'start',
          stepped: 'before',
          tension: 0,
          
        }
      ] :
      [
        {
          label: this.CHART_DATA_LABELS.POWER,
          data: this.extractData(this.tableDataset, UnitDataType.POWER),
          backgroundColor: this.CHART_COLORS.power,
          borderColor: this.CHART_COLORS.power,
          borderWidth: 5,
          tension: this.CHART_BASE_LINE_TENSION,
          cubicInterpolationMode: 'monotone',
        },
        {
          label: this.CHART_DATA_LABELS.PV,
          data: this.extractData(this.tableDataset, UnitDataType.PV_ACTUAL),
          backgroundColor: this.CHART_COLORS.pvActual,
          borderColor: this.CHART_COLORS.pvActual,
          tension: this.CHART_BASE_LINE_TENSION,
          cubicInterpolationMode: 'monotone',
        },
        {
          label: this.CHART_DATA_LABELS.PV_PREVIOUS,
          data: this.extractData(this.tableDataset, UnitDataType.PV_PREVIOUS),
          backgroundColor: this.CHART_COLORS.pvPrevious,
          borderColor: this.CHART_COLORS.pvPrevious,
          tension: this.CHART_BASE_LINE_TENSION,
          cubicInterpolationMode: 'monotone',
        },
        {
          label: this.CHART_DATA_LABELS.MIB,
          data: [],
          backgroundColor: this.isThemeDark ? this.CHART_COLORS.mibDark : this.CHART_COLORS.mib,
          borderColor: 'transparent',
          pointBackgroundColor: 'transparent',
          fill: 'start',
          stepped: 'before',
          tension: 0,
          
        }
      ];
    return {
      type: 'line',
      data: {
        labels: this.currentVisibleTimeLabels,
        datasets: chartDataset,
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              drawBorder: false,
              drawTicks: false,
              color: this.isThemeDark ? this.CHART_COLORS.gridDark : this.CHART_COLORS.grid,
            },
            ticks: {
              color: this.isThemeDark ? this.CHART_COLORS.axisLabelsDark : this.CHART_COLORS.axisLabels,
              padding: 12,
            },
          },
          x: {
            grid: {
              borderDash: [4],
              color: (context) => {
                const themedColor = this.isThemeDark ? this.CHART_COLORS.gridDark : this.CHART_COLORS.grid;
                if(this.isDatasetQuarterly) {
                  if(context.index % 4 == 0) {
                    return themedColor;
                  } else {
                    return 'transparent';
                  }
                } else {
                  return themedColor;
                }
              },
              drawTicks: false,
              borderColor: this.isThemeDark ? this.CHART_COLORS.gridDark : this.CHART_COLORS.grid,
            },
            ticks: {
              autoSkip: false,
              maxRotation: 0,
              minRotation: 0,
              color: this.isThemeDark ? this.CHART_COLORS.axisLabelsDark : this.CHART_COLORS.axisLabels,
              padding: 15,
            },
          }
        },
        interaction: {
          intersect: false,
          mode: 'index'
        },
        elements: {
          point: {
            radius: 1
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false,
            position: 'nearest',
            external: (context) => {
              if (context.tooltip.opacity === 0) {
                this.tooltipNode.style.opacity = '0';
                return;
              }
              this.tooltipNode.style.opacity = '1';
              this.tooltipDataset = [];
              const dataPoints = context.tooltip.dataPoints;
              dataPoints.forEach(dataPoint => {
                this.tooltipDataset.push({
                  label: dataPoint.dataset.label,
                  value: (dataPoint.dataset.data[dataPoint.dataIndex] as number)
                });
                this.currentHoveredTooltipTime = this.currentFullTimeLabels[dataPoint.dataIndex]
              });
              this.tooltipNode.style.left = context.tooltip.xAlign == 'left' ?
                (context.tooltip.x + 10).toString() + 'px' :
                (context.tooltip.x + this.TOOLTIP_WIDTH).toString() + 'px';
              this.tooltipNode.style.top = (context.tooltip.y).toString() + 'px';
            },
          },
          annotation: {
            annotations: {
              today: {
                display: dayjs(this.tableDataset.unitDate).date() === dayjs().date(),
                drawTime: 'afterDraw',
                type: 'line',
                xMin: 0,
                xMax: 0,
                borderColor: this.isThemeDark ? this.CHART_COLORS.todayAnnotationDark : this.CHART_COLORS.todayAnnotation,
                borderWidth: 2,
                label: {
                  enabled: true,
                  content: '--:--',
                  position: 'end',
                  yAdjust: 40,
                  backgroundColor: this.isThemeDark ? this.CHART_COLORS.todayAnnotationBackgroundDark : this.CHART_COLORS.todayAnnotationBackground,
                  color: this.isThemeDark ? this.CHART_COLORS.todayAnnotationTextDark : this.CHART_COLORS.todayAnnotationText,
                },
              }
            }
          }
        },
        layout: {
          padding: 0,
        },
        responsive: this.isModalView,
        maintainAspectRatio: false
      },
    };
  }

  private generateChart(): void {
    this.chart = new Chart(this.canvas.nativeElement, this.chartConfig);
  }

  private extractData(tableDataset: UnitTable, dataType: UnitDataType): number[] {
    let res: number[] = [];
    tableDataset.values.forEach(hour => {
      hour.forEach(value => {
        res.push(value[dataType]);
      });
    });
    return res;
  }

  private generateMibValues(): number[] {
    let mibValues = [];
    const MibooleanValues = this.extractData(this.tableDataset, UnitDataType.MIB);
    MibooleanValues.forEach(mibValue => {
      mibValues.push(
        mibValue ?
          this.chart.scales.y.max :
          this.chart.scales.y.min
      )
    });
    return mibValues;
  }

  private updateMibValues(): void {
    const mibIndex = this.chart.data.datasets.findIndex(dataset => dataset.label == this.CHART_DATA_LABELS.MIB);
    this.chart.data.datasets[mibIndex].data = this.generateMibValues();
  }

  private resizeChart():void {
    if(!this.isModalView) {
      setTimeout(()=>{
        this.chart?.resize(0, this.canvasHeight);
        const wrapperNodeWidth = (this.canvasWrapper.nativeElement as HTMLElement)?.offsetWidth;
        this.chart?.resize(wrapperNodeWidth, this.canvasHeight);

        this.chart.update();
      });
    }
  }

  public chartLegendEvent(event: Event, index: number): void {
    const target = event.currentTarget as HTMLElement;
    const state = target.classList.contains(this.LEGEND_ITEM_INACTIVE_CLASS);
    this.chart.setDatasetVisibility(index, state);
    target.classList.toggle(this.LEGEND_ITEM_INACTIVE_CLASS);
    this.chart.update();
    this.updateMibValues();
    this.chart.update();
  }
}
