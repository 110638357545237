<div class="modal card show" [id]="id" [ngClass]="[styleClasses ? styleClasses : '']" (clickOutside)="clickOutside($event)">
  <div class="modal-head">
    <div class="modal-head-content">
      <ng-content select="[modal-head]"></ng-content>
    </div>
    <button class="modal-close" (click)="close()">
      <i [ngClass]="[closeIconID, closeIconSize, 'icon-coral icon-box icon-box-small icon-anim']"></i>
    </button>
  </div>
  <div class="modal-body">
    <ng-content select="[modal-body]"></ng-content>
  </div>
  <div class="modal-footer">
    <ng-content select="[modal-footer]"></ng-content>
  </div>
</div>