<div
  class="table table-bde"
  role="table"
  [ngClass]="[isSingleUnit ? 'column-spread' : '']"
>
  <div class="table-head table-bde-head" role="rowgroup">
    <div class="table-head-row table-bde-head-row" role="row">
      <div
        role="cell"
        class="table-head-cell table-bde-head-cell cell-name typo-body-2 typo-bold"
        [innerText]="'TABLE-BDE-HEAD:unit' | translate"
        *ngIf="!isSingleUnit"
      ></div>
      <div class="table-head-cell table-bde-head-cell cell-data">
        <span
          role="cell"
          class="table-bde-head-subcell subcell-type typo-body-2 typo-bold"
          [innerText]="'TABLE-BDE-HEAD:type' | translate"
        >
        </span>
        <span
          role="cell"
          class="table-bde-head-subcell subcell-start-timestamp typo-body-2 typo-bold"
          [innerText]="'TABLE-BDE-HEAD:start-timestamp' | translate"
        >
        </span>
        <span
          role="cell"
          class="table-bde-head-subcell subcell-end-timestamp typo-body-2 typo-bold"
          [innerText]="'TABLE-BDE-HEAD:end-timestamp' | translate"
        >
        </span>
        <span
          role="cell"
          class="table-bde-head-subcell subcell-start-value typo-body-2 typo-bold"
          [innerText]="'TABLE-BDE-HEAD:start-value' | translate"
        >
        </span>
        <span
          role="cell"
          class="table-bde-head-subcell subcell-end-value typo-body-2 typo-bold"
          [innerText]="'TABLE-BDE-HEAD:end-value' | translate"
        >
        </span>
      </div>
    </div>
  </div>
  <div class="table-body table-bde-body" role="rowgroup">
    <ng-container
      *ngIf="!isSingleUnit; then multipleUnits; else singleUnit"
    ></ng-container>
    <ng-template #multipleUnits>
      <ng-container *ngIf="!!dataset; else noData">
        <div
          class="table-body-row table-bde-body-row"
          *ngFor="let unit of dataset; index as unitIndex"
          role="row"
        >
          <div
            class="table-body-cell table-bde-body-cell cell-name tooltip-top tooltip-large"
            [attr.data-tooltip]="unit.unitCode"
            role="cell"
          >
            <a
              *ngIf="routedLinks"
              [routerLink]="['/auction']"
              [queryParams]="{
                id: unit.auctionID,
                scrollTo: UNIT_ID_LINK_PREFIX + unitIndex
              }"
              [innerText]="unit.unitCode | translate"
              class="typo-textlink typo-body-2 typo-bold typo-uppercase text-trim-1"
            >
            </a>
            <a
              *ngIf="!routedLinks"
              [routerLink]=""
              [queryParams]="{ scrollTo: UNIT_ID_LINK_PREFIX + unitIndex }"
              [queryParamsHandling]="'merge'"
              [innerText]="unit.unitCode | translate"
              [attr.data-scrollto]="UNIT_ID_LINK_PREFIX + unitIndex"
              class="typo-textlink typo-body-2 typo-bold typo-uppercase text-trim-1"
            >
            </a>
          </div>
          <div class="table-body-cell table-bde-body-cell cell-data">
            <div *ngFor="let bde of unit.bde" class="table-bde-body-cell-group">
              <div
                role="cell"
                class="table-bde-body-subcell subcell-type table-bde-body-cell"
              >
                <span class="typo-body-2" [innerText]="bde.type | translate">
                </span>
              </div>
              <div
                role="cell"
                class="table-bde-body-subcell subcell-start-timestamp table-bde-body-cell"
              >
                <span
                  class="typo-body-2 tooltip-top tooltip-auto"
                  [attr.data-tooltip]="
                    dayjs(bde.startTime).format('DD/MM/YYYY HH:mm:ss')
                  "
                  [innerText]="dayjs(bde.startTime).format('HH:mm')"
                >
                </span>
              </div>
              <div
                role="cell"
                class="table-bde-body-subcell subcell-end-timestamp table-bde-body-cell"
              >
                <span
                  class="typo-body-2 tooltip-top tooltip-auto"
                  [attr.data-tooltip]="
                    dayjs(bde.endTime).format('DD/MM/YYYY HH:mm:ss')
                  "
                  [innerText]="dayjs(bde.endTime).format('HH:mm')"
                >
                </span>
              </div>
              <div
                role="cell"
                class="table-bde-body-subcell subcell-start-value table-bde-body-cell"
              >
                <!-- <span
                  *ngIf="bde.type !== BDEType.RAMP"
                  class="typo-body-2 tooltip-top tooltip-auto"
                  [attr.data-tooltip]="bde.ptIni"
                  [innerText]="bde.ptIni | floatTrim">
                </span>
                <span
                  *ngIf="bde.type === BDEType.RAMP"
                  class="typo-body-2 tooltip-top tooltip-auto"
                  [attr.data-tooltip]="bde.pvPowerDelta_TINI"
                  [innerText]="bde.pvPowerDelta_TINI | floatTrim">
                </span>
                -->
                <span
                  class="typo-body-2 tooltip-top tooltip-auto"
                  [attr.data-tooltip]="bde.pvPowerDelta_TINI"
                  [innerText]="bde.pvPowerDelta_TINI | floatTrim"
                >
                </span>
              </div>
              <div
                role="cell"
                class="table-bde-body-subcell subcell-end-value table-bde-body-cell"
              >
                <!-- <span
                  *ngIf="bde.type !== BDEType.RAMP"
                  class="typo-body-2 tooltip-top tooltip-auto"
                  [attr.data-tooltip]="bde.ptFin"
                  [innerText]="bde.ptFin | floatTrim"
                >
                </span>
                <span
                  *ngIf="bde.type === BDEType.RAMP"
                  class="typo-body-2 tooltip-top tooltip-auto"
                  [attr.data-tooltip]="bde.pvPowerDelta_TFIN"
                  [innerText]="bde.pvPowerDelta_TFIN | floatTrim"
                >
                </span> -->
                <span
                  class="typo-body-2 tooltip-top tooltip-auto"
                  [attr.data-tooltip]="bde.pvPowerDelta_TFIN"
                  [innerText]="bde.pvPowerDelta_TFIN | floatTrim"
                >
                </span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-template>

    <ng-template #singleUnit>
      <ng-container *ngIf="!!singleDataset; else noData">
        <div class="table-body-cell table-bde-body-cell cell-data">
          <div
            *ngFor="let singleDatasetBde of singleDataset"
            class="table-bde-body-cell-group"
          >
            <div
              role="cell"
              class="table-bde-body-subcell subcell-type table-bde-body-cell"
            >
              <span
                class="typo-body-2"
                [innerText]="singleDatasetBde.type | translate"
              >
              </span>
            </div>
            <div
              role="cell"
              class="table-bde-body-subcell subcell-start-timestamp table-bde-body-cell"
            >
              <span
                class="typo-body-2 tooltip-left tooltip-auto"
                [attr.data-tooltip]="
                  dayjs(singleDatasetBde.startTime).format(
                    'DD/MM/YYYY HH:mm:ss'
                  )
                "
                [innerText]="dayjs(singleDatasetBde.startTime).format('HH:mm')"
              >
              </span>
            </div>
            <div
              role="cell"
              class="table-bde-body-subcell subcell-end-timestamp table-bde-body-cell"
            >
              <span
                class="typo-body-2 tooltip-left tooltip-auto"
                [attr.data-tooltip]="
                  dayjs(singleDatasetBde.endTime).format('DD/MM/YYYY HH:mm:ss')
                "
                [innerText]="dayjs(singleDatasetBde.endTime).format('HH:mm')"
              >
              </span>
            </div>
            <div
              role="cell"
              class="table-bde-body-subcell subcell-start-value table-bde-body-cell"
            >
              <!--
                <span
                *ngIf="singleDatasetBde.type !== BDEType.RAMP"
                class="typo-body-2 tooltip-left tooltip-auto"
                [attr.data-tooltip]="singleDatasetBde.ptIni"
                [innerText]="singleDatasetBde.ptIni | floatTrim"
              >
              </span>
              <span
                *ngIf="singleDatasetBde.type === BDEType.RAMP"
                class="typo-body-2 tooltip-left tooltip-auto"
                [attr.data-tooltip]="singleDatasetBde.pvPowerDelta_TINI"
                [innerText]="singleDatasetBde.pvPowerDelta_TINI | floatTrim"
              >
              </span>
            -->
              <span
                class="typo-body-2 tooltip-left tooltip-auto"
                [attr.data-tooltip]="singleDatasetBde.pvPowerDelta_TINI"
                [innerText]="singleDatasetBde.pvPowerDelta_TINI | floatTrim"
              >
              </span>
            </div>
            <div
              role="cell"
              class="table-bde-body-subcell subcell-end-value table-bde-body-cell"
            >
              <!--
              <span
                *ngIf="singleDatasetBde.type !== BDEType.RAMP"
                class="typo-body-2 tooltip-left tooltip-auto"
                [attr.data-tooltip]="singleDatasetBde.ptFin"
                [innerText]="singleDatasetBde.ptFin | floatTrim"
              >
              </span>
              <span
                *ngIf="singleDatasetBde.type === BDEType.RAMP"
                class="typo-body-2 tooltip-left tooltip-auto"
                [attr.data-tooltip]="singleDatasetBde.pvPowerDelta_TFIN"
                [innerText]="singleDatasetBde.pvPowerDelta_TFIN | floatTrim"
              >
              </span>
             -->
              <span
                class="typo-body-2 tooltip-left tooltip-auto"
                [attr.data-tooltip]="singleDatasetBde.pvPowerDelta_TFIN"
                [innerText]="singleDatasetBde.pvPowerDelta_TFIN | floatTrim"
              >
              </span>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </div>
</div>

<ng-template #noData>
  <div class="table-body-row table-bde-body-row" role="row">
    <span
      class="table-body-cell table-bde-body-cell typo-body-2"
      [innerText]="'TABLE-BDE:no-data' | translate"
      role="cell"
    >
    </span>
  </div>
</ng-template>
