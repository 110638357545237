<div class="unit-config">
  <!-- <div class="unit-config-header">
    <div class="unit-config-header-left">
      <h2 class="typo-heading-2" [innerText]="auctionName"></h2>
    </div>
    <div class="unit-config-header-right">
      <span class="typo-heading-4 typo-regular" [innerText]="'UNIT-CONFIG:header-subtitle' | translate"></span>
      <span class="typo-heading-4 typo-bold" [innerText]="unitCode | translate"></span>
      <i class="icon-cross icon-coral icon-small icon-box icon-box-small icon-anim cursor-pointer unit-config-close"></i>
    </div>
  </div> -->
  <div class="unit-config-body">

    <div class="unit-config-body-left">
      <div class="unit-config-body-block unit-config-block-general">
        <span class="unit-config-block-title typo-heading-4" [innerText]="'UNIT-CONFIG:general-settings' | translate"></span>
        <app-input-checkbox
          [id]="'checkbox-balanceable'"
          [initialValue]="unitOptionsForm.controls.balanceable.value"
          [checkboxLabel]="'UNIT-CONFIG:checkbox-balanceable' | translate"
          [styleClasses]="'custom-checkbox'"
          [labelStyleClasses]="'typo-body-1'"
          (valueChange)="switchVisibilityOption('balanceable')"
          [control]="unitOptionsForm.controls.balanceable">
        </app-input-checkbox>
        <app-input-checkbox
          [id]="'checkbox-mib'"
          [initialValue]="unitOptionsForm.controls.showMib.value"
          [checkboxLabel]="'UNIT-CONFIG:checkbox-mib'"
          [styleClasses]="'custom-checkbox'"
          [labelStyleClasses]="'typo-body-1'"
          (valueChange)="switchVisibilityOption('showMib')"
          [control]="unitOptionsForm.controls.showMib">
        </app-input-checkbox>
        <app-input-checkbox
          [id]="'checkbox-pmax'"
          [initialValue]="unitOptionsForm.controls.showPowerMax.value"
          [checkboxLabel]="'UNIT-CONFIG:checkbox-pmax'"
          [styleClasses]="'custom-checkbox'"
          [labelStyleClasses]="'typo-body-1'"
          (valueChange)="switchVisibilityOption('showPowerMax')"
          [control]="unitOptionsForm.controls.showPowerMax">
        </app-input-checkbox>
        <app-input-checkbox
          [id]="'checkbox-pmin'"
          [initialValue]="unitOptionsForm.controls.showPowerMin.value"
          [checkboxLabel]="'UNIT-CONFIG:checkbox-pmin'"
          [styleClasses]="'custom-checkbox'"
          [labelStyleClasses]="'typo-body-1'"
          (valueChange)="switchVisibilityOption('showPowerMin')"
          [control]="unitOptionsForm.controls.showPowerMin">
        </app-input-checkbox>
        <app-input-checkbox
          [id]="'checkbox-pvm'"
          [initialValue]="unitOptionsForm.controls.showPVM.value"
          [checkboxLabel]="'UNIT-CONFIG:checkbox-pvm'"
          [styleClasses]="'custom-checkbox'"
          [labelStyleClasses]="'typo-body-1'"
          (valueChange)="switchVisibilityOption('showPVM')"
          [control]="unitOptionsForm.controls.showPVM">
        </app-input-checkbox>
      </div>

      <div class="unit-config-block-links">
        <span class="unit-config-block-title typo-heading-4" [innerText]="'UNIT-CONFIG:new_link' | translate"></span>

        <div class="unit-config-newlink-input">
          <div class="unit-config-input-wrapper">
            <app-input-text
              [id]="'new-link-title'"
              [placeholder]="'UNIT-CONFIG:new_link-title'"
              [styleClasses]="'typo-body-2'"
              [control]="newLinkForm.controls.label">
            </app-input-text>
          </div>
          <i
            class="icon-checkmark icon-small icon-dark-gray icon-box icon-box-small icon-anim icon-clickable unit-config-newlink-icon"
            [ngClass]="{'icon-disabled': newLinkForm.invalid || isLinkAlreadyExisting(newLinkForm.controls.label.value)}"
            (click)="requestNewLink()">
          </i>
        </div>
        <div class="unit-config-newlink-input">
          <div class="unit-config-input-wrapper">
            <app-input-text
              [id]="'new-link-url'"
              [placeholder]="'UNIT-CONFIG:new_link-url'"
              [styleClasses]="'typo-body-2'"
              [control]="newLinkForm.controls.url">
            </app-input-text>
          </div>
        </div>

        <div class="unit-config-link-list" *ngIf="linksList?.length > 0">
          <ng-container *ngFor="let link of linksList; index as i">
            <div class="unit-config-link-list-item">
              <div class="unit-config-link-list-item-view">
                <div class="unit-config-link-list-item-view-label typo-body-2 typo-bold text-trim-1" [innerText]="link.label"></div>
                <i
                  class="icon-pencil icon-small icon-dark-gray icon-anim icon-clickable unit-config-link-list-item-view-icon"
                  [ngClass]="{'icon-disabled': isEditing(link.label)}"
                  (click)="showEditLink(link)">
                </i>
                <i
                  class="icon-cross icon-small icon-dark-gray icon-anim icon-clickable unit-config-link-list-item-view-icon"
                  [ngClass]="{'icon-disabled': isEditing(link.label)}"
                  (click)="deleteLink(link.label)">
                </i>
              </div>
              <div class="unit-config-link-list-item-edit" *ngIf="isEditing(link.label)">
                <div class="unit-config-input-wrapper">
                  <app-input-text
                    [id]="'edit-link-' + i"
                    [placeholder]="'UNIT-CONFIG:new_link-url'"
                    [styleClasses]="'typo-body-2'"
                    [control]="editLinkForm.controls[link.label]"
                    [initialValue]="link.url">
                  </app-input-text>
                </div>
                <i
                  class="icon-checkmark icon-small icon-dark-gray icon-box icon-box-small icon-anim icon-clickable unit-config-link-list-item-edit-icon"
                  [ngClass]="{'icon-disabled': !isUrlValid(link)}"
                  (click)="confirmEditLink(link)">
                </i>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

    </div>

    <div class="unit-config-body-right">
      <span class="unit-config-block-title typo-heading-4" [innerText]="'UNIT-CONFIG:threshold-settings' | translate"></span>
      <app-chart-doughnut
        [percentageValue]="unitOptionsForm.controls.thresholdValue.value"
        [minutesValue]="unitOptionsForm.controls.thresholdDuration.value">
      </app-chart-doughnut>      
      <div class="unit-config-range-container">
        <i class="icon-lightning icon-medium icon-gun-metal unit-config-range-icon"></i>
        <app-input-range
          [id]="'range-1'"
          [minValue]="0"
          [maxValue]="50"
          [step]="0.1"
          [initialValue]="unitOptionsForm.controls.thresholdValue.value"
          [isTrackInverted]="true"
          [labelUnit]="'%'"
          (valueChange)="updateTresholdPercentage()"
          [control]="unitOptionsForm.controls.thresholdValue">
        </app-input-range>
      </div>
      <div class="unit-config-range-container">
        <i class="icon-clock icon-medium icon-gun-metal unit-config-range-icon"></i>
        <app-input-range
          [id]="'range-2'"
          [minValue]="1"
          [maxValue]="60"
          [initialValue]="unitOptionsForm.controls.thresholdDuration.value"
          [isTrackInverted]="true"
          [labelUnit]="' min'"
          (valueChange)="updateTresholdDuration()"
          [control]="unitOptionsForm.controls.thresholdDuration">
        </app-input-range>
      </div>
    </div>
  </div>

  <div class="auction-config-alert-box">
    <template #alertBox></template>
  </div>
</div>