import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {PreviewTable, PreviewTableList} from 'src/app/interfaces/table-preview';
import {NULL_UNIT_SELECTION, UnitTable, UnitTableSelection} from 'src/app/interfaces/table-report';
import {BdeList} from 'src/app/interfaces/bde-list';
import {deepCopy} from 'src/app/utils/functions';
import * as dayjs from 'dayjs';
import {DATA_UPDATE_FREQUENCY} from 'src/app/utils/consts';
import {AuctionID} from 'src/app/interfaces/auction';
import {MIBList} from 'src/app/interfaces/mib-list';
import {UserData} from 'src/app/interfaces/user-data';
import {Link} from 'src/app/interfaces/link';
import {UnitCode} from 'src/app/enums/unit-codes.enum';
import {ToastrService} from "ngx-toastr";
import {DifferenceStatus} from "../../../enums/difference-status.enum";
import {TranslateService} from "@ngx-translate/core";
//import { Note } from 'src/app/interfaces/notes';

type StoredUnitTable = Record<AuctionID, Record<string, StoredData<UnitTable[]>>>;
type StoredPreviewTable = Record<AuctionID, StoredData<PreviewTable[]>>;
type StoredPreviewTableList = StoredData<PreviewTable[]>;
type StoredDashboardBdeList = StoredData<BdeList[]>;
type StoredDashboardMIBList = StoredData<MIBList[]>;
//type StoredBdeList = Record<string, StoredData<BdeList[]>>;
//type StoredNotes = Record<AuctionID, Record<string, StoredData<Note[]>>>;

export interface StoredLinksList {
  global?: string;
  [key: string]: string|Link[];
}

export interface UnitOptions {
  balanceable: boolean;
  showMib: boolean;
  showPowerMax: boolean;
  showPowerMin: boolean;
  showPVM: boolean;
  thresholdValue: number;
  thresholdDuration: number;
}

export interface StoredUnitsOptions {
  [key: string]: UnitOptions;
}

interface StoredData<T> {
  data: T;
  timeout: number;
}

interface AppStorage {
  auctionsList: PreviewTableList[];
  units: StoredUnitTable;
  allUnits: Array<StoredUnitTable>;
  recaps: StoredPreviewTable;
  allRecaps: StoredPreviewTableList;
  dashboardBdeList: StoredDashboardBdeList;
  dashboardMIBList: StoredDashboardMIBList;
  //bdeList: StoredBdeList;
  //notes: StoredNotes;
  selectedUnitCell: BehaviorSubject<UnitTableSelection>;
  isUserLoggedIn: BehaviorSubject<boolean>;
  userData: BehaviorSubject<UserData>;
  isNotebarOpen: BehaviorSubject<boolean>;
  linksList: BehaviorSubject<StoredLinksList>;
  unitsOptions: BehaviorSubject<StoredUnitsOptions>;
}

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private storage: AppStorage = {
    auctionsList: null,
    units: {},
    allUnits: [],
    recaps: {},
    allRecaps: null,
    dashboardBdeList: null,
    dashboardMIBList: null,
    //bdeList: {},
    //notes: {},
    selectedUnitCell: new BehaviorSubject<UnitTableSelection>(NULL_UNIT_SELECTION),
    isUserLoggedIn: new BehaviorSubject<boolean>(false),
    userData: new BehaviorSubject<UserData>({}),
    isNotebarOpen: new BehaviorSubject<boolean>(null),
    linksList: new BehaviorSubject<StoredLinksList>(null),
    unitsOptions: new BehaviorSubject<StoredUnitsOptions>({})
  };

  constructor() {}

  private generateStoreTimeout(): number {
    return dayjs().add(DATA_UPDATE_FREQUENCY - 1, 'second').valueOf();
  }



  //getters

  public getSelectedUnitCell(): Observable<UnitTableSelection> {
    return this.storage.selectedUnitCell;
  }

  public getStoredAuctionsList(): PreviewTableList[] {
    if(this.storage.auctionsList) {
      return deepCopy(this.storage.auctionsList);
    } else {
      return undefined;
    }
  }

  public getStoredUnits(auctionID: AuctionID, timestamp: string): UnitTable[] {
    if(
      this.storage.units[auctionID] &&
      this.storage.units[auctionID][timestamp] &&
      this.storage.units[auctionID][timestamp].timeout > dayjs().valueOf()
    ) {
      return deepCopy(this.storage.units[auctionID][timestamp].data);
    } else {
      return undefined;
    }
  }

  public getAllStoredUnits(timestamp: string): UnitTable[] {
    if (
      this.storage.allUnits &&
      this.storage.allUnits[timestamp] &&
      this.storage.allUnits[timestamp].timeout > dayjs().valueOf()
    ) {
      return deepCopy(this.storage.allUnits[timestamp].data);
    } else {
      return undefined;
    }
  }

  public getStoredRecap(auctionID: AuctionID): PreviewTable[] {
    if(
      this.storage.recaps[auctionID] &&
      this.storage.recaps[auctionID].timeout > dayjs().valueOf()
    ) {
      return deepCopy(this.storage.recaps[auctionID].data);
    } else {
      const storedDashboard = this.getStoredDashboard();
      if(storedDashboard) {
        const filteredDashboard = storedDashboard.filter((previewData: PreviewTable) => previewData.auctionID === auctionID);
        return deepCopy(filteredDashboard);
      } else {
        return undefined;
      }
    }
  }

  public getStoredDashboard(): PreviewTable[] {
    if(
      this.storage.allRecaps &&
      this.storage.allRecaps.timeout > dayjs().valueOf()
    ) {
      return deepCopy(this.storage.allRecaps.data);
    } else {
      return undefined;
    }
  }

  public getStoredDashboardMIBList(): MIBList[] {
    if(
      this.storage.dashboardMIBList &&
      this.storage.dashboardMIBList.timeout > dayjs().valueOf()
    ) {
      return deepCopy(this.storage.dashboardMIBList.data);
    } else {
      return undefined;
    }
  }

  public getStoredDashboardBdeList(): BdeList[] {
    if(
      this.storage.dashboardBdeList &&
      this.storage.dashboardBdeList.timeout > dayjs().valueOf()
    ) {
      return deepCopy(this.storage.dashboardBdeList.data);
    } else {
      return undefined;
    }
  }

  /* public getStoredBdeList(auctionID: AuctionID): BdeList[] {
    if(
      this.storage.bdeList &&
      this.storage.bdeList[auctionID] &&
      this.storage.bdeList[auctionID].timeout > dayjs().valueOf()
    ) {
      return deepCopy(this.storage.bdeList.data);
    } else {
      return undefined;
    }
  } */

  /* public getStoredNotes(auctionID: AuctionID, timestamp: string): Note[] {
    if(
      this.storage.notes[auctionID] &&
      this.storage.notes[auctionID][timestamp] &&
      this.storage.notes[auctionID][timestamp].timeout > dayjs().valueOf()
    ) {
      return deepCopy(this.storage.notes[auctionID][timestamp].data);
    } else {
      return undefined;
    }
  } */

  public getLoginState(): Observable<boolean> {
    return this.storage.isUserLoggedIn;
  }

  public getUserData(): Observable<UserData> {
    return this.storage.userData;
  }

  public getNotebarVisibility(): Observable<boolean> {
    return this.storage.isNotebarOpen;
  }

  public getExternalLinks(): Observable<StoredLinksList> {
    return this.storage.linksList;
  }

  public getUnitsOptions(): Observable<StoredUnitsOptions> {
    return this.storage.unitsOptions;
  }



  //setters

  public setSelectedUnitCell(selectedUnitCell: UnitTableSelection): void {
    this.storage.selectedUnitCell.next(selectedUnitCell);
  }

  public storeAuctionsList(data: PreviewTableList[]): void {
    this.storage.auctionsList = deepCopy(data);
  }

  public storeUnits(auctionID: AuctionID, timestamp: string, data: UnitTable[]): void {
    if(!this.storage.units[auctionID]) {
      this.storage.units[auctionID] = {};
    }
    const dataToStore: StoredData<UnitTable[]> = {
      data: data,
      timeout: this.generateStoreTimeout(),
    }
    this.storage.units[auctionID][timestamp] = deepCopy(dataToStore);
  }

  public storeAllUnits(timestamp: string, data: UnitTable[]): void {
    if(!this.storage.allUnits) {
      this.storage.allUnits = [];
    }
    const dataToStore: StoredData<UnitTable[]> = {
      data: data,
      timeout: this.generateStoreTimeout(),
    }
    this.storage.allUnits[timestamp] = deepCopy(dataToStore);
  }

  public storeRecap(auctionID: AuctionID, data:PreviewTable[] ): void {
    const dataToStore: StoredData<PreviewTable[]> = {
      data: data,
      timeout: this.generateStoreTimeout(),
    }
    this.storage.recaps[auctionID] = deepCopy(dataToStore);
  }

  public storeDashboard(data: PreviewTable[]): void {
    const dataToStore: StoredData<PreviewTable[]> = {
      data: data,
      timeout: this.generateStoreTimeout(),
    }
    this.storage.allRecaps = deepCopy(dataToStore);
  }

  public storeDashboardMIBList(data: MIBList[]): void {
    const dataToStore: StoredData<MIBList[]> = {
      data: data,
      timeout: this.generateStoreTimeout(),
    }
    this.storage.dashboardMIBList = deepCopy(dataToStore);
  }

  public storeDashboardBdeList(data: BdeList[]): void {
    const dataToStore: StoredData<BdeList[]> = {
      data: data,
      timeout: this.generateStoreTimeout(),
    }
    this.storage.dashboardBdeList = deepCopy(dataToStore);
  }

  /* public storeBdeList(auctionID: AuctionID, data: BdeList[]): void {
    const dataToStore: StoredData<BdeList[]> = {
      data: data,
      timeout: this.generateStoreTimeout(),
    }
    this.storage.bdeList[auctionID] = deepCopy(dataToStore);
  } */

  /* public storeNotes(auctionID: AuctionID, timestamp: string, data: Note[]): void {
    if(!this.storage.notes[auctionID]) {
      this.storage.notes[auctionID] = {};
    }
    const dataToStore: StoredData<Note[]> = {
      data: data,
      timeout: this.generateStoreTimeout(),
    };
    this.storage.notes[auctionID][timestamp] = deepCopy(dataToStore);
  } */

  public setLoginState(isLoggedIn: boolean): void {
    this.storage.isUserLoggedIn.next(deepCopy(isLoggedIn));
  }

  public updateUserData(newPartialData: UserData): void {
    let newData: UserData = {};
    Object.keys(newPartialData).forEach(key => {
      newData[key] = newPartialData[key];
    });
    this.storage.userData.next(deepCopy(newData));
  }

  public updateNotebarVisibility(isOpen: boolean): void {
    this.storage.isNotebarOpen.next(isOpen);
  }

  public storeAuctionExternalLink(auctionID: AuctionID, url: string): void {
    this.storage.linksList.next(deepCopy({
      ...this.storage.linksList.value,
      [auctionID]: url
    }));
  }

  public storeUnitExternalLinks(unitCode: UnitCode, links: Link[]): void {
    this.storage.linksList.next(deepCopy({
      ...this.storage.linksList.value,
      [unitCode]: links
    }));
  }

  public storeUnitExternalLink(unitCode: UnitCode, link: Link): void {
    let newUnitList = (this.storage.linksList.value[unitCode] as Link[]) || [];
    newUnitList.push(link);

    this.storage.linksList.next(deepCopy({
      ...this.storage.linksList.value,
      [unitCode]: newUnitList
    }));
  }

  public removeAuctionExternalLink(auctionID: AuctionID): void {
    const { [auctionID]: unused, ...newStorage } = this.storage.linksList.value;
    this.storeExternalLinks(newStorage);
  }

  public removeUnitExternalLink(unitCode: UnitCode, linkLabel: string): void {
    let newUnitList = (this.storage.linksList.value[unitCode] as Link[]);
    newUnitList.splice(
      newUnitList.indexOf(
        newUnitList.find(link => link.label === linkLabel)
      ),
      1
    );

    this.storage.linksList.next(deepCopy({
      ...this.storage.linksList.value,
      [unitCode]: newUnitList
    }));
  }

  public storeExternalLinks(data: StoredLinksList): void {
    this.storage.linksList.next(deepCopy(data));
  }

  public updateUnitExternalLink(unitCode: UnitCode, newLink: Link): void {
    const storage = this.storage.linksList.value;
    (storage[unitCode] as Link[]).find(
      (link: Link) => link.label === newLink.label
    ).url = newLink.url;
    this.storeExternalLinks(storage);
  }

  public storeUnitsOptions(unitsOptions: StoredUnitsOptions): void {
    this.storage.unitsOptions.next(deepCopy(unitsOptions));
  }

  public updateUnitOption(unitCode: UnitCode, option: keyof UnitOptions, value: number|undefined = undefined): void {
    const store = this.storage.unitsOptions.value;
    if(typeof value === 'number') {
      (store[unitCode][option] as number) = value;
    } else {
      (store[unitCode][option] as boolean) = !(store[unitCode][option] as boolean);
    }
    this.storage.unitsOptions.next(deepCopy(store));
  }
}
