import { AfterViewInit, Component, EventEmitter, Injectable, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Language } from 'src/app/enums/language.enum';
import { TimeSelectorTitles } from 'src/app/enums/time-selector-titles.enum';
import * as dayjs from 'dayjs';
import * as isToday from 'dayjs/plugin/isToday';
import * as isYesterday from 'dayjs/plugin/isYesterday';
import * as isTomorrow from 'dayjs/plugin/isTomorrow';
import * as calendar from 'dayjs/plugin/calendar';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import { NgbDatepickerI18n, NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { getNgbDateStructFromDate } from 'src/app/utils/functions';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BREAKPOINT_TABLET_MAX_WIDTH } from 'src/app/utils/consts';

const I18N_VALUES = {
  it: {
    weekdays: ['Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab', 'Dom'],
    months: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
  },
  de: {
    weekdays: ['Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam', 'Son'],
    months: ['Januar', 'Februar', 'Märsch', 'April', 'Dürfen', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
  }
};

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  constructor(private translate: TranslateService) { super(); }
  getWeekdayLabel(weekday: number): string {
    return I18N_VALUES[this.translate.currentLang].weekdays[weekday - 1];
  }
  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this.translate.currentLang].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this.translate.currentLang].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

@Component({
  selector: 'app-time-selector',
  templateUrl: './time-selector.component.html',
  providers: [
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
  ]
})
export class TimeSelectorComponent implements OnInit, OnDestroy, AfterViewInit {

  private readonly onDestroy$ = new Subject<void>();

  private currentLanguage: Language = Language.IT;
  private date: dayjs.Dayjs = dayjs();
  
  public selectedDate: string;
  public calendarDay: string;
  public isPreviousDisabled: boolean = false;
  public isNextDisabled: boolean = false;
  public datepickerDate: NgbDateStruct = getNgbDateStructFromDate(dayjs().toDate());
  public datepickerMinDate: NgbDateStruct;
  public datepickerMaxDate: NgbDateStruct;
  public isTablet: boolean = false;

  @Input() minDate: Date;
  @Input() maxDate: Date;
  
  @Output() onDateChange = new EventEmitter<Date>();

  @ViewChild('d') datepickerNode: NgbInputDatepicker;

  constructor(
    private translateService: TranslateService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.translateService.onLangChange.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((event: LangChangeEvent) => {
      if(event && event.lang) {
        this.currentLanguage = <Language>event.lang;
        dayjs.locale(<string> this.currentLanguage);
        this.generateDate();
      }
    });
  }

  ngOnInit() {
    if(Boolean(this.minDate)) {
      this.datepickerMinDate = getNgbDateStructFromDate(this.minDate);
    }
    if(Boolean(this.maxDate)) {
      this.datepickerMaxDate = getNgbDateStructFromDate(this.maxDate);
    }
    dayjs.extend(calendar);
    dayjs.extend(isToday);
    dayjs.extend(isYesterday);
    dayjs.extend(isTomorrow);
    dayjs.extend(localizedFormat);
    dayjs.extend(relativeTime);
    this.generateDate();
    this.onResize();
  }

  ngAfterViewInit() {
    this.datepickerNode.autoClose = 'inside';
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  private onResize(): void {
    this.isTablet = this.breakpointObserver.isMatched(BREAKPOINT_TABLET_MAX_WIDTH);
    window.addEventListener('resize', () => {
      this.isTablet = this.breakpointObserver.isMatched(BREAKPOINT_TABLET_MAX_WIDTH);
      this.generateDate();
    });
  }

  public previous(): void {
    this.updateDate(this.date.subtract(1, 'day'));
  }

  public next(): void {
    if(!this.date.isTomorrow()) {
      this.updateDate(this.date.add(1, 'day'));
    }
  }

  private generateDate(): void {
    const localeDate = this.date.locale(<string>this.currentLanguage);
    this.calendarDay = this.date.calendar(null, {
      sameDay: '[' + TimeSelectorTitles.TODAY + ']',
      lastDay: '[' + TimeSelectorTitles.YESTERDAY + ']',
      nextDay: '[' + TimeSelectorTitles.TOMORROW + ']',
      lastWeek: '[' + localeDate.fromNow() + ']',
      sameElse: '[' + localeDate.fromNow() + ']'
    });
    this.selectedDate = this.isTablet ?
      localeDate.format('ll') :
      localeDate.format('LL');
  }

  private updateDate(newDate: dayjs.Dayjs) {
    if(newDate.startOf('day').valueOf() !== this.date.valueOf()) {
      this.date = newDate;
      this.datepickerDate = getNgbDateStructFromDate(newDate.startOf('day').toDate());

      this.isPreviousDisabled = this.date.startOf('day').valueOf() <= this.minDate?.valueOf();
      this.isNextDisabled = this.date.startOf('day').valueOf() >= this.maxDate?.valueOf();

      this.generateDate();
      this.onDateChange.emit(this.date.toDate());
    }
  }

  public onDatepickerDateSelect(date: NgbDateStruct) {
    const inputDate = `${date.year}-${date.month}-${date.day}`;
    this.updateDate(dayjs(inputDate));
  }

  public closeDatepicker() {
    this.datepickerNode.close();
  }
}
