import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PagesModule } from './components/pages/pages.module';
import { SharedModule } from './components/shared/shared.module';
import { HttpLoaderFactory } from './utils/functions';

import { DEFAULT_LANGUAGE } from './utils/consts';
import { LANGUAGE_LIST } from './utils/consts';
import { AuctionService } from './services/http/auction/auction.service';
import { NotesService } from './services/http/notes/notes.service';
import { StorageKeys } from './enums/storage-keys';
import { InterceptorService } from './services/http/interceptor/interceptor.service';
import {ToastContainerModule, ToastrModule} from "ngx-toastr";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

const modules = [
  PagesModule,
  SharedModule
];

const services = [
  AuctionService,
  NotesService,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorService,
    multi: true
  }
];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot({
      timeOut: 15000,
      positionClass: 'toast-top-right',
    }),
    ...modules
  ],
  providers: [
    ...services
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private translate: TranslateService,
    private router: Router
  ) {
    translate.addLangs(LANGUAGE_LIST);
    translate.setDefaultLang(DEFAULT_LANGUAGE);
    const storedLanguage = localStorage.getItem(StorageKeys.LANGUAGE);
    if(!storedLanguage || !LANGUAGE_LIST.find(language => language == storedLanguage) ) {
      localStorage.setItem(StorageKeys.LANGUAGE, DEFAULT_LANGUAGE);
    }
    translate.use(localStorage.getItem(StorageKeys.LANGUAGE));
  }
}
