<div class="auction-page" [ngClass]="{'view-all': isViewAllPage}">
  <template #alertBox></template>
  <!-- <div class="basic-page-title">
    <div class="basic-page-title-icon">
      <i class="icon-location icon-sky icon-box icon-box-large icon-box-sky icon-large"></i>
    </div>
    <span class="basic-page-title-label typo-heading-1 typo-capitalize" [innerText]="currentAuctionName"></span> 
  </div> -->

  <div class="auction-page-content" [ngClass]="notebarOpened ? 'notebar-opened' : null">
    <div class="card card-table-preview no-pad">
      <app-accordion>
        <ng-container accordion-head>
          <div class="accordion-head-inner">
            <div class="accordion-head-title typo-heading-3 typo-capitalize" [innerText]="'AUCTION:auction-preview' | translate"></div>
          </div>
        </ng-container>
        <ng-container accordion-body>
          <div class="accordion-body-inner">
            <app-table-preview [dataset]="currentAuctionPreviewTableDataset"></app-table-preview>
          </div>
        </ng-container>
      </app-accordion>
    </div>
    <div class="basic-page-subtitle typo-heading-3" [innerText]="'AUCTION:units-detail' | translate"></div>
    <div class="auction-page-filters" [ngClass]="{'sticky': areToolsSticky}">
      <div class="auction-page-filters-tab">
        <app-tab
          [tabList]="AUCTION_DATA_TYPE_LIST"
          [initialValue]="currentTab"
          (selected)="switchTab($event)">
        </app-tab>
      </div>
      <div class="auction-page-filters-date">
        <app-time-selector (onDateChange)="switchDate($event)" [maxDate]="dateSelectorMaxDate"></app-time-selector>
      </div>
    </div>
    <div class="auction-page-guide" *ngIf="currentAuctionGuide.length > 0">
      <div class="auction-page-guide-title typo-body-3-noquery typo-bold" [innerText]="'AUCTION--GUIDE:title' | translate"></div>
      <ul class="auction-page-guide-list">
        <li class="auction-page-guide-list-item" *ngFor="let guideItem of currentAuctionGuide">
          <div
            class="auction-page-guide-list-item-example"
            [ngClass]="[guideItem.type]"
            [innerText]="guideItem.content">
          </div>
          <div
            class="auction-page-guide-list-item-description"
            [innerText]="guideItem.label | translate">
          </div>
        </li>
      </ul>
    </div>
    <div *ngFor="let tableReport of currentAuctionReportTableDataset; index as unitIndex">
      <div class="card-table-report-container">
        <div class="card card-table-report no-pad tableReport" [attr.data-scroll]="UNIT_ID_LINK_PREFIX + unitIndex">
          <ng-container *ngIf="isUnitDataAvailable(tableReport, currentTab); then accordion else checkNotEnabled"></ng-container>
          <ng-template #accordion>
            <app-accordion [groupID]="tableReport.auctionName.toLowerCase().slice(0,3)">
              <ng-container accordion-head>
                <div class="accordion-head-inner">
                  <div class="accordion-head-title typo-heading-4 typo-capitalize" [innerText]="tableReport.unitCode | translate"></div>
                  <!-- [innerText]="'AUCTION:data-detail' | translate:{ delta: quarterDetail.delta, mw: quarterDetail.mw }" -->
                  <div
                    class="accordion-head-subtitle typo-body-2 typo-wolf-gray"
                    [innerText]="'AUCTION:data-detail' | translate:{ delta: quarterDetail.delta }"
                    *ngIf="selectedReportCell.unitCode === tableReport.unitCode">
                  </div>
                  <i
                    *ngIf="currentTab.id === UNIT_DATA_TYPE.CHART"
                    class="chart-zoom icon-zoom-in icon-coral icon-medium icon-box icon-box-small icon-anim"
                    (click)="chartZoom($event)"
                    [attr.data-unit]="tableReport.unitCode">
                  </i>
                </div>
              </ng-container>
              <ng-container accordion-body>
                <div class="accordion-body-inner">
                  <div class="accordion-big-padding">
                    <div [ngClass]="{'accordion-body-scroll': currentTab.id !== UNIT_DATA_TYPE.CHART}">
                      <hr class="accordion-separator">
                      <app-table-report
                        *ngIf="currentTab.id === UNIT_DATA_TYPE.PV"
                        [dataset]="tableReport">
                      </app-table-report>
                      <app-table-report
                        *ngIf="currentTab.id === UNIT_DATA_TYPE.PVM_PV"
                        [dataset]="tableReport"
                        [mode]="UNIT_DATA_TYPE.PVM_PV">
                      </app-table-report>
                      <app-table-report
                        *ngIf="currentTab.id === UNIT_DATA_TYPE.PVM_P"
                        [dataset]="tableReport"
                        [mode]="UNIT_DATA_TYPE.PVM_P">
                      </app-table-report>
                      <app-table-report
                        *ngIf="currentTab.id === UNIT_DATA_TYPE.POWER"
                        [dataset]="tableReport"
                        [mode]="UNIT_DATA_TYPE.POWER">
                      </app-table-report>
                      <app-table-report
                        *ngIf="currentTab.id === UNIT_DATA_TYPE.P_MAX"
                        [dataset]="tableReport"
                        [mode]="UNIT_DATA_TYPE.P_MAX">
                      </app-table-report>
                      <app-chart-line
                        *ngIf="currentTab.id === UNIT_DATA_TYPE.CHART"
                        [id]="unitIndex"
                        [tableDataset]="tableReport">
                      </app-chart-line>
                    </div>
                  </div>

                  <app-accordion
                    [initialVisibility]="false"
                    [style]="AccordionStyle.SMALL"
                    [headerOnBottom]="true"
                    class="accordion-bde"
                    *ngIf="tableReport.bde.length > 0">
                    <ng-container accordion-head>
                      <div
                        class="accordion-head-inner typo-textlink typo-body-2 typo-bold typo-uppercase"
                        [innerText]="'ACCORDION--HEAD:bde-list' | translate">
                      </div>
                    </ng-container>
                    <ng-container accordion-body>
                      <div class="accordion-body-inner">
                        <app-table-bde
                          [isSingleUnit]="true"
                          [singleDataset]="tableReport.bde">
                        </app-table-bde>
                      </div>
                    </ng-container>
                  </app-accordion>
                </div>
              </ng-container>
            </app-accordion>
          </ng-template>

          <ng-template #checkNotEnabled>
            <div class="card-table-report-unavailable">
              <div class="card-table-report-unavailable-title typo-heading-4 typo-capitalize" [innerText]="tableReport.unitCode | translate"></div>
              <div
                class="card-table-report-unavailable-subtitle typo-body-2 typo-wolf-gray"
                [innerText]="'AUCTION:not-enabled' | translate"
                *ngIf="isUnitNotEnabled(tableReport); else unavailable">
              </div>
              <ng-template #unavailable>
                <div
                  class="card-table-report-unavailable-subtitle typo-body-2 typo-wolf-gray"
                  [innerText]="'AUCTION:unavailable' | translate">
                </div>
              </ng-template>
            </div>
          </ng-template>
        </div>
        
        <div class="card card-total-data" *ngIf="isViewAllPage && !notebarOpened">
          <div class="card-total-data-fieldgroup vertical extend">
            <span
              class="card-total-data-label separator typo-heading-4"
              [innerText]="'AUCTION:pv-total' | translate">
            </span>
            <span
              class="card-total-data-value extend center typo-heading-3"
              [innerText]="(totalPv(tableReport) | number) + ' MWh'">
            </span>
          </div>
          <div class="card-total-data-footer">
            <div class="card-total-data-fieldgroup">
              <span
                class="card-total-data-label typo-body-2-noquery typo-bold"
                [innerText]="'AUCTION:pmin' | translate">
              </span>
              <span
                class="card-total-data-value typo-body-2-noquery"
                [innerText]="currentAuctionPreviewTableDataset[unitIndex].powerMin || '-'">
              </span>
            </div>
            <div class="card-total-data-fieldgroup">
              <span
                class="card-total-data-label typo-body-2-noquery typo-bold"
                [innerText]="'AUCTION:pmax' | translate">
              </span>
              <span
                class="card-total-data-value typo-body-2-noquery"
                [innerText]="currentAuctionPreviewTableDataset[unitIndex].powerMax || '-'">
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-notebar
    [auction]="currentAuction"
    [selectedDate]="date.toDate()"
    [selectedAuctionID]="selectedReportCell.auctionID"
    [selectedUnit]="selectedReportCell.unitCode"
    [selectedCellTime]="selectedReportCell.startTime"
    (onAdd)="addNoteBadge($event)"
    (onDelete)="removeNoteBadge($event)">
  </app-notebar>
</div>