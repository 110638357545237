<div class="range" [ngClass]="{'inverted-track': isTrackInverted, 'label-visible': isLabelVisible}">
  <label
    [for]="id"
    #labelNode
    class="typo-body-3 typo-bold">
    <span [innerText]="value"></span>
    <span [innerText]="labelUnit | translate"></span>
  </label>
  <input
    type="range"
    [id]="id"
    [min]="minValue"
    [max]="maxValue"
    [step]="step"
    [value]="initialValue"
    [(ngModel)]="value"
    [formControl]="control"
    #inputNode>
</div>