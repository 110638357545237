import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { getApiUrl } from "src/app/utils/urls";
import { catchError, map } from "rxjs/operators";
import { UnitCode } from "src/app/enums/unit-codes.enum";
import { Observable, of } from "rxjs";
import {
  LinksResponse,
  UnitsOptionsResponse,
} from "src/app/interfaces/config-service-response";
import { AuctionID } from "src/app/interfaces/auction";
import { Link } from "src/app/interfaces/link";
import { AppService } from "../../local/app/app.service";

@Injectable({
  providedIn: "root",
})
export class ConfigService {
  constructor(private http: HttpClient, private app: AppService) {}

  public actions = {
    links: {
      auction: {
        set: (auctionId: AuctionID, link: Link) =>
          this.setAuctionLink(auctionId, link),
        edit: (auctionId: AuctionID, link: Link) =>
          this.editAuctionLink(auctionId, link),
          delete: (linkKey: string, auctionID: AuctionID) =>
            this.deleteAuctionLink(linkKey, auctionID),
      },
      unit: {
        set: (unitCode: UnitCode, link: Link) =>
          this.setUnitLink(unitCode, link),
        edit: (unitCode: UnitCode, link: Link) =>
          this.editUnitLink(unitCode, link),
        delete: (unitCode: UnitCode, linkKey: string, auctionID: number) =>
          this.deleteUnitLink(unitCode, linkKey, auctionID),
      },
      getAll: () => this.getAllLinks(),
    },
    visibilityFlags: {
      balanceable: {
        set: (unitCode: UnitCode) => {
          return this.setUnitBalanceable(unitCode);
        },
      },
      showMib: {
        set: (unitCode: UnitCode) => {
          return this.setUnitMibVisibility(unitCode);
        },
      },
      showPowerMax: {
        set: (unitCode: UnitCode) => {
          return this.setUnitPowerMaxVisibility(unitCode);
        },
      },
      showPowerMin: {
        set: (unitCode: UnitCode) => {
          return this.setUnitPowerMinVisibility(unitCode);
        },
      },
      showPVM: {
        set: (unitCode: UnitCode) => {
          return this.setUnitPVMVisibility(unitCode);
        },
      },
      getAll: () => {
        return this.getAllUnitsOptions();
      },
    },
    threshold: {
      value: {
        set: (unitCode: UnitCode, thresholdValue: number) => {
          return this.setUnitThresholdValue(unitCode, thresholdValue);
        },
      },
      duration: {
        set: (unitCode: UnitCode, thresholdDuration: number) => {
          return this.setUnitThresholdDuration(unitCode, thresholdDuration);
        },
      },
    },
  };

  private setAuctionLink(
    auctionID: string = undefined,
    link: Link
  ): Observable<string> {
    return this.http
      .post(
        getApiUrl("addlink", null, {
          auctionID: auctionID ? auctionID : undefined,
        }),
        {
          key: link.label,
          value: link.url,
        }
      )
      .pipe(
        map((response: string) => {
          this.app.storeAuctionExternalLink(auctionID || "global", link.url);
          return response;
        }),
        catchError((error) => {
          if (error.status === 400) {
            return this.actions.links.auction.edit(auctionID, link);
          } else {
            throw error;
          }
        })
      );
  }

  private setUnitLink(unitCode: UnitCode, link: Link): Observable<string> {
    return this.http
      .post(getApiUrl("addunitlink", unitCode), {
        key: link.label,
        value: link.url,
      })
      .pipe(
        map((response: string) => {
          this.app.storeUnitExternalLink(unitCode, link);
          return response;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  private editAuctionLink(
    auctionID: string = undefined,
    link: Link
  ): Observable<string> {
    return this.http
      .patch(
        getApiUrl("editlink", null, {
          auctionID: auctionID ? auctionID : undefined,
        }),
        {
          key: link.label,
          value: link.url,
        }
      )
      .pipe(
        map((response: string) => {
          this.app.storeAuctionExternalLink(auctionID || "global", link.url);
          return response;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  private editUnitLink(unitCode: UnitCode, link: Link): Observable<any> {
    return this.http
      .patch(getApiUrl("editunitlink", unitCode + "/" + link.label), {
        value: link.url,
      })
      .pipe(
        map((response: any) => {
          this.app.updateUnitExternalLink(unitCode, link);
          return response;
        })
      );
  }

  private getAllLinks(): Observable<LinksResponse[]> {
    return this.http.get(getApiUrl("getalllinks")).pipe(
      map((response: LinksResponse[]) => {
        return response;
      }),
      catchError((error) => {
        return of([]);
      })
    );
  }

  private deleteAuctionLink(linkKey: string, auctionID: AuctionID = null): Observable<any> {
    let params: {[key: string]: string} = {keyLink: linkKey};
    
    if(auctionID){
      params = {...params, auctionID}
    }
    return this.http
      .delete(
        getApiUrl("deletelink"), {
          params
        }
      )
      .pipe(
        map((response: any) => {
          this.app.removeAuctionExternalLink(auctionID || "global");
          return response;
        })
      );
  }

  private deleteUnitLink(unitCode: UnitCode, linkKey: string, auctionID: number): Observable<any> {
    return this.http
      .delete(`${getApiUrl("deleteunitlink")}/delete` , {
        params: {
          keyLink: linkKey,
          auctionID: auctionID,
          unitCode: unitCode
        },
      })
      .pipe(
        map((response: any) => {
          this.app.removeUnitExternalLink(unitCode, linkKey);
          return response;
        })
      );
  }

  private getAllUnitsOptions(): Observable<UnitsOptionsResponse[]> {
    return this.http.get(getApiUrl("getallunitsoptions")).pipe(
      map((response: UnitsOptionsResponse[]) => {
        return response;
      })
    );
  }

  private setUnitBalanceable(
    unitCode: UnitCode
  ): Observable<string | HttpErrorResponse> {
    return this.http
      .patch(getApiUrl("setunitbalanceable", unitCode), { test: "test" })
      .pipe(
        map((response: string) => {
          this.app.updateUnitOption(unitCode, "balanceable");
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          throw of(error);
        })
      );
  }

  private setUnitMibVisibility(unitCode: UnitCode): Observable<any> {
    return this.http
      .patch(getApiUrl("setunitmibvisibility", unitCode), {})
      .pipe(
        map((response: any) => {
          this.app.updateUnitOption(unitCode, "showMib");
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          throw of(error);
        })
      );
  }

  private setUnitPowerMaxVisibility(unitCode: UnitCode): Observable<any> {
    return this.http
      .patch(getApiUrl("setunitpowermaxvisibility", unitCode), {})
      .pipe(
        map((response) => {
          this.app.updateUnitOption(unitCode, "showPowerMax");
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          throw of(error);
        })
      );
  }

  private setUnitPowerMinVisibility(unitCode: UnitCode): Observable<any> {
    return this.http
      .patch(getApiUrl("setunitpowerminvisibility", unitCode), {})
      .pipe(
        map((response) => {
          this.app.updateUnitOption(unitCode, "showPowerMin");
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          throw of(error);
        })
      );
  }

  private setUnitPVMVisibility(unitCode: UnitCode): Observable<any> {
    return this.http
      .patch(getApiUrl("setunitpvmvisibility", unitCode), {})
      .pipe(
        map((response) => {
          this.app.updateUnitOption(unitCode, "showPVM");
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          throw of(error);
        })
      );
  }

  private setUnitThresholdValue(
    unitCode: UnitCode,
    thresholdValue: number
  ): Observable<any> {
    return this.http
      .patch(getApiUrl("setunitthresholdvalue", unitCode), {
        unbalancedAlarmThreshold: thresholdValue / 100,
      })
      .pipe(
        map((response) => {
          this.app.updateUnitOption(unitCode, "thresholdValue", thresholdValue);
          return response;
        }),
        catchError((error) => {
          throw of(error);
        })
      );
  }

  private setUnitThresholdDuration(
    unitCode: UnitCode,
    thresholdDuration: number
  ): Observable<any> {
    return this.http
      .patch(getApiUrl("setunitthresholdduration", unitCode), {
        unbalancedAlarmMinutes: thresholdDuration,
      })
      .pipe(
        map((response) => {
          this.app.updateUnitOption(
            unitCode,
            "thresholdDuration",
            thresholdDuration
          );
          return response;
        }),
        catchError((error) => {
          throw of(error);
        })
      );
  }
}
