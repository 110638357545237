import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { DEFAULT_LANGUAGE } from 'src/app/utils/consts';
import { BasicPageComponent } from './basic-page/basic-page.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuctionPageComponent } from './auction-page/auction-page.component';
import { SharedModule } from 'src/app/components/shared/shared.module';
import { HttpClient } from '@angular/common/http';
import { HttpLoaderFactory } from 'src/app/utils/functions';
import { RoutesList } from 'src/app/enums/routes-list.enum';

const modules = [
  SharedModule
];

const components = [
  BasicPageComponent,
  DashboardComponent,
  AuctionPageComponent
];

const routes: Routes = [
  {
    path: '',
    component: BasicPageComponent,
    children: [
      {
        path: '',
        redirectTo: RoutesList.DASHBOARD,
        pathMatch: 'full'
      },
      {
        path: RoutesList.DASHBOARD,
        component: DashboardComponent
      },
      {
        path: RoutesList.AUCTION,
        component: AuctionPageComponent
      }
    ]
  }
];

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RouterModule.forChild(routes),
    ...modules
  ]
})
export class PagesModule {
  constructor(
    private translate: TranslateService,
  ) {
    translate.setDefaultLang(DEFAULT_LANGUAGE);
  }
}
