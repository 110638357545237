<header class="header">
  <div class="page-spacing header-inner">
    <div class="header-left">
      <ng-container *ngIf="!isTablet; then languageSelector"></ng-container>
      <button class="header-burger" (click)="toggleBurger()">
        <i class="icon-burger icon-coral icon-medium icon-box icon-box-medium icon-anim"></i>
      </button>
      <a
        *ngIf="globalExternalLink"
        [href]="globalExternalLink"
        target="_blank"
        class="header-external-link tooltip-bottom tooltip-large typo-body-2"
        [attr.data-tooltip]="'HEADER:external-link' | translate">
        <i class="icon-address-book icon-coral icon-medium icon-box icon-box-medium icon-anim icon-clickable"></i>
      </a>
      <nav class="header-menu" *ngIf="isLoggedIn" [ngClass]="{'open': isMobileMenuVisible}">
        <div class="header-menu-overlay" (click)="toggleBurger(false)" *ngIf="isTablet"></div>
        <a
          [routerLink]="['/' + RoutesList.DASHBOARD]"
          class="header-menu-item"
          [ngClass]="{'active': activeMenu === RoutesList.DASHBOARD}"
          (click)="toggleBurger(false)">
          <span
            class="header-menu-dashboard-label typo-body-1 typo-capitalize"
            [innerText]="'HEADER:' + RoutesList.DASHBOARD | translate">
          </span>
          <i class="icon-meter icon-dark-gray icon-medium header-menu-item-icon"></i>
        </a>
        <ng-container *ngFor="let auction of auctionList">
          <a
            [routerLink]="['/' + RoutesList.AUCTION]"
            [queryParams]="{id: auction.id}"
            [innerText]="auction.name"
            class="header-menu-item typo-body-1 typo-capitalize"
            [ngClass]="{'active': activeMenu === RoutesList.AUCTION + auction.id}"
            (click)="toggleBurger(false)">
          </a>
        </ng-container>
        <a
          [routerLink]="['/' + RoutesList.AUCTION]"
          [queryParams]="{id: ALL_AUCTIONS_ID}"
          [innerText]="'HEADER:unit-list' | translate"
          class="header-menu-item typo-body-1 typo-capitalize"
          [ngClass]="{'active': activeMenu === RoutesList.AUCTION + ALL_AUCTIONS_ID}"
          (click)="toggleBurger(false)">
        </a>
        <ng-container *ngIf="isTablet; then languageSelector"></ng-container>
      </nav>
    </div>
    <div class="header-right">
      <div class="header-clock typo-body-1 typo-capitalize" [innerText]="clock"></div>
      <i class="icon-alperia header-logo" (click)="__panicRefresh()"></i>
      <div class="header-user" [ngClass]="{'open': isUserMenuOpen}" *ngIf="isLoggedIn">
        <div class="header-user-info" (click)="toggleUserMenu()" (clickOutside)="toggleUserMenu(false)">
          <div class="header-user-icon typo-body-2 typo-dark-gray typo-bold typo-uppercase" [innerText]="getUserInitials()"></div>
          <i class="icon-chevron-down icon-dark-gray icon-small header-user-chevron"></i>
        </div>
        <div class="card header-user-menu">
          <span class="header-user-menu-item header-user-menu-item-name">
            <span class="typo-body-2 typo-bold text-trim-1" [innerText]="username"></span>
          </span>
          <span class="header-user-menu-item header-user-menu-item-contrast" (click)="toggleHighContrast()">
            <span class="typo-body-2" [innerText]="'HEADER:high-contrast' | translate"></span>
            <i class="icon-contrast icon-small icon-black"></i>
          </span>
          <span class="header-user-menu-item header-user-menu-item-theme" (click)="toggleDarkTheme()">
            <span class="typo-body-2" [innerText]="'HEADER:theme' | translate"></span>
            <i class="icon-moon icon-small icon-black" *ngIf="!isThemeDark"></i>
            <i class="icon-sun icon-small icon-black" *ngIf="isThemeDark"></i>
          </span>
          <span class="header-user-menu-item header-user-menu-item-audio" (click)="toggleAudio()">
            <span class="typo-body-2" [innerText]="'HEADER:audio' | translate"></span>
            <i class="icon-bell-ringing icon-small icon-black" *ngIf="isAudioEnabled"></i>
            <i class="icon-bell-slash icon-small icon-black" *ngIf="!isAudioEnabled"></i>
          </span>
          <span *ngIf="isUserAdmin" class="header-user-menu-item header-user-menu-item-config" (click)="openConfigModal()">
            <span class="typo-body-2" [innerText]="'HEADER:config' | translate"></span>
            <i class="icon-gear icon-small icon-black"></i>
          </span>
          <a class="header-user-menu-item header-user-menu-item-logout typo-textlink-coral typo-body-2" (click)="logout()">
            <span class="header-user-menu-item-logout-label" [innerText]="'HEADER:logout' | translate"></span>
            <i class="icon-exit icon-medium icon-coral icon-anim"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</header>

<ng-template #languageSelector>
  <div class="header-language">
    <button
      class="header-language-item typo-body-2 typo-bold typo-concrete typo-uppercase"
      [ngClass]="{'active': currentLanguage === Language.IT}"
      (click)="switchLanguage(Language.IT)" 
      [innerText]="Language.IT">
    </button>
    <div class="header-language-separator">|</div>
    <button
      class="header-language-item typo-body-2 typo-bold typo-concrete typo-uppercase"
      [ngClass]="{'active': currentLanguage === Language.DE}"
      (click)="switchLanguage(Language.DE)"
      [innerText]="Language.DE">
    </button>
  </div>
</ng-template>