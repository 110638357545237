import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isNodeDescendant } from 'src/app/utils/functions';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html'
})
export class ModalComponent implements OnInit {

  private readonly DEFAULT_CLOSE_ICON_ID = 'icon-cross';
  private readonly DEFAULT_CLOSE_ICON_SIZE = 'icon-medium';
  private readonly MODAL_CLASS_SELECTOR = '.modal';
  private readonly MODAL_WRAPPER_CLASS_SELECTOR = '.modal-wrapper';

  @Input() id: string;
  @Input() closeIconID: string = this.DEFAULT_CLOSE_ICON_ID;
  @Input() closeIconSize: string = this.DEFAULT_CLOSE_ICON_SIZE;
  @Input() onCloseCallback: Function;
  @Input() styleClasses: string;
  
  @Output() onClose: EventEmitter<Function> = new EventEmitter<Function>();
  
  constructor() {}

  ngOnInit() {}

  public close(): void {
    if(this.onCloseCallback) {
      this.onClose.emit(this.onCloseCallback);
    } else {
      this.onClose.emit(null);
    }
  }

  public clickOutside(event: Event): void {
    const modalNode = document.querySelector(this.MODAL_CLASS_SELECTOR) as HTMLElement;
    const modalWrapperNode = document.querySelector(this.MODAL_WRAPPER_CLASS_SELECTOR) as HTMLElement;
    const target = event.target as HTMLElement;

    if(
      isNodeDescendant(modalWrapperNode, target) &&
      !modalNode.contains(target)
    ) {
      this.close();
    }
  }
}