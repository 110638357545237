<app-modal
  [id]="MODAL_ID"
  [attr.id]="MODAL_ID"
  [closeIconID]="config.content.head.closeIconID"
  (onClose)="modalClose($event)"
  [styleClasses]="'modal-chart-zoom'">
  <ng-container modal-head>
    <div class="modal-head-inner">
      <div
        class="modal-head-title typo-heading-3"
        [innerText]="config.content.head.title | translate">
      </div>
      <div
        *ngIf="config.content.head.subtitle"
        class="modal-head-subtitle typo-body-2 typo-wolf-gray"
        [innerText]="config.content.head.subtitle | translate">
      </div>
    </div>
  </ng-container>
  <ng-container modal-body>
    <div class="modal-body-inner modal-chart-zoom-body-inner">
      <ng-container *ngIf="config.content.body.chart.dataset">
        <app-chart-line
          *ngIf="isReadyToPrint"
          [id]="config.content.body.chart.id"
          [tableDataset]="config.content.body.chart.dataset"
          [canvasWidth]="config.content.body.chart.canvasWidth"
          [canvasHeight]="config.content.body.chart.canvasHeight"
          [isModalView]="true">
        </app-chart-line>
      </ng-container>
    </div>
  </ng-container>
</app-modal>