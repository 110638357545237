<div class="dashboard">
  <template #alertBox></template>
  <ng-container *ngIf="isLoggedIn">
    <div class="dashboard-tabs">
      <app-tab
        [tabList]="DASHBOARD_VIEW_LIST"
        [initialValue]="currentTab"
        (selected)="switchTab($event)">
      </app-tab>
    </div>
    <!-- <div class="basic-page-title">
      <div class="basic-page-title-icon">
        <i class="icon-meter icon-sky icon-box icon-box-large icon-box-sky icon-box-no-shadow icon-large"></i>
      </div>
      <span class="basic-page-title-label typo-heading-1 typo-capitalize" [innerText]="'DASHBOARD:title' | translate"></span>
    </div> -->
    <div class="tileset">
      <div class="tileset-tile card no-pad" *ngFor="let auction of auctionList">
        <div class="tileset-tile-header">
          <div class="tileset-tile-name typo-heading-2 typo-capitalize" [innerText]="auction.auctionName"></div>
          <a
            class="tileset-tile-link tileset-tile-link-external typo-textlink-coral"
            *ngIf="!!linksList ? linksList[auction?.auctionID] || false : false"
            [href]="linksList[auction?.auctionID] || '#'"
            target="_blank">
            <span class="tileset-tile-link-label" [innerText]="'DASHBOARD:phone-book' | translate"></span>
            <i class="icon-phone-out icon-coral icon-small icon-box icon-box-small icon-anim tileset-tile-link-icon"></i>
          </a>
          <a
            [routerLink]="['/auction']"
            [queryParams]="{id: auction.auctionID}"
            class="tileset-tile-link typo-textlink-coral">
            <span class="tileset-tile-link-label" [innerText]="'DASHBOARD:detail' | translate"></span>
            <i class="icon-graph icon-coral icon-small icon-box icon-box-small icon-anim tileset-tile-link-icon"></i>
          </a>
        </div>
        <app-table-preview
          *ngIf="currentTab.id === DashboardTab.DASHBOARD"
          [dataset]="auction.data"
          [auctionID]="auction.auctionID"
          [routedLinks]="true">
        </app-table-preview>
        <app-table-bde
          *ngIf="currentTab.id === DashboardTab.BDE"
          [dataset]="auction.bde">
        </app-table-bde>
        <app-table-mib
          *ngIf="currentTab.id === DashboardTab.MIB"
          [dataset]="auction.mib">
        </app-table-mib>
      </div>
    </div>
  </ng-container>
</div>