import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberSign'
})
export class NumberSignPipe implements PipeTransform {

  transform(value: number | string): string {
    if(value === null || value === NaN || value === undefined) {
      return '';
    } else {
      value = parseFloat( <string> value );
      return value > 0 ? '+' + value.toString() : value.toString();
    }
  }
}
