import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FloatTrimPipe } from './float-trim/float-trim.pipe';
import { StringReplacePipe } from './string-replace/string-replace.pipe';
import { NumberSignPipe } from './number-sign/number-sign.pipe';

const PIPES = [
  FloatTrimPipe,
  StringReplacePipe,
  NumberSignPipe,
];

@NgModule({
  declarations: [
    ...PIPES,
  ],
  exports: [
    ...PIPES
  ],
  imports: [
    CommonModule
  ]
})
export class PipesModule {
  static forRoot() {
    return {
      ngModule: PipesModule,
      providers: [],
    };
  }
}