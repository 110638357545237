<div class="chart">
  <div class="chart-canvas-wrapper" #canvasWrapper>
    <canvas
      #canvas
      class="chartjs"
      [id]="chartNodeID"
      [attr.aria-label]="tableDataset.unitCode"
      role="img"
      [attr.width]="canvasWidth"
      [attr.height]="canvasHeight">
    </canvas>
  </div>
  <div #chartTooltip [id]="chartNodeID + CHART_TOOLTIP_ID_SUFFIX" class="chart-tooltip">
    <div class="chart-tooltip-head typo-body-1" [innerText]="currentHoveredTooltipTime"></div>
    <ng-container *ngIf="tooltipDataset.length > 1; else noDataTooltip">
      <ng-container *ngFor="let tooltipData of tooltipDataset">
        <div class="chart-tooltip-body">
          <span class="chart-tooltip-label typo-body-3 typo-bold" [innerText]="tooltipData.label | translate"></span>
          <span
            class="chart-tooltip-value typo-body-3"
            [innerText]="( tooltipData.label == CHART_DATA_LABELS.MIB ? 
              'AUCTION--CHART:data-mib-' + isNumberGreaterThan(tooltipData.value, 0) :
              tooltipData.value
              ) | translate | floatTrim">
          </span>
        </div>
      </ng-container>
    </ng-container>
    <ng-template #noDataTooltip>
      <span class="chart-tooltip-no-data typo-body-3" [innerText]="'AUCTION--CHART:tooltip-no_data' | translate"></span>
    </ng-template>
  </div>
  <div class="chart-footer">
    <div class="chart-legend">
      <div class="chart-legend-title typo-body-3" [innerText]="'AUCTION--CHART:legend-title' | translate"></div>
      <ul class="chart-legend-list">
        <ng-container *ngFor="let dataset of chartConfig.data.datasets; index as i">
          <li class="chart-legend-list-item" (click)="chartLegendEvent($event, i)">
            <div class="chart-legend-list-item-sample" [ngStyle]="{'background-color': dataset.backgroundColor, 'border-color': dataset.backgroundColor}"></div>
            <div class="chart-legend-list-item-label typo-body-3 typo-bold" [innerText]="dataset.label | translate"></div>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>