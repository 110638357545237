import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output
} from '@angular/core';
import { InputSelectItem } from 'src/app/interfaces/input-select-item';

@Component({
  selector: 'app-input-select',
  templateUrl: './input-select.component.html'
})
export class InputSelectComponent implements OnInit {

  @Input() list: InputSelectItem[];
  @Input() initialValue: InputSelectItem = {
    label: '',
    value: ''
  };
  @Input() placeholder: string = '';
  @Input() maxHeight: number = null;
  @Output() onSelect = new EventEmitter<InputSelectItem>();

  public current: InputSelectItem;
  public showList: Boolean = false;

  constructor() { }

  ngOnInit() {
    this.current = this.initialValue;
  }

  toggleListVisibility(): void {
    this.showList = !this.showList;
  }

  selectValue(value: string): void {
    this.current = this.list.filter(listItem => listItem.value === value)[0];
    this.onSelect.emit(this.current);
    this.toggleListVisibility();
  }
}
