import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Tab } from 'src/app/interfaces/tab';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html'
})
export class TabComponent implements OnInit {

  private readonly TAB_ITEMS_SELECTOR = '.tab .tab-item';
  private readonly ACTIVE_CLASS = 'active';

  private tabNodes: NodeListOf<HTMLElement>;

  @Input() tabList: Tab[];
  @Input() initialValue: Tab;
  
  @Output() selected = new EventEmitter<Tab>();

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.tabNodes = document.querySelectorAll(this.TAB_ITEMS_SELECTOR);
    if( this.initialValue ) {
      document.querySelector('#'+this.initialValue.id).classList.add(this.ACTIVE_CLASS);
    } else {
      this.tabNodes[0].classList.add(this.ACTIVE_CLASS);
    }
  }

  select(event: Event): void {
    const selectedNode: HTMLElement = <HTMLElement>event.target;
    const selectedItem: Tab = this.tabList.find(item => item.id === selectedNode.id);
    this.tabNodes.forEach(item =>  {
      item.classList.remove(this.ACTIVE_CLASS);
    });
    selectedNode.classList.add(this.ACTIVE_CLASS);
    this.selected.emit(selectedItem);
  }
}
